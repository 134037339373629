import { defineComponent } from "vue"

export default defineComponent({
  name: "v-mdi",
  props: {
    title: {
      type: String,
      default: "",
    },
    size: {
      type: [String, Number],
      default: 24,
    },
    fill: {
      type: String,
      default: "currentColor",
    },
    path: {
      type: String,
      required: true,
    },
  },
  render() {
    return (
      <svg
        class="v-mdi"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={this.fill}
        width={this.size}
        height={this.size}
        aria-label={this.title}
      >
        <path d={this.path} />
      </svg>
    );
  }
})
  // <template>
  //   <svg
  //     viewBox="0 0 24 24"
  //     :fill="fill"
  //     :height="size"
  //     :width="size"
  //     class="mdi-icon"
  //     :style="{ transform: `rotate(${this.rotate}deg)`, display: 'inline-block' }"
  //   >
  //     <title v-if="title">{{ title }}</title>
  //     <path :d="icon"></path>
  //   </svg>
  // </template>
  //
  // <script>
