<template lang="pug">
.card.profile-card(:class="{'border-0': preview}")
  .card-body(v-if="form" :class="{'p-0': preview}")
    .row
      .col-12(v-if="isSubmitting && !preview")
        .loading-progress-bar.mt-2.mb-2
          .loading-progress-bar__progress
      .col-12.col-md-6: .mb-4
        RadioGroup(
          :label="$t('js.components.talent_profile.questions.work_permit')"
          name='citizenship'
          v-model="form.work_permit"
          :options="workPermitOptions"
        )

      .col-12.col-md-6: .mb-4
        RadioGroup(
          :label="$t('js.components.talent_profile.questions.german_residency_short')"
          name='german_residency'
          v-model="form.german_residency"
          :inline="true"
          :options="yesNoOptions"
        )

      .col-12.col-md-6: .mb-4
        RadioGroup(
          :label="$t('js.components.talent_profile.questions.ready_to_move_short')"
          name='ready_to_move'
          v-model="form.ready_to_move"
          :inline="true"
          :options="yesNoOptions"
        )

      .col-12.col-md-6: .mb-4
        label.form-label(for="citizenship")
          | {{ $t('js.components.talent_profile.questions.nationality') }}
        Select(
          v-model="form.country_code"
          :options="countryOptions"
          style="font-size: 1rem"
        )

      .col-12: .mb-4
        label.form-label(for="education")
          | {{ $t('js.components.talent_profile.motivation.label') }}
        FormInput(
          name="optional_text"
          :label="false"
          type="textarea"
          v-model="form.optional_text"
          :placeholder="$t('js.components.talent_profile.placeholders.motivation')"
        )

      .col-12: .mb-4
        label.form-label(for="language_skills" name="language_skills")
          | {{ $t('js.components.talent_profile.languages.text') }}
        table.table.table-striped.table-hover.table-sm
          thead
            tr
              th.col
                | {{ $t('js.components.profile.tables.sprache') }}
              th.col.text-center
                | {{ $t('js.components.profile.tables.level') }}
          tbody
            tr(v-for="language in form.language_skills")
              td
                | {{ language.name }}
              td.text-center
                | {{ $t(`js.components.talent_profile.languages.fluency_levels.${language.level}`) }}
        .pull-right.mb-4
          span.open-modal(@click="setModalContent('language_skills')")
            | {{ $t('js.components.profile.tables.sprachkenntnisse_bearbeiten') }}
            i.fas.fa-edit(style="margin-left: 0.5rem")

      .col-12: .mb-4
        label.form-label(for="competence_levels" name="competence_levels")
          | {{ $t('js.components.talent_profile.competences.short') }}
        table.table.table-striped.table-hover.table-sm
          thead
            tr
              th.col.align-middle
                | {{ $tc('js.components.profile.tables.kompetenzen', 0) }}
              th.col.text-center.align-middle
                | {{ $t('js.components.profile.tables.kompetenz_stufe') }}
              th.col.text-center.align-middle(data-bs-toggle="tooltip" data-bs-placement="top" :title="t('js.components.talent_profile.important_competences.label')")
                | {{ $t('js.components.talent_profile.competences.weiter_entwickeln') }}
                span.tooltip-icon(style="margin-left: 0.25rem")
                  i.fas.fa-question-circle
          tbody
            tr(v-for="level in form.competence_levels")
              td
                | {{ level[`name_${locale}`] }}
              td.text-center(data-bs-toggle="tooltip" data-bs-placement="top" :title="t(`js.components.talent_profile.experience_levels.explanations.${level.experience_level}`)")
                | {{ $t(`js.components.talent_profile.experience_levels.options.${level.experience_level}`) }}
                span.tooltip-icon(style="margin-left: 0.25rem;")
                  i.fas.fa-asterisk.fa-xs
              td.text-center
                span.text-center(v-if="level.is_important" style="color: green")
                  i.fas.fa-check
        .pull-right
          span.open-modal(@click="setModalContent('competence_levels')" id="open_competence_modal")
            | {{ $t('js.components.profile.tables.kompetenzen_bearbeiten') }}
            i.fas.fa-edit(style="margin-left: 0.5rem")

  .card-footer(v-if="!preview" :class='{"card-footer-sticky": formChanged }')
    .d-flex.justify-content-end.gap-2
      button.btn.btn-sm(@click="editing = false" :class="(!dataSent || error) ? 'btn-outline-danger' : 'btn-outline-success'" id="close_skillset")
        i.fas.fa-times(style="margin-right: 0.5rem")
        span(v-if="!dataSent || error")
          | {{ $t('js.components.talent_profile.generic.abbrechen') }}
        span(v-else)
          | ({{ $t('js.components.talent_profile.generic.schliessen') }})
      button.btn.btn-primary.btn-sm(
        :class="{'tilt-shaking': formChanged && !dataSent}"
        :disabled="isSubmitting || !formChanged"
        @click="submitWithToast()"
      )
        i.fas.fa-save(style="margin-right: 0.5rem")
        | {{ $t('js.components.talent_profile.generic.speichern') }}

</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Select, RadioGroup, FormInput } from "@/elements"
import { useQuestionOptions } from "@/talents/question_options"
import { useSubmit } from "@/talents/profile/profile"
import { setModalContent } from  "@/talents/profile/contents/modal"
import { useI18n } from "vue-i18n"
import { debounceFilter, watchWithFilter } from '@vueuse/core'
const { locale, t } = useI18n()

const { workPermitOptions, yesNoOptions, countryOptions } = useQuestionOptions();
const { submit, error, isSubmitting, dataSent, form, formChanged } = useSubmit("profile");

const props = withDefaults(defineProps<{
  modelValue: boolean,
  preview: boolean,
}>(), {
  modelValue: false,
  preview: false,
})

watchWithFilter(
  form,
  () => { if(props.preview) submit() },
  { eventFilter: debounceFilter(500), deep: true },
)

const emits = defineEmits(["update:modelValue", "submit:makeToast"])
const submitWithToast = () => {
  submit()
  const type = error.value ? "error" : "success"
  emits("submit:makeToast", {
    message: t(`js.components.talent_profile.generic.status.${type}`),
    type: type,
  })
}

const editing = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emits("update:modelValue", value);
  },
});

defineExpose({
  form,
  formChanged
})
</script>
