<template lang="pug">
div
  .loader.profile-loader(v-show="isSubmitting")
    i.fa-spin.fa.fa-spinner.fa-6x
  .row
    .col-12
      .alert.alert-danger.mt-4.mb-4(v-if="error")
        .text-center
          h4.mb-3
            | {{ $t('js.components.talent_profile.generic.status.application_error') }}
          i.fas.fa-exclamation-circle.fa-6x
      .alert.alert-success.mt-4.mb-4(v-if="dataSent && !error")
        .text-center
          h4.mb-3 {{ $t('js.components.talent_profile.generic.status.application_success') }}
          i.fas.fa-check-circle.fa-6x
          p.mt-3
            | {{ $t('js.components.profile.job_offer.can_close_site') }}
      .alert.alert-warning(v-if="!dataSent")
        p {{ $t('js.components.profile.job_offer.check_the_following_data', {company: job.company_name}) }}
        h4 {{ job.title }}

  template(:class="isSubmitting ? 'blurred' : ''" v-if="applicationInfo && !dataSent")
    p
      | {{ $t('js.components.profile.job_offer.data_update_hint') }}
    .row
      .col-12
        .row-header.mt-3
          | {{ $t('js.components.profile.tables.deine_daten') }}
        .mt-1
          edit-application-profile(
            :preview="true"
            ref="appProfileRef"
          )

    .mt-3.text-center
      button.btn.btn-outline-primary.btn-lg(@click.prevent="showDetails = !showDetails")
        | {{ $t('js.components.profile.profile_preview.dein_anonymes_kurzprofil') }}
        i.fas.fa-chevron-down(:class="showDetails ? 'fa-rotate-180' : ''" style="margin-left: 0.5rem")
    collapse-transition
      .profile-card.p-3.mt-3(v-if="showDetails")
        .row-header
          | {{ $t('js.components.profile.tables.abschluss_und_zertifikate') }}
        .mt-1
          edit-education(
            :preview="true"
          )
        hr
        .row-header
          | {{ $t('js.components.profile.tables.allgemeine_infos') }}
        .mt-1
          edit-skillset(
            :preview="true"
          )
        hr
        .row-header(v-if="recommendations.length > 0")
          | {{ $t('js.components.profile.tables.deine_code') }}
        .mt-1
          edit-recommendation(
            :preview="true"
          )

    .row
      .col-12: .mt-5
        .row-header
          | {{ $t("js.components.profile.job_offer.answer_those_questions", { company: job.company_name }) }}
        .mt-2(v-if="applicationInfo.questions")
          Question(
            v-for="q in applicationInfo.questions"
            :name="'question_answers[' + q.question_id + ']'"
            :key="q.question_id"
            v-model="questionAnswers[q.question_id]"
            :question="q"
            class="mb-3"
          )
      .col-12(v-if="addressRequired"): .mt-3
        | {{ $t("js.components.profile.job_offer.postal_address") }}
      .col-md-4.col-12: .mt-2
        FormInput(
          name="street"
          type="text"
          v-model="address.street"
        )
      .col-md-4.col-12: .mt-2
        FormInput(
          name="postcode"
          type="text"
          v-model="address.postcode"
        )
      .col-md-4.col-12: .mt-2
        FormInput(
          name="city"
          type="text"
          v-model="address.city"
        )

    .alert.alert-danger.is-invalid.mt-3.mb-3(v-if="!valid && dirty")
      | {{ $t('js.components.profile.job_offer.answer_all_questions') }}
    .mt-3.mb-5.text-center
      button.btn.btn-accent.btn-lg.w-100(type="button" @click="submitApplication()" id="submit_job_application")
        | {{ $t('js.components.profile.job_offer.send_application') }}

  modal-wrapper

</template>

<script lang="ts" setup>
import { ref, computed, nextTick } from "vue"
import { profile } from "@/talents/profile/profile"
import { FormInput } from "@/elements"
import { talentsProfileJobApplicationPath } from "@/routes"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import EditApplicationProfile from "@/talents/profile/components/edit/EditApplicationProfile.vue"
import EditEducation from "@/talents/profile/components/edit/EditEducation.vue"
import EditSkillset from "@/talents/profile/components/edit/EditSkillset.vue"
import EditRecommendation from "@/talents/profile/components/edit/EditRecommendation.vue"
import Question from "@/components/apply/Question"
import ModalWrapper from "@/talents/profile/components/ModalWrapper.vue"
import type { ValidationRuleWithoutParams } from "@vuelidate/core"
import csrfToken from "@/utils/csrfToken"

const props = defineProps({
  job: {
    type: Object,
    required: true
  },
  recommendations: {
    type: Array<Recommendation>,
    required: true
  },
  applicationInfo: {
    type: Object as PropType<ApplicationInfo | null>,
    required: true
  }
})

const questionAnswers = ref<Record<string, Record<string, ValidationRuleWithoutParams>>>({})
const address = ref<Record<string, string>>({})
const dataSent = ref<boolean>(false);
const isSubmitting = ref<boolean>(false);
const error = ref<boolean>(false);
const showDetails = ref<boolean>(false)
const dirty = ref<boolean>(false)

const appProfileRef = ref<{ valid: boolean, touchFields: Function, selectedFiles: string[] }>({ valid: false, touchFields: () => {}, selectedFiles: [] })

const applicationInfo = computed(() => {
  return props.applicationInfo
})

const addressRequired = computed(() => {
  return applicationInfo.value?.required_fields.includes("postal_address")
})

const valid = computed(() => {
  return addressValid.value && questionAnswersValid.value && appProfileRef.value.valid
})

const addressValid = computed(() => {
  if (!addressRequired.value) {
    return true
  }

  return Object.keys(address.value).every(key => {
    return address.value[key].length > 0
  }) && Object.keys(address.value).length === 3
})

const questionAnswersValid = computed(() => {
  if (!applicationInfo.value?.questions) {
    return true
  }

  return Object.keys(questionAnswers.value).every(key => {
    return Object.keys(questionAnswers.value[key]).length > 0
  }) && Object.keys(questionAnswers.value).length === applicationInfo.value?.questions.length
})

function scrollToInvalid() {
  const invalid = document.querySelector(".is-invalid")
  if (invalid) {
    invalid.scrollIntoView({ behavior: "smooth", block: "center" })
  }
}

function submitApplication() {
  dirty.value = true
  appProfileRef.value.touchFields()
  if (!valid.value) {
    nextTick(() => {
      scrollToInvalid()
    })
    return
  }

  const form = {
    email: profile.value.data.email,
    question_answers: questionAnswers.value,
    address: address.value,
    job_id: props.job.id,
    signed_ids: appProfileRef.value.selectedFiles,
  }

  isSubmitting.value = true;
  fetch(talentsProfileJobApplicationPath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      talents_profile_form: form,
    }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        error.value = true;
      }
      dataSent.value = true;
      isSubmitting.value = false;
    });
}
</script>

<style scoped>
.ml-1 {
  margin-left: 0.5rem;
}
</style>
