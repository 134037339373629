<template>
  <div class="lazyload-wrapper" ref="mainElement">
    <transition name="fade">
      <slot v-if="loaded"></slot>
      <div v-else="" :style="{ minHeight: minHeight + 'px' }">
        <slot name="loading">{{ $t('js.components.lazyload.wird_geladen') }}</slot>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from "vue"

const mainElement = ref<HTMLElement | null>(null)
const loaded = ref(false)

const props = defineProps({
  threshold: {
    type: Number,
    default: 0.0,
  },
  rootMargin: {
    type: String,
    default: "0px"
  },
  minHeight: {
    type: Number,
    default: 400,
  },
  root: {
    type: HTMLElement,
    default: () => document.querySelector("main"),
  },
})

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.disconnect()
          loaded.value = true
        }
      })
    },
    {
      rootMargin: props.rootMargin,
      threshold: props.threshold,
      root: null,
    }
  )
  observer.observe(mainElement.value!)
})
</script>

<style scoped>
.lazyload-wrapper {
  height: auto;
  transition: height 0.5s ease-in;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
