// https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
export default (object: Record<string, any>, path: string): any => {
  const s = path
    .replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    .replace(/^\./, "") // strip a leading dot
  const a = s.split(".")
  let element = object
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i]
    if (k in element) {
      element = element[k]
    } else {
      return
    }
  }
  return element
}
