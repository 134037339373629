<template>
  <div>
    <div v-if="open" class="gtm-info"><div class="container">
      <p>
        Wir und ausgewählte Dritte setzen Cookies oder ähnliche Technologien für technische Zwecke ein und – mit Ihrer Einwilligung – für
        Funktionalität, Nutzererfahrung, Erfolgsmessung und Marketing, wie in der Cookie-Richtlinie beschrieben. Im Fall der Ablehnung könnten
        bestimmte Funktionen nicht verfügbar sein.
      </p>
      <p>Ihre Einwilligung können Sie jederzeit erteilen, verweigern oder widerrufen.</p>
      <p>
        Verwenden Sie den „Alle akzeptieren“-Button, um dem Einsatz solcher Technologien zuzustimmen. Verwenden Sie den „Nur Essentielle“-Button oder schließen Sie diesen Hinweis, um fortzufahren ohne zuzustimmen.
        <a href="/datenschutz" target="_blank">Hinweis zu unseren Datenschutzbestimmungen</a>.
      </p>
      <div>
        <button class="btn btn-lg cookies-eu-ok" @click.prevent="accept">Alle akzeptieren</button>
        <button class="btn btn-outline-dark" @click.prevent="reject">Nur Essentielle</button>
      </div>
    </div>
    </div>
    <div v-if="!open">
      <button class="btn btn-dark btn-sm cookie-settings" @click="open = true">Cookie-Einstellungen</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue"

import Cookies from "js-cookie"
window.dataLayer = window.dataLayer || []
window.gtag = function () {
  window.dataLayer.push(arguments)
}
declare global {
  interface Window {
    dataLayer: any[]
    gtag: (...args: any[]) => void
  }
}

const props = defineProps<{
  tag: string
}>()

const open = ref(true)

let gtmLoaded = false

const loadGTM = () => {
  if (gtmLoaded) {
    return
  }
  const script = document.createElement("script")
  script.src = `https://www.googletagmanager.com/gtag/js?id=${props.tag}`
  script.async = true
  script.onload = () => {
    window.gtag("js", new Date())
    window.gtag("config", props.tag)
  }
  document.head.appendChild(script)
  gtmLoaded = true
}

const accept = () => {
  Cookies.set("gtm_consent", "true", { expires: 365 })
  Cookies.set('cookie_eu_consented', 'true', { expires: 365, path: '/' })
  loadGTM()
  open.value = false
}
const reject = () => {
  Cookies.set("gtm_consent", "false", { expires: 365 })
  open.value = false
}

onMounted(() => {
  const curVal = Cookies.get("gtm_consent")
  if (curVal === "true") {
    loadGTM()
  }
  if (curVal !== undefined) {
    open.value = false
  }
})
</script>

<style scoped>
.gtm-info {
  position: fixed;
  bottom: 0;
  padding: 25px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100vw;
  text-align: center;
}
.cookie-settings {
  position: fixed;
  bottom: -1px;
  left: 15px;
  border-radius: 5px 5px 0 0;
}
</style>
