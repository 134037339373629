<template lang="pug">
Teleport(to="body")
  .modal.fade(tabindex="-1" :aria-labelledby="id + 'label'" aria-hidden="true" :id='id' ref='modal' v-bind="$attrs")
    .modal-dialog(:class='sizeClass')
      .modal-content.border-portal-main.border.border-5
        .modal-header
          slot(name='header')
            h5.modal-title(:id="id + 'label'")
              | {{title}}
            button(type="button" class="modal-button btn-close" data-bs-dismiss="modal" aria-label="Close")
        .modal-body
          slot
        .modal-footer(v-if='!hideFooter')
          slot(name='footer')
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect, onMounted, onUnmounted } from "vue"
import Modal from "bootstrap/js/src/modal"
import type { Modal as ModalType } from "bootstrap"

const props = defineProps<{
  id: string
  title?: string
  size?: string,
  modelValue?: boolean
  static?: boolean
  hideFooter?: boolean
}>()

const sizeClass = computed(() => {
  switch (props.size) {
    case "xl":
      return "modal-xl"
    case "sm":
      return "modal-sm"
    case "lg":
      return "modal-lg"
    default:
      return ""
  }
})

const emit = defineEmits(['update:modelValue'])

const modal = ref<HTMLElement | null>(null)
let bModal = null as ModalType | null
let isVisible = false

const eventListener = (event: Event) => {
  emit('update:modelValue', event.type == 'show.bs.modal')
  if (event.type == 'hide.bs.modal') {
    isVisible = false
  }
}
onMounted(() => {
  if (modal.value) {
    if (props.static) {
      bModal = new Modal(modal.value, { backdrop: 'static', keyboard: false })
    } else {
      bModal = new Modal(modal.value)
    }
    modal.value.addEventListener('hide.bs.modal', eventListener)
    //modal.value.addEventListener('show.bs.modal', eventListener)
  }
})
onUnmounted(() => {
  bModal
  if (modal.value) {
    modal.value.removeEventListener('hide.bs.modal', eventListener)
    //modal.value.removeEventListener('show.bs.modal', eventListener)
  }
  if (bModal) {
    bModal.dispose()
  }
})


watchEffect(() => {
  if (modal.value) {
    if (props.modelValue) {
      if (!isVisible) {
        isVisible = true
        bModal?.show()
      }
    } else {
      if (isVisible) {
        bModal?.hide()
        // document.querySelectorAll('.modal-backdrop').forEach((el) => el.classList.remove('in'))
      }

    }
  }
})
</script>
<style scoped>
.modal {
  z-index: 99999;
}

</style>
