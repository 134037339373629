<template lang="pug">
.card.profile-card
  .card-body(v-if="form")
    .row
      .col-12(v-if="isSubmitting")
        .loading-progress-bar.mt-2.mb-2
          .loading-progress-bar__progress
      .col-12: .row
        label.mb-2(for="salary_input_fields")
          | {{ $t('js.components.talent_profile.salary_intervals.label') }}
        .col-7.mb-4
          salary-input-field(
            v-model="proxySalary"
            :options="{ currency: 'EUR', currenyDisplay: 'symbol', locale: 'de-DE' }"
          )
        .col-5.mb-4
          Select(
            v-model="form.salary_interval"
            :options="options['salary_intervals']"
          )
      .col-12(v-if="form && form.locations"): .mb-4
        label.mb-2(for="work_locations")
          | {{ $t('js.components.talent_profile.work_location.label') }}
        work-location(
          v-model="form.locations"
          :work_location_state="form.work_location_state"
          :radius="form.radius"
          @update:work_location_state="form.work_location_state = $event"
          @update:radius="form.radius = $event"
          :showError="false"
        )
        .alert.alert-danger.p-1.mt-3(v-show="!valid")
          small.text-danger
            | {{ $t('js.components.talent_profile.errors.work_locations') }}

      .col-12: .mb-4
        label.mb-2(for="sub_job_types")
          | {{ $t('js.components.talent_profile.sub_job_types.text') }}
        CheckboxGroup(
          v-model="form.sub_job_types_preference"
          name="sub_job_type"
          :options="options['sub_job_types']"
          :inline="true"
        )
      .col-12: .mb-4
        label.mb-2(for="job_titles")
          | {{ $t('js.components.talent_profile.job_titles.label_short') }}
        table.table.table-striped.table-hover.table-sm
          thead
            tr
              th
                | {{ $t('js.components.profile.tables.stellenbezeichnung') }}
          tbody
            tr(v-for="jobTitle in form.job_titles_preference")
              td
                | {{ jobTitle }}
        .pull-right.mb-4
          span.open-modal(@click="setModalContent('job_titles')")
            | {{ $t('js.components.profile.tables.bearbeiten') }}
            i.fas.fa-edit(style="margin-left: 0.5rem")
  .card-footer(:class='{"card-footer-sticky": formChanged }')
    .d-flex.justify-content-end.gap-2
      button.btn.btn-sm(@click="editing = false" :class="(!dataSent || error) ? 'btn-outline-danger' : 'btn-outline-success'")
        i.fas.fa-times(style="margin-right: 0.5rem")
        span(v-if="!dataSent || error")
          | {{ $t('js.components.talent_profile.generic.abbrechen') }}
        span(v-else)
          | {{ $t('js.components.talent_profile.generic.schliessen') }}
      button.btn.btn-primary.btn-sm(
        :class="{'tilt-shaking': formChanged && !dataSent}"
        :disabled="isSubmitting || !formChanged || !valid"
        @click="submitWrapper()"
      )
        i.fas.fa-save(style="margin-right: 0.5rem")
        | {{ $t('js.components.talent_profile.generic.speichern') }}
</template>

<script setup lang="ts">
import { computed, provide } from 'vue';
import SalaryInputField from "@/talents/creation_form/components/SalaryInputField.vue"
import { Select, CheckboxGroup } from "@/elements"
import WorkLocation from "@/talents/creation_form/components/WorkLocation.vue"
import { setModalContent } from  "@/talents/profile/contents/modal"
import { getOptionValues } from "@/talents/creation_form/talents_form"
import { useSubmit } from "@/talents/profile/profile"
import { minLength } from "@/utils/validators"
import { requiredIf } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { useI18n } from "vue-i18n"
const { t } = useI18n()

const { submit, error, isSubmitting, dataSent, form, formChanged } = useSubmit('job_desire');

const options = {
  salary_intervals: getOptionValues("salary_intervals"),
  sub_job_types: getOptionValues('sub_job_types')
};

const rule = computed(() => ({
  locations: {
    required: requiredIf(() => {
      if (form.value.work_location_state == 'any'|| form.value.work_location_state == 'remote') {
        return false
      }
      return true;
    }),
    minLength: minLength(1)
  }
}))

const v$ = useVuelidate(rule, form, {})
provide("v$", v$)

const valid = computed(() => {
  return v$.value && v$.value.locations && !v$.value.locations.$invalid
})

const props = defineProps<{
  modelValue: boolean
}>();

const proxySalary = computed({
  get: () => {
    return form.value.salary_preference
  },
  set: (value: number) => {
    form.value.salary_preference = `${value}`;
  },
});

const emits = defineEmits(["update:modelValue", "submit:makeToast"])

const editing = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emits("update:modelValue", value);
  },
});

function submitWrapper() {
  if (!valid.value) return;
  submit();
  const type = error.value ? "error" : "success"
  emits("submit:makeToast", {
    message: t(`js.components.talent_profile.generic.status.${type}`),
    type: type,
  })
}

</script>
