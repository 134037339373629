import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"

const animationDuration = 6000
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration)
// An ease-out function that slows the count as it progresses
const easeOutQuad = (t: number) => 1 - Math.pow(1 - t, 5)
// const easeOutQuad = (t: number) => t * (2 - t)

const animateCountUp = (el: HTMLElement, countTo: number, fromValue: number) => {
  let frame = 0
  const length = countTo.toString().length
  // Start the animation running 60 times per second
  const counter = setInterval(() => {
    frame++
    const progress = easeOutQuad(frame / totalFrames)
    const y = (countTo - fromValue) * (progress - 0) + fromValue

    const currentCount = Math.round(y)
    el.innerHTML = currentCount
      .toString()
      .padStart(length, "0")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")

    if (frame === totalFrames) {
      clearInterval(counter)
    }
  }, frameDuration)
}

export default class extends Controller {
  countTo: number = 0
  isVisible = false

  declare fromValue: number
  static values = {
    from: {
      type: Number,
      default: 0,
    },
  }

  connect() {
    const [_, stop] = useIntersection(this, { threshold: 0 })
    this.countTo = parseInt(this.element.innerHTML, 10)
    // this.stop = stop
  }
  appear() {
    animateCountUp(this.element as HTMLElement, this.countTo, this.fromValue)
  }
}
