<template lang="pug">
overview-card(
  :title="$t('js.components.profile.tables.firmenblockliste')"
  icon="fa-ban"
  :showProgress="false"
  :hint="$t(`js.components.progress.hint.company_blocklist`)"
)
  template(v-slot:toggle-actions)
    button.btn.btn-outline-primary.btn-sm.no-transform.d-flex.align-items-center(@click="editing = true" :disabled="editing" id="edit_company_blocklist")
      i.fas.fa-user-edit(style="margin-right: 0.5rem")
      span(v-if="!editing")
        | {{ $t("js.components.talent_profile.generic.bearbeiten") }}
      span(v-else)
        | {{ $t("js.components.talent_profile.generic.in_bearbeitung") }}
  template(v-slot:actions)
    collapse-transition
      .mt-3(v-if="editing")
        edit-company-blocklist(
          v-model="editing"
          @submit:makeToast="emits('submit:makeToast', $event)"
        )
</template>

<script setup lang="ts">
import { ref } from "vue"
import EditCompanyBlocklist from "@/talents/profile/components/edit/EditCompanyBlocklist.vue"
import OverviewCard from "@/talents/profile/components/OverviewCard.vue"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"

const editing = ref(false)
const emits = defineEmits(['submit:makeToast'])

</script>

