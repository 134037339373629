<template>
  <div :style='eventStreamStyle' ref='wrapper'>
    <div v-for="(event, i) in showEvents" class='event-stream__event' :class=' i == 0 ? "bg-accent" : ""'>
      <div :style="{ fontSize: '0.9rem', color: timeColor }">{{ event.time }}</div>
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" :d="icons[event.event_name]" />
      </svg>
      <!-- i18n-use t("js.components.eb_livestream.events.#{foo}") -->
      <div>{{ $t(`js.components.eb_livestream.events.${event.event_name}`) }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, onBeforeUnmount, computed, Ref } from "vue"

const events = ref<StreamEvent[]>([])
const showEvents = ref<StreamEvent[]>([])

const wrapper = ref<HTMLDivElement | null>(null)

const tick = () => {
  const now = new Date().toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })


  const countBefore = showEvents.value.length
  showEvents.value = events.value.filter((e) => e.time <= now)
  showEvents.value = showEvents.value.sort((a, b) => a.time > b.time ? -1 : 1)
  if (wrapper.value && countBefore != showEvents.value.length) {
    setTimeout(() => {
      if (wrapper.value)
        wrapper.value.scrollTop = 0
    }, 200)
  }
}

let interval: NodeJS.Timer | null = null

const icons = {
  "passus_click": "M21,12L14,5V9C7,10 4,15 3,20C5.5,16.5 9,14.9 14,14.9V19L21,12Z",
  "passus_click_recommended": "M15,4A4,4 0 0,1 19,8A4,4 0 0,1 15,12A4,4 0 0,1 11,8A4,4 0 0,1 15,4M15,5.9A2.1,2.1 0 0,0 12.9,8A2.1,2.1 0 0,0 15,10.1C16.16,10.1 17.1,9.16 17.1,8C17.1,6.84 16.16,5.9 15,5.9M15,13C17.67,13 23,14.33 23,17V20H7V17C7,14.33 12.33,13 15,13M15,14.9C12,14.9 8.9,16.36 8.9,17V18.1H21.1V17C21.1,16.36 17.97,14.9 15,14.9M5,13.28L2.5,14.77L3.18,11.96L1,10.08L3.87,9.83L5,7.19L6.11,9.83L9,10.08L6.8,11.96L7.45,14.77L5,13.28Z",
  "apply_submit": "M22 5.5H9C7.9 5.5 7 6.4 7 7.5V16.5C7 17.61 7.9 18.5 9 18.5H22C23.11 18.5 24 17.61 24 16.5V7.5C24 6.4 23.11 5.5 22 5.5M22 16.5H9V9.17L15.5 12.5L22 9.17V16.5M15.5 10.81L9 7.5H22L15.5 10.81M5 16.5C5 16.67 5.03 16.83 5.05 17H1C.448 17 0 16.55 0 16S.448 15 1 15H5V16.5M3 7H5.05C5.03 7.17 5 7.33 5 7.5V9H3C2.45 9 2 8.55 2 8S2.45 7 3 7M1 12C1 11.45 1.45 11 2 11H5V13H2C1.45 13 1 12.55 1 12Z"
}

onMounted(() => {
  fetch("/arbeitgeber/api/event_stream.json")
    .then((r) => r.json())
    .then((r) => {
      events.value = r
      interval = setInterval(tick, 5000)
      tick()
    })
})
onBeforeUnmount(() => {
  if (interval) clearInterval(interval)
})

const timeColor = computed(() => {
  const path = window.location.pathname
  if (path === '/it-personalvermittlung') {
    return '#c4c2c2'
  } else {
    return '#333';
  }
});
const eventStreamStyle: Ref<{ [key: string]: string }> = computed(() => {
  const path = window.location.pathname
  if (path === '/it-personalvermittlung') {
    return {
      maxHeight: '331px',
      overflowY: 'scroll',
      scrollBehavior: 'smooth',
      padding: '0 50px 10px 0'
    }
  } else {
    return {
      maxHeight: '300px',
      overflowY: 'scroll',
      scrollBehavior: 'smooth',
      padding: '0 50px 10px 0'
    }
  }
});
</script>

<style scoped>
.event-stream__event {
  display: flex;
  gap: 25px;
  padding: 2px 10px;
  border-radius: 15px;
}
</style>
