<template>
  <div>
    <div class="form-group">
      <label>
        <div class="input-group">
          <input
            v-model="newUrl"
            id="new_url_input"
            type="url"
            class="form-control"
            placeholder="https://deine-website.de/"
            name="url"
            style="font-size: 1.2rem"
            @keydown.enter.stop.prevent="submit"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-primary"
              type="button"
              style="font-size: 1.2rem"
              id="add_url_button"
              :disabled="!isValid"
              @click.prevent.stop="submit"
            >
              {{ $t("js.components.talent_profile.pages.add") }}
            </button>
          </div>
        </div>
      </label>
      <small class="form-text text-muted">
        {{ $t("js.components.talent_profile.pages.add_hint") }}
      </small>
    </div>
    <div class="mt-2 mb-2" v-if="isSubmitting">
      <div class="loading-progress-bar">
        <div class="loading-progress-bar__progress"></div>
      </div>
    </div>
    <div v-for="link in profileLinks" class="clearfix d-flex">
      <div class="flex-grow-1" v-html="link.view"></div>
      <button
          type="button"
          class="btn btn-sm btn-outline-danger"
          @click.prevent="removeLink(link)"
          >
          <i class="fas fa-trash-alt"></i>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue"
import "@/style/components/_profile_links.scss"
import { talentsProfileLinksPath, talentsProfileLinkPath } from "@/routes"
import csrfToken from "@/utils/csrfToken"

const newUrl = ref<string>("")
const profileLinks = ref<TalentsLink[]>([])
const isSubmitting = ref<boolean>(false);
const error = ref<boolean>(false);

const links = computed(() => {
  return profileLinks.value.map(l=> l.data.link)
})

const isValid = computed(() => {
  return (
    newUrl.value.match(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    ) != null && !links.value.includes(newUrl.value)
  )
})


const emits = defineEmits(["makeToast"])

function submit() {
  if (!isValid.value) return

  const trimmed = newUrl.value.trim();
  if (trimmed.length === 0) return

  const params = {
    url: trimmed,
  }
  const path = talentsProfileLinkPath();
  isSubmitting.value = true;
  fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      talents_profile_form: params,
    }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        error.value = true;
      } else {
        profileLinks.value.push(data);
        newUrl.value = "";
      }
      isSubmitting.value = false;
      const type = error.value ? "error" : "success"
      emits("makeToast", type)
    });
}

function removeLink(link: TalentsLink) {
  const id = link.data.id;
  if (!id) return;

  const path = talentsProfileLinkPath({id: id});
  fetch(path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {

        error.value = true;
      } else {
        profileLinks.value = data;
      }
      const type = error.value ? "error" : "success"
      emits("makeToast", type)
    });
}

onMounted(() => {
  const path = talentsProfileLinksPath();
  fetch(path)
    .then(response => response.json())
    .then(data => {
      profileLinks.value = data;
    });
})
</script>

<style scoped>
label {
  display: block !important;
}
.input-group-append button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-outline-danger {
  border-color: rgba(255, 0, 0, 0.3);
  border-radius: 0 5px 5px 0;
}
</style>
