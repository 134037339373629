import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String
  }

  connect() {
    if (navigator.share) {
      this.element.classList.remove('d-none');
    }
  }

  share() {
    if (navigator.share) {
      navigator.share({
        title: this.titleValue,
        text: this.textValue,
        url: this.urlValue,
      }).catch(error => {
        console.error("Es gab ein Problem beim Teilen:", error);
      });
    }
  }
}
