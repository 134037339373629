import { minLength, maxLength, required, email } from "@/utils/validators"
import { requiredIf } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { computed, nextTick } from "vue"
import { useStorage, debounceFilter } from '@vueuse/core'
import i18n from "@/utils/i18n"

const defaultForm: TalentForm = {
  email: "",
  portal_type: undefined,
  german_residency: null,
  ready_to_move: null,
  work_location_state: 'onsite',
  locations: [],
  radius: null,
  competences: [],
  salary_interval: null,
  salary_preference: null,
  job_titles_preference: [],
  sub_job_types_preference: [],
  degree: null,
  country_code: 'DE',
  company_blocklist: [],
  optional_text: '',
  language_skills: [
    {
      language_iso_code: 'en',
      level: 'c1'
    },
    {
      language_iso_code: 'de',
      level: 'c1'
    }
  ],
  recommendation_codes: [],
}

const defaultOnboardingForm: OnboardingForm = {
  has_prefill: "no",
  is_working: null,
  has_last_employer: null,
  citizenship: null,
}

const form = useStorage<TalentForm>("talent-cache", defaultForm , localStorage, { eventFilter: debounceFilter(1000)  } )

const resetForm = () => {
  localStorage.removeItem("talent-cache")
}

export default () => {
  const rules = computed(() => {
    const base = {
      portal_type: {
        required: required
      },
      job_titles_preference: {
        required: required,
        minLength: minLength(1)
      },
      competences: {
        required: required,
        minLength: minLength(1),
        maxLength: maxLength(5)
      },
      email: {
        required: required,
        email: email
      },
      locations: {
        required: requiredIf(() => {
          if (form.value.work_location_state == 'any' || form.value.work_location_state == 'remote') {
            return false
          }
          return true;
        }),
        minLength: minLength(1)
      },
      degree: {
        required: required
      },
      language_skills: {
        required: required,
        minLength: minLength(1)
      },
      sub_job_types_preference: {
        required: required,
        minLength: minLength(1)
      },
      country_code: {
        required: required
      },
    }
    return base
  })
  const v$ = useVuelidate(rules, form, {})
  return { rules, form, v$, resetForm }
}

const onboardingForm = useStorage<OnboardingForm>("onboarding-form", defaultOnboardingForm, localStorage)

function resetOnboardingForm() {
  localStorage.removeItem("onboarding-form")
}

function useValidations(fields: string[], v$: any) {
  const valid = computed(() => {
    const arr = fields.map((f: any) => fieldValid(f))
    return !arr.includes(false)
  })

  const touchFields = () => {
    fields.forEach((field: any) => {
      v$.value[field].$touch()
    })
    scrollToFirstError()
  }

  const fieldValid = (field: string) => {
    return v$.value && v$.value[field] && !v$.value[field].$invalid
  }

  const scrollToFirstError = () => {
    nextTick(() => {
      const firstError = document.querySelector(".my-alert")
      if (firstError) {
        firstError.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    })
  }

  return { touchFields, valid, fieldValid }
}

const nullable = ["salary_intervals"]

type fieldType = 'degrees' | 'salary_intervals' | 'experience_levels' | 'sub_job_types' | 'sub_job_types_group_1' | 'sub_job_types_group_2'

const namespace: Record<fieldType, string> = {
  degrees: "js.components.talent_profile.degrees.options",
  salary_intervals: "js.components.talent_profile.salary_intervals.options",
  experience_levels: "js.components.talent_profile.experience_levels.options",
  sub_job_types: "js.components.talent_profile.sub_job_types.options",
  sub_job_types_group_1: "js.components.talent_profile.sub_job_types.group_1",
  sub_job_types_group_2: "js.components.talent_profile.sub_job_types.group_2",
} as const

function getOptionValues(field: fieldType){
  //@ts-ignore
  const optionsObj = i18n.global.tm(namespace[field])
  const t = i18n.global.t
  const options: FormOption[] = Object.keys(optionsObj).map((k) => {
    return { value: k, text: t(namespace[field] + "." + k)}
  })

  if (nullable && nullable.includes(field)) {
    options.unshift({ value: null, text: "" })
  }

  return options
}

export { form, useValidations, getOptionValues, onboardingForm, resetOnboardingForm, resetForm }
