<template lang="pug">
div
  .loader.profile-loader(v-show="isSubmitting")
    i.fa-spin.fa.fa-spinner.fa-6x
  .row
    .col-12
      .alert.alert-danger.mb-3(v-if="error")
        .text-center
          h4.mb-3
            | {{ $t('js.components.talent_profile.generic.status.error') }}
          i.fas.fa-exclamation-circle.fa-6x
      .alert.alert-warning(style="font-size: 1.2rem;")
        | {{ $t('js.components.profile.job_offer.reject_survey.title') }}
  .row.p-3(v-if="!sent")
    .col-12: .text-center.mb-5
      h4 {{ $t('js.components.profile.job_offer.reject_survey.why', {company: organisation.name}) }}
    .col-12(v-for="question in questions" :key="question"): .mb-3
      .mb-3(style="font-size: 1.2rem;")
        | {{ $t(`js.components.profile.job_offer.reject_survey.questions.${question}.title`) }}
      .d-flex.justify-content-between.flex-column-on-sm(style="flex-wrap: wrap; font-size: 1.2rem;")
        .form-check(v-for="option in answerOptions" :key="option.value.toString()")
          input.form-check-input(
            :id="`${question}-${option.value}`"
            :name="question"
            type="radio"
            :checked="answers[question] === option.value"
            @input="answers[question] = option.value"
          )
          label(:for="`${question}-${option.value}`" v-html='option.text')
      hr
    .col-12: .mb-3
      .form-group(style="font-size: 1.2rem;")
        label.mb-3(for="other") {{ $t('js.components.profile.job_offer.reject_survey.questions.other.title') }}
        textarea.form-control(:id="`other`" v-model="answers.other")
      hr

    .col-12
      .form-group(style="font-size: 1.1rem;")
        .form-check
          input.form-check-input(type="checkbox" v-model="blockCompany" id="block-company-check")
          label.form-check-label(for="block company")
            | {{ $t('js.components.profile.job_offer.reject_survey.no_more_offer_from') }}

      button.btn.btn-primary.btn-lg.w-100.mt-3(@click="rejectOffer()")
        | {{ $t('js.components.profile.job_offer.reject_survey.submit') }}

  .alert.alert-success.p-5.mt-5(v-else)
    .text-center.mb-3
      h4 {{ $t('js.components.profile.job_offer.reject_survey.vielen_dank') }}
    .text-center.mb-3
      i.fas.fa-check-circle.fa-6x
    .text-center.mb-3
      p {{ $t('js.components.profile.job_offer.reject_survey.es_ist_wichtig') }}

</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import csrfToken from "@/utils/csrfToken"
import { talentsConversationDeclineHirePath } from "@/routes"

const t = useI18n().t

const answers = ref<Record<string, string>>({})
const error = ref<boolean>(false)
const isSubmitting = ref<boolean>(false)
const blockCompany = ref<boolean>(false)
const sent = ref<boolean>(false)

const questions = [
  'high_requirements',
  'low_salary',
  'unfitting_description',
  'unfitting_location',
]

const answerOptions = computed(() => {
  return [
    { value: 'no', text: t('js.components.profile.job_offer.reject_survey.questions.options.no') },
    { value: 'rather_no', text: t('js.components.profile.job_offer.reject_survey.questions.options.rather_no') },
    { value: 'not_sure', text: t('js.components.profile.job_offer.reject_survey.questions.options.not_sure') },
    { value: 'rather_yes', text: t('js.components.profile.job_offer.reject_survey.questions.options.rather_yes') },
    { value: 'yes', text: t('js.components.profile.job_offer.reject_survey.questions.options.yes') }
  ]
})

const props = defineProps({
  organisation: {
    type: Object,
    required: true,
  },
  conversationId: {
    type: Number,
    required: true,
  },
})

function rejectOffer() {
  isSubmitting.value = true
  fetch(talentsConversationDeclineHirePath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      conversation: {
        id: props.conversationId,
        rejected_reasons: answers.value,
        block_company: blockCompany.value,
      },
    }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        error.value = true;
        console.error(data.error)
      }
      else {
        isSubmitting.value = false
        sent.value = true
      }
    });
}

</script>
