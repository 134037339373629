<template lang="pug">
.container
  .mb-8.d-flex.flex-wrap.gap-4.justify-content-center
    span.btn.rounded-pill.btn-outline-primary(:class="{ active: isActiveService('all')}" @click="changeService('all')" )
      | {{ $t('js.components.history_event_page.alle_services') }}
    span.btn.rounded-pill.btn-outline-primary(v-for="service in services" :class="{ active: isActiveService(service)}" @click="changeService(service)")
      | {{ service.toUpperCase() }}
     
  Timeline(
    :events="filteredEvents"
    :key="serviceName"
    :editable="editable"
    :line-color="lineColor"
  )
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import { Timeline } from "@/elements"

const props = defineProps<{
  services: string[],
  allEvents: HistoryEvent[],
  editable: boolean,
}>()

const serviceName = ref("all")

const filteredEvents = computed(() => {
  return props.allEvents?.filter(e => {
    if (serviceName.value == 'all') return true
    return e.service_name == serviceName.value
  })
})

const lineColor = computed(() => {
  const color = filteredEvents.value[0]?.primary_color || "#23788f"
  return color.startsWith('#') ? color : "#23788f"
})

function changeService(sName: string) {
  serviceName.value = sName
}

function isActiveService(sName: string) {
  return serviceName.value == sName
}

</script>

<style scoped>
</style>
