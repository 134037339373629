import { ref } from "vue"

export default () => {
  const error = ref<string | null>(null)
  const isSubmitting = ref(false)

  const unknownError = (text: string) => {
    error.value = `Unbekannter Fehler: ${text}`
  }

  const extractJsonError = (data: unknown) => {
    if (typeof data == "object" && data && "error" in data && data.error) {
      const maybeError = data.error
      if (typeof maybeError == "object" && maybeError instanceof Array) {
        error.value = maybeError.join("\n")
        return true
      } else if (typeof maybeError == "string") {
        error.value = maybeError
        return true
      }
    }
  }

  return {
    error,
    fromAxiosCatch(err: any) {
      console.error(err)
      isSubmitting.value = false
      if (extractJsonError(err.response.data)) return
      unknownError(err)
    },
    async fromFetchCatch(err: Response) {
      error.value = null
      isSubmitting.value = false
      const contentType = err.headers.get("content-type")
      if (contentType && contentType.includes("application/json")) {
        const data = await err.json()
        if (extractJsonError(data)) return
        unknownError(err.statusText)
      } else {
        unknownError(err.statusText)
      }
    },
    reset() {
      error.value = null
      isSubmitting.value = true
    },
    isSubmitting,
  }
}
