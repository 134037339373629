import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.onClick.bind(this))
  }
  disconnect() {
    this.element.removeEventListener("click", this.onClick.bind(this))
  }
  onClick(event: Event) {
    event.preventDefault()
    const el = this.element as HTMLAnchorElement
    const targetSelector = el.getAttribute("href")
    if (targetSelector == '#') {
      // scroll to top smoothely
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      return
    }
    if (!targetSelector) return
    const target = document.querySelector(targetSelector)
    if (target instanceof HTMLElement) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
    history.pushState(null, "", targetSelector)
  }
}
