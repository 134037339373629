<template>
  <l-map ref="map" :maxZoom='10' :zoom='zoom' style="height: 300px; border-radius: 15px; overflow: hidden" :center="center" :options='leafletOptions' v-if='initialized'>
    <l-tile-layer url="https://tiles.pludoni.de/osm/{z}/{x}/{y}.png" attribution="&copy; OpenStreetMap contributors" />
    <l-marker :lat-lng="[p.lat, p.lon]" v-for="p in points">
      <l-popup :content="p.name" />
    </l-marker>
  </l-map>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue"
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LPopup, LMarker } from "@vue-leaflet/vue-leaflet"
import { LatLngBounds, LatLng } from "leaflet"

const map = ref<Element | null>()

type Point = {
  lat: number
  lon: number
  name: string
}

const props = defineProps<{ points: Point[] }>()
const center = ref<LatLng>(
  new LatLng(
    props.points.map((p) => p.lat).reduce((a, b) => a + b) / props.points.length,
    props.points.map((p) => p.lon).reduce((a, b) => a + b) / props.points.length
  )
)

const minZoom = ref(4)
const zoom = ref(5)
const initialized = ref(false)

const leafletOptions = computed(() => ({
  attributionControl: false,
  boxZoom: false,
  minZoom: minZoom.value,
  zoom: zoom.value,
  scrollWheelZoom: false,
  maxBounds: new LatLngBounds(
    new LatLng(90, 180),
    new LatLng(-90, -180)
  )
}))

onMounted(() => {
  const latMin = Math.min.apply(
    null,
    props.points.map((e) => e.lat)
  )
  const latMax = Math.max.apply(
    null,
    props.points.map((e) => e.lat)
  )
  const lonMin = Math.min.apply(
    null,
    props.points.map((e) => e.lon)
  )
  const lonMax = Math.max.apply(
    null,
    props.points.map((e) => e.lon)
  )

  const ne = new LatLng(latMax, lonMax)
  const sw = new LatLng(latMin, lonMin)
  const bounds = new LatLngBounds(sw, ne)
  center.value = bounds.getCenter()

  if (props.points.some(m => m.lat > 55 || m.lat < 47 || m.lon < 4.5 || m.lon > 17)) {
    minZoom.value = 2
    zoom.value = 2
  }
  initialized.value = true
})
</script>

<style scoped></style>
