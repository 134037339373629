<template lang='pug'>
.timeline-container
  .timeline-date.d-flex.flex-column.align-items-center
    .text-center
      h5(style="font-size: 15px;")
        | {{ event.event_date }}
    div(v-if="event.logo_path" style="margin-bottom: 8px")
      img.img-fluid-timeline.img-fluid(:src="event.logo_path" alt="Logo" width="150")
    div(v-else)
      span.h5 {{ event.service_name.toUpperCase() }}
  .content(:class="isActivated")
    a.br-word(:href='event.link' target='_blank')
      h4 {{ event.title }}
    img.mt-1.mb-2(v-if="event.image" :src="event.image" loading="lazy" style="width: 100%")
    .br-word(v-html="event.description")
    .row
      .col-12.col-md-6
        span.badge.bg-empfehlungsbund.d-inline-flex.gap-1.text-white(v-if="event.status === '1'")
          | Meilenstein
          i.fas.fa-star
      .col-12.col-md-6.text-right
        a.btn.btn-outline-primary.btn-sm(v-if="editable" :href="path" target="_blank")
          i.fas.fa-edit
  .timeline-circle
    i.timeline-icon.fas.fa-calendar-alt(style="color: white")
</template>

<script lang='ts' setup>
import { computed } from 'vue'
const props = defineProps<{ event: HistoryEvent, isReached: boolean, editable: boolean }>()

const path = computed(() => {
  return `/admin/history_events/${props.event.id}/edit`
})

const isActivated = computed(() => {
  if (props.isReached && props.event.status === "normal") return 'activated'
  if (props.isReached && props.event.status === "milestone") return 'rainbow'
  return ""
})

const borderColor = computed(() => {
  if (props.event.primary_color) return props.event.primary_color
  return "#23788f"
})

</script>

<style scoped>
.activated {
  border-width: 2px;
  border-style: solid;
  border-color: v-bind(borderColor);
}
</style>
