<template lang='pug'>
form(action="/arbeitgeber/angebot", method="post" data-turbo='false' target='_blank')
  input(type='hidden' name='authenticity_token' :value='csrfToken')
  input(type='hidden' name='offer_pdf[has_bms]' :value='offerForm.hasBms')
  input(type='hidden' name='offer_pdf[needs_bewerbung_bot]' :value='offerForm.needsBmsApplicants')
  input(type='hidden' name='offer_pdf[needs_job_crawler]' :value='offerForm.needsBmsJobs')
  input(type='hidden' name='offer_pdf[can_change_rejection]' :value='offerForm.canChangeRejection')
  .form-group.my-2
    input.form-control(v-model='form.firma' type='text' :placeholder="$t('js.components.prices_app.firma')" name='offer_pdf[firma]')
  .form-group.my-2
    textarea.form-control(v-model='form.rechnungsadresse'  :placeholder="$t('js.components.prices_app.rechnungsadresse')" name='offer_pdf[address]')
  .form-group.my-2
    input.form-control(v-model='form.mitarbeiter' type='number' :placeholder="$t('js.components.prices_app.anzahl_der_mitarbeiter')" name='offer_pdf[mitarbeiter]' step='1' min='1' max='100000')
    p.help-block.text-muted.small
      | {{ $t('js.components.prices_app.anzahl_der_in_deutschland') }}
  button(type='submit' class='btn btn-accent' data-disable-with='Bitte warten...' :disabled='isInvalid' @click='disableButton')
    | {{ $t('js.components.prices_app.angebot_erzeugen') }}
</template>

<script lang='ts' setup>
import { watch, onMounted, ref, computed, reactive } from 'vue'
import csrfToken from '@/utils/csrfToken'

const props = defineProps<{
  offerForm: PriceForm,
}>()

const form = reactive({
  firma: '',
  rechnungsadresse: '',
  mitarbeiter: null as number | null,
})
onMounted(() => {
  form.mitarbeiter = props.offerForm.mitarbeiter
})
watch(() => props.offerForm.mitarbeiter, (value) => {
  form.mitarbeiter = value
})


const disableButton = (event: Event) => {
  if (isInvalid.value) {
    event.preventDefault()
    return
  }
  disabled.value = true
}
const disabled = ref(false)
const isInvalid = computed(() => !form.mitarbeiter || !form.firma || !form.rechnungsadresse)

</script>

<style scoped>
</style>
