<template>
  <transition name="fade">
    <button class="btn-lg scroll-to-top btn btn-primary" @click="scrollToTop" :title="$t('js.components.scroll_to_top.title')" v-show="y > 1000">
      <Icon :path="mdiChevronUpCircle" />
    </button>
  </transition>
</template>

<script lang="ts" setup>
import { mdiChevronUpCircle } from "@mdi/js"
import { Icon } from "@/elements"
import { useWindowScroll } from "@vueuse/core"
import { useI18n } from 'vue-i18n'

useI18n()

const { y } = useWindowScroll()

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" })
}
</script>

<style scoped>
button {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
