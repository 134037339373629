import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"
import Wordcloud from "wordcloud"

type TagCloudResponse = {
  text: string,
  weight: number
}

export default class extends Controller {
  stop: () => void = () => {}
  isVisible = false

  connect() {
    const [_, stop] = useIntersection(this, { threshold: 0 })
    this.stop = stop
  }

  appear() {
    this.stop()

    const domElement = this.element
    fetch("/arbeitgeber/api/kanaleo_tag_cloud")
      .then((r) => r.json())
      .then((json) => {
        const data: TagCloudResponse[] = json.data
        const list = data.map((item) => [item.text, Math.round((item.weight * item.weight) / 400)])
        Wordcloud(domElement as HTMLElement, {
          minSize: 5,
          backgroundColor: "rgba(255,255,255,0,01)",
          color() {
            return "#d2d2f2"
          },
          list,
        })
      })
  }
}
