<template>
  <Card class="max-w-lg mb-5 p-3 fit-content" variant="white" neumorphism="white">
    <h1 class="text-2xl font-bold mb-4" ref="mainEl" id='apply_form'>
      {{ $t("js.components.apply_card.title") }}
    </h1>
    <p class="mb-4">
      {{ $t("js.components.apply_card.intro", { company: job.company_name, job: job.title }) }}
    </p>

    <div v-if='job.requirements_info && job.requirements_info.length > 0' class='my-3'>
      <p class='font-bold'> {{ $t("js.components.apply_card.requirements") }}</p>
      <ul>
        <li v-for="req in job.requirements_info" :key="req">
          <span v-html="req"></span>
        </li>
      </ul>
    </div>


    <transition name="smooth">
      <div v-if="open">
        <ApplyForm :job='job' @success='scrollUp'></ApplyForm>
      </div>
    </transition>

    <button @click="open = !open" class="btn btn-primary btn-lg d-block" v-if="!open">
      {{ $t("js.components.apply_card.apply_now_button") }}
      <svg fill="currentColor" viewBox="0 0 20 20" style="width: 30px">
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
    </button>
  </Card>
</template>

<script lang="ts" setup>
import { Button } from "@/elements"
import { ref, watch } from "vue"
import ApplyForm from './ApplyForm.vue'

const mainEl = ref<Element | null>(null)

const scrollUp = () => {
  if (mainEl.value) {
    mainEl.value.scrollIntoView({ behavior: "smooth" })
  }
}

const props = defineProps<{
  job: JobPayload
  isOpen: boolean
}>()

const open = ref(props.isOpen)
watch(
  () => props.isOpen,
  (isOpen) => {
    open.value = isOpen
  }
)
</script>

<style scoped>
.smooth-enter-active,
.smooth-leave-active {
  transition: max-height 0.5s;
}
.smooth-enter,
.smooth-leave-to {
  max-height: 0;
}
</style>
