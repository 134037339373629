<template>
  <div class="d-flex align-items-center border p-4 rounded my-2" :class='selected && !hideSelect ? "bg-selected" : ""'>
    <div class="flex-grow-1">
      <div class="flex-1">
        <a :href="blobPath" target="_blank">
          {{ file.filename }}
        </a>
      </div>
      <div class="text-muted text-small small">{{ fsToString(file.byte_size) }}</div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <div v-if="!hideRemove">
        <Button variant="secondary" size="sm" class="ml-2" @click.prevent="remove">
          {{ $t("js.elements.upload_field.remove") }}
        </Button>
      </div>
      <div v-if="!hideSelect" class="d-flex align-items-center">
        <div class="text-muted" style="margin-right: 0.5rem" v-if="selected">
          <small>
            {{ $t("js.elements.upload_field.selected") }}
          </small>
        </div>
        <input class="form-check-input" type="checkbox" :checked="selected" @change="select" style="font-size: 2rem; margin: 0">
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue"
import fsToString from "@/utils/fsToString"
import { Button } from "@/elements"

const props = withDefaults(defineProps<{
  file: ActiveStorage.Blob
  hideRemove?: boolean
  blobRedirect?: boolean
  hideSelect?: boolean
  selected?: boolean
}>(), {
  hideRemove: false,
  blobRedirect: false,
  hideSelect: false,
  selected: false
})

const emit = defineEmits(["remove", "select"])

const remove = () => {
  emit("remove", props.file)
}

const select = () => {
  emit("select", props.file)
}

const blobPath = computed(() => {
  if (props.blobRedirect) {
    return `/rails/active_storage/blobs/redirect/${props.file.signed_id}/${encodeURIComponent(props.file.filename)}`
  }
  return `/rails/active_storage/blobs/${props.file.signed_id}/${encodeURIComponent(props.file.filename)}`
})

onMounted(() => {
  const url = blobPath.value
  // check if file still exists
  fetch(url, { method: "HEAD" }).then((res) => {
    if (res.status === 404) {
      remove()
    }
  })
})
</script>

<style scoped>
.bg-selected {
  background-color: #d4edda;
}
</style>
