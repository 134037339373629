<template lang="pug">
div
  .d-flex(v-if="success")
    .step-info
      i.fas.fa-check.text-white
    .step-body
      .mb-3
        b
          | {{ $t('js.components.profile.login.email_gesendet') }}
      p
        | {{ $t('js.components.profile.login.wir_haben_dir') }}

  template(v-else)
    p.text-center
      | {{ $t('js.components.profile.login.gib_deine_email') }}
    .input-group.input-group-lg
      input.form-control(
        type="email"
        id="email"
        v-model="form.email"
        autocomplete='email'
        @change="v$.email.$touch()"
        placeholder="Deine E-Mail-Adresse"
      )

    .mt-2(v-show="!valid && v$.email.$dirty")
      small.text-danger
        | {{ $t('js.components.talent_profile.errors.email') }}
    .mt-2(v-show="failure")
      small.text-danger
        | {{ $t('js.components.profile.login.wir_konnten_deine') }}

    button.btn.btn-accent.mt-3.w-100(
      :class="{'disabled': !valid}"
      @click="submitLogin"
    )
      | {{ $t('js.components.profile.login.link_senden') }}

</template>

<script lang="ts" setup>
import { ref, computed, provide } from "vue"
import { talentsLoginPath } from "../routes"
import { required, email } from "../utils/validators"
import useVuelidate from "@vuelidate/core"
import csrfToken from "@/utils/csrfToken"

const form = ref({email: ""})
const success = ref(false)
const failure = ref(false)

const rule = computed(() => ({
  email: { required, email },
}))

const v$ = useVuelidate(rule, form, {})
provide("v$", v$)

const valid = computed(() => {
  return v$.value && v$.value.email && !v$.value.email.$invalid
})

async function submitLogin() {
  const path = talentsLoginPath()
  console.log("path", path)
  const response = await fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      login: {
        email: form.value.email,
      },
    }),
  })
  if (response.ok) {
    success.value = true
  }
  else {
    console.log("response", response)
    failure.value = true
  }
}

</script>

<style scoped>

</style>
