<template lang='pug'>
transition(name='fade')
  .card.bg-primary.neumorphism-on-medium.text-white.text-center.fit-content(v-if='currentLevel' ref='cardEl'): .card-body
    | für {{ currentLevel.level.level}} Mitarbeitende:
    br
    h4.display-5.text-white.mb-0
      |{{ formatPrice(Math.round(totalPrice / 12)) }}
      span.text-muted.ml-1(style='font-size: 0.4em')
        |/Monat
    small.small.text-light {{ $t('js.components.prices_app.netto_zahlbar_als_jahresmi') }} {{formatPrice(totalPrice)}}

    transition
      ul.list-group.text-left.my-3(v-if='setupFee')
        li.list-group-item.small inkl. Wartungspauschale für BMS-Anbindung
        li.list-group-item.small zzgl. Einrichtungsgebühr für BMS-Anbindung (vor.): {{ formatPrice(Math.round(setupFee.netto)) }}

    transition
      ul.list-group.text-left.my-3(v-if='form.hasBms === false')
        li.list-group-item.small
          |Ihre Bewerbungen aus der EB-Talentcloud werden im Empfehlungsbund-BMS bearbeitet. Die Nutzung des EBMS ist in der Mitgliedschaft inklusiv und wird Ihnen automatisch zur Verfügung gestellt. <a href='https://bms.empfehlungsbund.de'>Alle Informationen und Features zum EBMS finden Sie hier</a>.
    div.mt-5
      .btn-group
        button.btn.btn-outline-accent.text-white(:href='"/arbeitgeber/angebot?mitarbeiter=" + form.mitarbeiter' data-bs-toggle='modal' data-bs-target='#offer-modal')
          | {{ $t('js.components.prices_app.angebot_erzeugen') }}
        a.btn.btn-accent(href='/online-meeting')
          | {{ $t('js.components.prices_app.termin_vereinbaren') }}
        a.btn.d-flex.align-items-center.btn-accent(:href='"https://login.empfehlungsbund.de/registrieren?" + utm')
          | {{ $t('js.components.prices_app.registrieren') }}
</template>

<script lang='ts' setup>
import { ref, computed, watch } from 'vue'

const props = defineProps<{
  form: PriceForm,
  prices: crm.PreislistSetting,
  utm: string,
}>()

const formatPrice = (price: number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  }).format(price)
}
const currentLevel = ref<crm.PreislisteElement | null>(null)

const findPrice = (levels: crm.PreislisteElement[]) => {
  const ma = props.form.mitarbeiter
  if (!ma)
    return null
  return levels.find((level) => ma >= level.level.from && ma <= level.level.to) || null
}

const calculatePrice = () => {
  if (!props.form.mitarbeiter || props.form.mitarbeiter < 1) return
  const preisliste = props.form.canChangeRejection == false ? props.prices.empfehlungsbundAngebotstypAnu : props.prices.empfehlungsbundAngebotstyp
  currentLevel.value = findPrice(preisliste.preisliste.levels)
}

const addon = computed(() => {
  if (!currentLevel.value) return null
  if (props.form.hasBms !== true) return null
  let pl: crm.AddonPreisInfo | null = null
  if (props.form.needsBmsApplicants && props.form.needsBmsJobs) {
    pl = props.prices.both
  } else if (props.form.needsBmsApplicants) {
    pl = props.prices.applicants
  } else if (props.form.needsBmsJobs) {
    pl = props.prices.jobs
  }
  return pl
})

const totalPrice = computed(() => {
  if (!currentLevel.value) return 0

  const membershipPrice = currentLevel.value.price.netto
  const add = addon.value
  if (add && add.pricelist) {
    const addonPriceLevel = findPrice(add.pricelist.levels)
    return membershipPrice + (addonPriceLevel?.price.netto || 0)
  }
  return membershipPrice
})

const setupFee = computed(() => {
  const add = addon.value
  if (add && add.setupFeePricelist) {
    const setupFeeLevel = findPrice(add.setupFeePricelist.levels)
    return setupFeeLevel?.price
  }
})

const cardEl = ref<HTMLElement | null>(null)
const scrollIntoView = () => {
  const el = cardEl.value
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}


defineExpose({
  calculatePrice,
  scrollIntoView
})


watch(() => props.form, () => {
  calculatePrice()
}, { deep: true })
</script>

<style scoped>
.list-group a {
  text-decoration: underline;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
