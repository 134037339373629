import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"
import Highcharts from "~/utils/highcharts"
import { medium } from './themes'

export default class extends Controller {
  declare chartValue: string
  declare themeValue: string
  static values = {
    chart: String,
    theme: String,
  }
  isVisible = false
  options = {
    threshold: 0,
  }
  stop: Function | null = null
  connect() {
    const [_, stop] = useIntersection(this, this.options)
    this.stop = stop
  }
  appear() {
    if (this.stop)
      this.stop()
    Highcharts.chart(this.element as HTMLElement, this.chartOptions())
  }

  chartOptions() {
    const options = this.jsonChartOptions()

    let theme = this.themeValue
    theme ||= 'medium'

    switch (theme) {
      case 'medium':
        medium(options)
        break
      default:
        break
    }
    return options
  }

  jsonChartOptions() {
    const json = JSON.parse(this.chartValue || "{}")
    json.series.forEach((serie: any) => {
      serie.data.map((d: any) => {
        if (d.events && d.events.click) {
          d.events.click = new Function("e", d.events.click)
        }
      })
    })
    return json
  }
}
