import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"
import Highcharts from "~/utils/highcharts"

export default class extends Controller {
  static values = {
    type: String,
  }
  stop: () => void = () => { }
  isVisible = false

  connect() {
    const [_observe, stop] = useIntersection(this, { threshold: 0 })
    this.stop = stop
  }

  preprocessData(data: string[], dummy: string = "0") {
    return [
      data[4],
      data[5],
      data[6],
      data[7],
      dummy,
      data[0],
      data[1],
      data[2],
      data[3],
      dummy,
      data[8],
      data[9],
      data[10],
      data[11],
    ]
  }

  appear() {
    this.stop()
    const domElement = this.element as HTMLElement

    fetch("/arbeitgeber/api/kanaleo_public_chart")
      .then((r) => r.json())
      .then((json) => {
        const data = json.data
        let keys: string[] = []
        const series = Object.keys(data).map(function (key) {
          keys = Object.keys(data[key]['count'])
          return {
            type: "column",
            name: key,
            data: Object.values(data[key]['count']),
          }
        })
        keys = this.preprocessData(keys as string[], "" as string)
        series[0].data = this.preprocessData(series[0].data as string[])
        series[1].data = this.preprocessData(series[1].data as string[])

        Highcharts.chart(domElement, {
          chart: {
            polar: true,
            backgroundColor: "#150426",
          },
          title: {
            text: null,
          },
          colors: ["#97C171", "#D4632B"],
          tooltip: {
            formatter() {
              var s = "<b>" + this.series.name + "</b> - " + keys[this.x] + ": " + this.y
              return s
            },
          },
          legend: {
            itemStyle: {
              color: "#fff",
            },
          },
          credits: { enabled: false },
          xAxis: {
            tickInterval: 1,
            min: 0,
            max: Object.keys(series[0].data).length - 1,
            labels: {
              floating: true,
              rotation: -45,
              style: {
                color: "#fff",
              },
              formatter: function () {
                return keys[this.value]
              },
            },
          },
          yAxis: {
            type: "logarithmic",
            tickInterval: 0.4,
            title: { text: "Anzahl Rückmeldungen" },
            labels: {
              floating: true,
              style: {
                color: "#fff",
              },
            },
          },
          series: series,
        })
      })
  }
}
