<template lang='pug'>
div.mt-3
  .text-center.my-3(v-if='showHeader')
    | {{ $t('js.wizard.job_list.passende_stellenangebote') }}

  ul.list-group.rounded-md
    job-list-item.list-group-item(v-for='job in showJobs' :key='job.id' :job='job' :organisation='organisation')
    li.list-group-item(v-if='jobs.length > show')
      button.btn.btn-link(@click='show = 999')
        | {{ $t('js.wizard.job_list.mehr_jobs_length_show_anze', { jobs_length_show: (jobs.length - show) }) }}

</template>

<script lang='ts' setup>
import { ref, computed } from 'vue'
import JobListItem from '@/wizard/JobListItem.vue'

const show = ref(3)

const props = defineProps<{
  jobs: frontend.Job[]
  organisation: frontend.Organisation
  showHeader: {
    type: boolean,
    default: true
  }
}>()
const showJobs = computed(() => {
  return props.jobs.slice(0, show.value)
})
</script>

<style scoped>
.list-group-item {
  border-width: 0px;
}
</style>
