<template>
  <div v-bind='$attrs' :class='error && !disableError ? "is-invalid" : ""' class='form-group'>
    <label v-if="label && showLabel" :for="name" class='form-label'>
      {{ showLabel }}
      <abbr v-if="required" class="required" title="required">*</abbr>
    </label>
    <slot :error='error' :touch='touch'/>
    <div v-if="error && !disableError" class="mb-1 text-sm block text-danger">
      {{ error.$message }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue"
import type { ComputedRef } from "vue"
import { Validation, ErrorObject } from "@vuelidate/core"
import objectDig from '@/utils/objectDig'

import { useI18n } from "vue-i18n"
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    for: string
    ns?: string,
    label?: string | boolean
    v?: any
    disableError?: boolean
  }>(),
  {
    label: true,
    ns: 'js.form.properties',
    disableError: false
  }
)

const globalV = inject('v', null) as ComputedRef<Validation> | null

const attributeValidation = (): Validation | null => {
  const path = props.for
  const v = props.v || globalV?.value
  return v && objectDig(v, path)
}

const name = computed(() => props.for)

const i18nLabel = computed(() => {
  const key = `${props.ns}.${props.for}`
  return t(key)
})

const showLabel = computed(() => {
  if (props.label === true) {
    return i18nLabel.value
  } else if (props.label === false) {
    return null
  } else {
    return props.label
  }
})

const error = computed((): ErrorObject | null => {
  const vv = attributeValidation()
  if (vv && vv.$dirty && vv.$error) return vv.$errors[0]
  else return null
})

const required = computed(() => {
  const vv = attributeValidation()
  return vv && vv.required
})
const touch = () => {
  const vv = attributeValidation()
  if (vv) vv.$touch()
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
  name: "FormGroup",
}
</script>

<style lang="scss" scoped>
$move-left: -15px;

.form-group.is-invalid {
  border-left: 3px solid #dc3545;
  margin-left: $move-left;
  padding-left: $move-left * -1 - 3px;
}
</style>
