<template>
  <div class="empfehlungsbund-card card card-domain neumorphism-on-white">
    <div class="card-body">
      <h3 class="display-5">{{ $t("js.wizard.components.subscribe_card.ihr_wunschstelle_ist_nicht") }}</h3>
      <p>{{ $t("js.wizard.components.subscribe_card.dann_melden_sie_sich_hier") }}</p>

      <SubscribeForm />

      <!-- TODO: profile page freischalten   -->
      <div class="mt-3" v-if="profilePageAvailable">
        <p>{{ $t("js.wizard.components.subscribe_card.legen_sie_sich") }}</p>
        <div class="text-center">
          <a :href="talentsUrl" class="btn btn-accent btn-lg">
            {{ $t("js.wizard.components.subscribe_card.profil_anlegen") }}
          </a>
        </div>
      </div>

      <BewerbungsApp class="mt-5"/>

      <CollapseTransition>
      <a v-if="bestMatchingCommunity && remoteUrl" class="mt-5 btn btn-outline-dark" :href="remoteUrl">
          <div class="d-flex flex-column-on-sm gap-4 align-items-center">
            <img :src="bestMatchingCommunity.logo" style="width: 200px" />
            <h3 class="h5 font-light m-0" v-html="sentence"></h3>
          </div>
          <p class="mt-2">{{ bestMatchingCommunity.description }}</p>
        </a>
      </CollapseTransition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue"
import { form } from "@/wizard/form"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import SubscribeForm from "@/wizard/components/SubscribeForm.vue"
import BewerbungsApp from "@/wizard/components/BewerbungsApp.vue"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

const profilePageAvailable = false

const props = defineProps<{
  bestMatchingCommunity: frontend.BestMatchingCommunity | null
}>()

const tracking = inject("tracking")
const remoteUrl = computed(() => {
  if (!props.bestMatchingCommunity) return null

  return props.bestMatchingCommunity.url + `/jobs/search?q=${encodeURIComponent(form.query)}&${tracking}`
})

const sentence = computed(() => {
  const args = { location: form.location, query: form.query }
  if (form.location && form.location != "") {
    return t("js.wizard.components.subscribe_card.sentence.with_location_query", args)
  } else {
    return t("js.wizard.components.subscribe_card.sentence.with_query", args)
  }
})

const talentsUrl= computed(() => {
  let url = "/talents?"
  for (const [k, v] of Object.entries(form)) {
    if(v) {
      url += `${k}=${encodeURIComponent(v)}&`
    }
  }
  return url
})
</script>

<style scoped>
.btn {
  text-align: initial;
  text-transform: initial;
}
</style>
