<template lang="pug">
div
  h2.profile-card-title.mb-3
    | {{ $t("js.components.profile.conversation.header") }}
  .alert.alert-warning.d-flex.gap-3.justify-content-start.align-items-center.mb-3(v-if="convs.length == 0 && loaded")
    i.fa.fa-info-circle.fa-2x
    small
      | {{ $t('js.components.profile.conversation.intro') }}
      | {{ $t('js.components.profile.conversation.es_kann_eine') }}

  .alert.alert-warning.d-flex.gap-3.justify-content-start.align-items-center.mb-3(v-if="convs.length > 0 && loaded")
    i.fa.fa-info-circle.fa-2x
    small
      | {{ $t('js.components.profile.conversation.hint_1') }}

  #chat-region
  .container-fluid: .row.chat-border
    .col-lg-4.col-md-5.chat-previews
      .mt-5(v-if="!loaded")
        .preview-skeleton(v-for="i in 3" :key="i")
      template(v-else)
        .mt-3(v-if="convs.length > 0")
          .position-sticky.d-none.d-md-block
            h4 {{ $t("js.components.profile.tabs.conversations") }}
          preview-bar(
            :conversations="sortedConvs"
            :activeID="currentConvId"
            @select:conv="selectConv"
          )
        .d-flex.justify-content-center.align-items-center.h-800px(v-else)
          .text-muted.text-center
            p.mb-3
              | {{ $t('js.components.profile.conversation.no_conversations') }}

    .col-lg-8.col-md-7(style="padding: 0; margin: 0;")
      template(v-if="loaded && currentConv")
        .chat-header.p-3.d-flex.align-items-center.justify-content-between
          .d-flex.align-items-center(style="height: 70px;")
            .org--avatar(v-if="currentConv.organisation_data.logo_url")
              img.img-fluid(:src="currentConv.organisation_data.logo_url" alt="organisation logo" style="max-height: 70px; object-fit: cover")
            .flex-grow-1.d-none.d-sm-block
              .display-name
                | {{ currentConv.organisation_data.name }}

          .d-flex.justify-content-end.align-items-end.gap-1.flex-column
            button.btn.btn-outline-primary.btn-sm.w-100.transform-none(
              :class="{'tilt-shaking': currentConv.hire_status == 'sent'}"
              @click="showModal = true"
            )
              | {{ $t("js.components.profile.conversation.details") }}
            button.btn.btn-sm.w-100.transform-none(@click="showConfirmCloseConv = true" :class="closeActionWrapper.buttonClass")
              | {{ $t(closeActionWrapper.button) }}
        Chat(
          :conversation="currentConv"
          :loading="sending"
          @send-message="sendMessage($event)"
          @trigger-profile="triggerProfileDataForOrg"
          @chat-focused="focusOnConv"
        )

      .d-flex.justify-content-center.align-items-center.h-800px(v-else-if="!loaded")
        .spinner-border.text-info(style="width: 3rem; height: 3rem; margin-right: 1rem;" role="status")
        .text-muted.p-3
          h4 {{ $t('js.components.profile.conversation.loading') }}
      .d-flex.justify-content-center.align-items-center.h-800px(v-else)
        .text-muted.p-3
          h4
            | {{ $t('js.components.profile.conversation.no_conversation_chosen') }}
            i.fas.fa-comment-alt(style="margin-left: 0.5rem")
          p.mt-3.mb-0
            | {{ $t('js.components.profile.conversation.choose_conversation') }}

  confirm-modal(
    v-model="showConfirmCloseConv"
    :titleKey="closeActionWrapper.title"
    @confirm="handleCloseOrReopenConversation()"
  )
    template(v-slot:confirm_content)
      .text-center.mb-3
        i.fa.fa-3x(:class="closeActionWrapper.textIcon")
      p
        | {{ $t(closeActionWrapper.text) }}

  Modal.text-dark(
    v-model="showModal"
    id="job-details-modal"
    :title="$t('js.components.profile.job_offer.about_company')"
    :hideFooter="true"
    size="xl"
  )
    .p-3(v-if="currentConv && currentConv.organisation_data")
      .mb-5
        h3.text-center
          | {{ currentConv.organisation_data.name }}
        .mt-3(v-if="currentConv.hire_status == 'sent'")
          p.text-center.mb-2
            | {{ $t('js.components.profile.conversation.hire_confirmations.hint') }}
          .d-flex.justify-content-between.gap-3
            a.btn.btn-outline-dark.w-100(:href="rejectURL" target="_blank")
              | {{ $t('js.components.profile.conversation.hire_confirmations.decline') }}
            button.btn.btn-accent.w-100(@click="confirmHire()")
              | {{ $t('js.components.profile.conversation.hire_confirmations.confirm') }}
          hr
      organisation-details(:organisation='currentConv.organisation_data')

</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import PreviewBar from "@/talents/profile/chat/PreviewBar.vue"
import Chat from "@/talents/profile/chat/Chat.vue"
import csrfToken from "@/utils/csrfToken"
import { Modal } from "@/elements"
import { talentsConversationPath,
  talentsConversationsPath,
  talentsConversationReadPath,
  talentsConversationTriggerPath,
  talentsConversationClosePath,
  talentsConversationConfirmHirePath,
  talentsProfileRejectSurveyPath,
  talentsConversationRequestToReopenPath
} from "@/routes"
import OrganisationDetails from "@/wizard/components/OrganisationDetails.vue"
import ConfirmModal from "./ConfirmModal.vue"

const props = defineProps({
  conversationId: {
    type: Number,
    default: null
  }
})

const currentConvId = ref<number | null>(null)
const convs = ref<FrontendConversation[]>([])
const loaded = ref<boolean>(false)
const showModal = ref<boolean>(false)
const showConfirmCloseConv = ref<boolean>(false)
const sending = ref<boolean>(false)

const currentConv = computed(() => {
  return convs.value.find(conv => conv.id === currentConvId.value)
})

const sortedConvs = computed(() => {
  return convs.value.sort((a, b) => {
    if (a.last_updated_at === null) return -1
    if (b.last_updated_at === null) return 1

    return new Date(b.last_updated_at).getTime() - new Date(a.last_updated_at).getTime()
  })
})

import consumer from '@/utils/consumer'

async function fetchConversations() {
  fetch(talentsConversationsPath())
    .then(response => response.json())
    .then(data => {
      convs.value = data
      loaded.value = true
    }).then(() => {
      if (props.conversationId) {
        const ids = convs.value.map(conv => conv.id)
        if (ids.includes(props.conversationId)) {
          selectConv(props.conversationId)
        }
        const chatRegion = document.getElementById("chat-region")
        if (chatRegion) {
          chatRegion.scrollIntoView({ behavior: "smooth" })
        }
      }
    })
}

function confirmHire() {
  if (!currentConv.value || currentConv.value.hire_status !== "sent") return
  fetch(talentsConversationConfirmHirePath({ conversation: { id: currentConv.value.id }}), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || ""
    }
  })
    .then(response => response.json())
    .then(data => {
      const index = convs.value.findIndex(conv => conv.id === currentConvId.value)
      convs.value[index] = data
      showModal.value = false
    })
}

const rejectURL = computed(() => {
  if (!currentConv.value) return talentsProfileRejectSurveyPath()

  return talentsProfileRejectSurveyPath({ conversation_id: currentConv.value.id })
})

onMounted(() => {
  fetchConversations()
  consumer.subscriptions.create({
    channel: "Talents::ProfileChannel",
  }, {
    received(data: FrontendConversation) {
      const index = convs.value.findIndex(conv => conv.id === data.id)
      if (index === -1) {
        convs.value.unshift(data)
        return
      }
      convs.value[index] = data
    }
  })
})

function triggerProfileDataForOrg() {
  if (!currentConv.value) return

  const params = { id: currentConv.value.id }
  sending.value = true
  fetch(talentsConversationTriggerPath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || ""
    },
    body: JSON.stringify({ conversation: params })
  })
    .then((response)=>response.json())
    .then((data) => {
      const index = convs.value.findIndex(conv => conv.id === currentConvId.value)
      convs.value[index] = data
      sending.value = false
    })
}

const closeActionWrapper = computed(() => {
  if (currentConv.value && currentConv.value.status === "closed") {
    return {
      textIcon: "fa-info-circle",
      title: "js.components.profile.conversation.confirm_modal.title_reopen",
      text: "js.components.profile.conversation.confirm_modal.text_reopen",
      button: "js.components.profile.conversation.reopen_conversation",
      buttonClass: "btn-outline-accent"
    }
  }
  return {
    textIcon: "fa-exclamation-triangle text-danger",
    title: "js.components.profile.conversation.confirm_modal.title_close",
    text: "js.components.profile.conversation.confirm_modal.text_close",
    button: "js.components.profile.conversation.end_conversation",
    buttonClass: "btn-outline-danger"
  }
})

function handleCloseOrReopenConversation() {
  if (!currentConv.value) return

  let path = talentsConversationClosePath()
  if (currentConv.value.status === "closed") {
    path = talentsConversationRequestToReopenPath()
  }
  sending.value = true
  const params = { id: currentConv.value.id }
  fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || ""
    },
    body: JSON.stringify({ conversation: params })
  })
    .then((response) => response.json())
    .then((data)=> {
      const index = convs.value.findIndex(conv => conv.id === currentConvId.value)
      convs.value[index] = data
      sending.value = false
    })
}

function selectConv(convId: number) {
  currentConvId.value = convId
  focusOnConv()
}

function focusOnConv() {
  if(currentConv.value && !currentConv.value.is_read) {
    fetch(talentsConversationReadPath(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.value || ""
      },
      body: JSON.stringify({ conversation: { id: currentConv.value.id } })
    })
      .then(() => {
        const index = convs.value.findIndex(conv => conv.id === currentConvId.value)
        convs.value[index].is_read = true
      })
  }
}

const emits = defineEmits(["error:makeToast"])

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

function sendMessage(payload: { message: string, file?: ActiveStorage.Blob }) {
  if (currentConvId.value === null || currentConv.value?.status == 'closed') return

  const params = {
    id: currentConvId.value,
    file: payload.file,
    content: payload.message
  }
  sending.value = true
  fetch(talentsConversationPath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || ""
    },
    body: JSON.stringify({ conversation: params })
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        emits('error:makeToast', {
          message: t("js.components.profile.conversation.message_error"),
          type: "error"
        })
        console.error(data)
      }
      sending.value = false
    })
}
</script>

<style>
.circle--avatar {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #103640;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.org--avatar {
  max-width: 150px;
  margin-right: 1rem;
}
.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.chat-previews {
  height: 800px;
  overflow-y: scroll;
  border-right: 1px solid #e5e5e5;
  padding-left: 0.15rem;
  padding-right: 0.75rem;
  margin: 0;
}
.search-bar {
  position: sticky;
  padding-top: 5px;
  top: 0;
  z-index: 1;
  background-color: white;
}
.chat-preview {
  cursor: pointer;
}
.chat-preview:hover {
  background-color: #e5e5e5;
}
.preview--active {
  background-color: #e5e5e5;
}
.chat-border {
  border: 2px solid #e5e5e5;
  border-radius: 5px;
  padding: 0 0 0 1rem;
}
.h-800px {
  height: 800px;
}
@media (max-width: 768px) {
  .chat-border {
    padding: 0;
  }
  .chat-previews {
    border-right: none;
    padding: 0 0.5rem 0 0.5rem;
    height: auto;
    max-height: 300px;
  }
  .chat-preview {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 0.5rem;
  }
  .chat-preview:last-child {
    border-bottom: none;
  }
  .circle--avatar {
    width: 50px;
    height: 50px;
  }
  .avatar {
    width: 20px;
    height: 20px;
  }
  .h-800px {
    height: auto !important;
  }
}
.chat-header {
  border-radius: 0 5px 0 0;
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 0 5px #e5e5e5;
}
.preview-skeleton {
  height: 70px;
  border-radius: 5px;
  width: 100%;
  background-color: #e5e5e5;
  margin-bottom: 1rem;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
.position-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 0.5rem 0;
}
.transform-none {
  text-transform: none !important;
}
</style>
