<template>
  <teleport to="body">
    <div
      class="w-100 h-100 position-fixed top-0 d-flex flex-column align-items-center justify-content-center bg-light bg-opacity-50"
      :class="dragInProgress ? 'd-block' : 'invisible hidden d-none'" @drop.prevent.stop='onDrop'>
      <i>
        <svg class="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
        </svg>
      </i>
      <p class="text-lg text-primary">Drop files to upload</p>
    </div>
  </teleport>

  <div v-bind='$attrs'>
    <div class="border-dashed">
      <div class="d-flex flex-column align-items-center">
        <svg class="mx-auto" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true" style='width: 80px;'>
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <div class="d-flex text-sm align-items-center mx-auto">
          <label
            for="file-upload"
            class="btn btn-outline-dark">
            <span> {{ $t("js.elements.upload_field.upload_a_file") }} </span>
            <input id="file-upload" name="file-upload" type="file" class="sr-only" :multiple="multiple" :accept="accept" @input="uploadFileEvent" />
          </label>
          <span class="ps-1">{{ $t("js.elements.upload_field.or_drag_and_drop_here") }} </span>
        </div>
        <p class="text-sm text-gray-500">PDF, DOC, DOCX &lt; 10MB</p>
      </div>
    </div>
    <div class="" v-if="uploads.length > 0">
      <div class="" v-for="file in uploads">
        <slot name="progress" :file="file" :progress="progress">
          <i>{{ $t("js.elements.upload_field.is_uploading") }} {{ file.file.name }}</i>
          <br />
          <progress :value="progress" :max="100" :animated="true" style="max-width: 200px; height: 2px" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue"
import { DirectUpload } from "@rails/activestorage"

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false,
  },
  maxSize: { type: Number, required: false, default: null },
  accept: { type: String, required: false, default: () => "application/pdf" },
  uploadUrl: {
    type: String,
    default: () => "/rails/active_storage/direct_uploads",
  },
  placeholder: { type: String, default: () => "Dokument hochladen" },
  dropPlaceholder: {
    type: String,
    default: () => "Dokument hier fallenlassen um hochzuladen",
  },
  disabled: { type: Boolean, required: false, default: false },
})
const emit = defineEmits(["error", "uploaded"])

const dragInProgress = ref(false)
const progress = ref(0)
const uploads = ref<{ file: File; upload: any }[]>([])

const uploadInProgress = () => {
  return uploads.value.length > 0
}
const onProgress = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
  progress.value = (event.loaded / event.total) * 100
}
const directUploadWillStoreFileWithXHR = (xhr: XMLHttpRequest) => {
  xhr.upload.addEventListener("progress", (event) => onProgress(event))
}
const uploadFile = (file: File) => {
  if (props.maxSize && file.size > props.maxSize * 1024 * 1024) {
    emit("error", `Die Datei darf maximal ${props.maxSize} MB groß sein`)
    return
  }
  const upload = new DirectUpload(file, props.uploadUrl, { directUploadWillStoreFileWithXHR })
  uploads.value = [...uploads.value, { file, upload }]
  progress.value = 0
  upload.create((error, blob) => {
    uploads.value = uploads.value.filter((payload) => payload.file.name !== file.name)
    if (error) {
      emit("error", error)
    } else {
      emit("uploaded", { file, blob })
    }
  })
}
const uploadFileEvent = (event: Event) => {
  const files = (event.target as HTMLInputElement).files
  if (!files) {
    return
  }
  for (let i = 0; i < files.length; i++) {
    uploadFile(files[i])
  }
}

const onDrop = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  dragInProgress.value = false
  const files = e.dataTransfer?.files
  if (files) {
    Array.from(files).forEach((file) => uploadFile(file))
  }
}

let lastElement: EventTarget| null = null

const dragEnter = (e: DragEvent) => {
  lastElement = e.target
  e.preventDefault()
  e.stopPropagation()
  dragInProgress.value = true
}
const dragLeave = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  if(e.target === lastElement || e.target === document)
    dragInProgress.value = false
}

onMounted(() => {
  window.addEventListener("dragenter", dragEnter)
  window.addEventListener("dragover", dragEnter)
  window.addEventListener("dragleave", dragLeave)
  window.addEventListener("drop", onDrop)
})
onUnmounted(() => {
  window.removeEventListener("dragenter", dragEnter)
  window.removeEventListener("dragover", dragEnter)
  window.removeEventListener("dragleave", dragLeave)
  window.removeEventListener("drop", onDrop)
})
</script>

<style scoped>
.border-dashed {
  border: 3px dashed #ccc;
}
</style>
