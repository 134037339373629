<template>
  <div class="rounded-md p-4" :class="v.bg">
    <div class="d-flex">
      <div class="flex-shrink-0">
        <Icon class="h-5 w-5 text-red-400" :class="v.iconColor" aria-hidden="true" :path="v.icon"/>
      </div>
      <div class="ms-2 w-100 overflow-hidden">
        <h3 class="text-sm font-medium mb-2" :class="v.titleColor" v-if="title">
          {{ title }}
        </h3>
        <div class="text-sm" :class="v.bodyColor">
          <slot></slot>
        </div>
      </div>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>

<script setup lang="ts">
import Icon from "./Icon"
import { computed } from 'vue'

import { mdiAlertCircle, mdiCheckCircleOutline, mdiInformationOutline } from '@mdi/js';
const variants = {
  danger: {
    bg: 'alert-danger',
    titleColor: 'text-red-800 dark:text-red-400',
    iconColor: 'text-red-500 dark:text-red-400',
    bodyColor: 'text-red-700 dark:text-red-500',
    icon: mdiAlertCircle,
  },
  success: {
    bg: 'alert-success',
    titleColor: 'text-green-800 dark:text-green-500',
    iconColor: 'text-green-500 dark:text-green-500',
    bodyColor: 'text-green-700 dark:text-green-600',
    icon: mdiCheckCircleOutline
  },
  info: {
    bg: 'alert-info',
    titleColor: 'text-blue-800 dark:text-blue-400',
    iconColor: 'text-blue-400 dark:text-blue-500',
    bodyColor: 'text-blue-700 dark:text-blue-500',
    icon: mdiInformationOutline,
  },
}
const props = defineProps<{
  variant: keyof typeof variants,
  title?: string,
}>()

const v = computed(() => {
  return variants[props.variant] || variants.success
})
</script>

<script lang='ts'>
export default { name: "Alert" }
</script>
