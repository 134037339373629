import { Controller } from "stimulus"
import ahoy from "~/utils/ahoy"

export default class extends Controller {
  declare payloadValue: string
  static values = { payload: String }

  connect() {
    const payload = JSON.parse(this.payloadValue)
    ahoy.track(payload[0], payload[1])
  }
}
