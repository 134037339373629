<template>
  <ChecklistItem :done="false" :openIcon="mdiMagnify">
    <a :href="wizardPath">
      <p> {{ $t("js.components.apply.apply_succeed.company_finder.title", { count: result.organisation_count, jobCount: result.job_count }) }}</p>
      <CollapseTransition>
        <div class="linear-gradient" v-if="result.organisations[0]">
          <OrganisationCard :payload="result.organisations[0]" />
        </div>
      </CollapseTransition>
      <CollapseTransition>
        <div class="linear-gradient" v-if="result.organisations[1]">
          <OrganisationCard :payload="result.organisations[1]" />
        </div>
      </CollapseTransition>
      <CollapseTransition>
        <div class="linear-gradient" v-if="result.organisations[2]">
          <OrganisationCard :payload="result.organisations[2]" />
        </div>
      </CollapseTransition>
    </a>
  </ChecklistItem>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from "vue"
import ChecklistItem from "@/elements/ChecklistItem.vue"
import OrganisationCard from "@/wizard/OrganisationCard.vue"
import { wizardCompaniesPath } from "@/routes"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import { mdiMagnify } from "@mdi/js"

const props = defineProps<{
  application: StoredApplicationV2
  job: JobPayload | (frontend.Job & { company_name: string })
}>()

const result = ref<frontend.CompanyFinderResult>({ job_count: 0, organisation_count: 0, organisations: [] })

const form = {
  query: props.job.tag_list.slice(0, 5).join(" "),
  location: props.job.location,
  sub_job_type: props.job.sub_job_type,
  full_remote: props.job.remote_type == "full_remote",
  portal_type: props.job.portal_type,
  ignore_organisations: [props.job.organisation_id],
  radius: 40,
}

onMounted(() => {
  fetch(wizardCompaniesPath(form))
    .then((r) => r.json())
    .then((r) => {
      result.value = r
    })
})

const wizardPath = computed(() => {
  return wizardCompaniesPath(form)
})
</script>

<style scoped>
.linear-gradient {
  max-height: 200px;
  overflow: hidden;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0em, rgba(0, 0, 0, 1) 3.75em);
  pointer-events: none;
}
.linear-gradient::v-deep .neumorphism-on-white {
  box-shadow: none;
}
</style>
