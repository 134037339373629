<template lang="pug">
div
  .d-flex.flex-wrap.gap-3.justify-content-center(v-if="!minimize")
    button.btn.shadow.px-3.portal-type(
      v-for="(icon, type) in icons"
      :key="type"
      :id="type"
      :class="type === proxyPortalType ? 'btn-primary' : 'btn-light'"
      @click.prevent="selectType(type)"
      style="font-size: 1.2rem;"
    )
      .text-center
        img(:src='icon.svg' v-if='icon.svg' :alt='pt' style='width: 50px; height: 50px')
        svg(v-else :viewBox='icon.viewbox' style="width: 50px; height: 50px; fill: currentColor")
          path(:d="icon.path" fill='inherit')
        p.m-0
          | {{ type.toUpperCase() }}
        small.text-muted
          | {{ icon["title_" + locale]}}

  template(v-else)
    .d-flex.flex-wrap.gap-1.justify-content-start.mb-2
      button.btn.shadow.px-3(
        v-for="type in portalTypeOptions"
        :key="type"
        :class="type === proxyPortalType ? 'btn-primary' : 'btn-light'"
        @click.prevent="selectType(type)"
      )
        .text-center
          p.m-0
            | {{ type.toUpperCase() }}
    small.text-muted
      | {{ icons[proxyPortalType]["title_" + locale]}}

  .alert.alert-danger.my-alert.m-0.p-2.mt-3(v-if="showError && !proxyPortalType")
    | {{ $t('js.components.talent_profile.competences.no_portaltype') }}

  component.font-light.mt-5(:is="minimize ? 'b' : 'h4'")
    | {{ $t("js.components.talent_profile.competences.label2") }}
  .input-group.input-group-lg
    input.form-control(
      type="text"
      name="search_or_add_competence"
      v-model="searchStr"
      :placeholder="$t('js.components.talent_profile.competences.competence_search')"
      :class="showError ? 'is-invalid' : ''"
      @keyup.enter="handleAdd()"
    )
    button.btn.btn-dark(
      @click="handleAdd()"
      id="add_custom_competence"
      :disabled="!proxyPortalType"
    )
      | {{ $t('js.components.talent_profile.pages.add') }}

  .mt-3.alert.alert-warning(v-if="showWarning")
    | {{ $t('js.components.talent_profile.competences.only_5_competences') }}

  .mt-3(v-if="customCompetences.length > 0")
    small
      | {{ $t('js.components.talent_profile.competences.custom_competences') }}
    .mt-1.mb-3.d-flex.justify-content-start.flex-wrap.gap-2(ref="parent_2")
      button.btn.btn-accent.d-flex.align-items-center(v-for="c in customCompetences" :key="c.custom_competence" style="background-color: #bf436f;" :class="minimize ? 'btn-sm' : ''")
        button.btn-close.btn-close-white.btn-sm(aria-label="remove" @click="handleRemove(c.custom_competence)" style="margin-right: 10px;")
        span.text-white
          | {{ c.custom_competence }}

  .mt-3: small {{ $tc('js.components.talent_profile.competences.chosen_competences', selectedCompetences.length) }}

  .fs-5.mt-3.text-warning(v-if="sortedCompetences.length === 0")
    | {{ $t('js.components.talent_profile.competences.no_competences') }}

  .d-flex.justify-content-start.align-items-start.flex-wrap.gap-1.mt-1(ref="parent" style="min-height: 3em" v-else)
    button.btn.btn-primary(
      v-for="competence in sortedCompetences"
      :key="competence.id"
      @click="handleClick(competence)"
      :class="{ 'btn-accent': active(competence.id), 'btn-sm' : minimize }")
        | {{ competence[`name_${locale}`] }}

  .mt-3: small {{ $t('js.components.talent_profile.competences.hint') }}

  .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="showError")
    | {{ $t('js.components.talent_profile.errors.competences') }}
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { useI18n } from "vue-i18n"
import { talentsFindCompetencesPath } from "@/routes"
import { sortBy } from 'lodash'
import icons from "@/wizard/portalTypeIcons"

const { locale } = useI18n()

type localtype = 'de' | 'en'

const competences = ref<Competence[]>([])
const searchStr = ref("")
const showWarning = ref<boolean>(false)

const portalTypeOptions = ['it', 'office', 'mint', 'sano', 'bau', 'lager', 'hoga'] as portaltype[]

const props = withDefaults(defineProps<{
  modelValue: CompetenceInput[],
  portalType: portaltype
  showError: boolean
  minimize: boolean
}>(), {
  showError: false,
  minimize: false
})

const emits = defineEmits(["update:modelValue", "update:portalType"])

const selectedCompetences = computed({
  get() {
    return props.modelValue
  },
  set(value: CompetenceInput[]) {
    emits("update:modelValue", value)
  }
})

const proxyPortalType = computed({
  get() {
    return props.portalType
  },
  set(value: portaltype) {
    emits("update:portalType", value)
  }
})

const competencesByPortalType = computed(() => {
  return competences.value.filter((c: Competence) => {
    const types = c.portal_types
    return types.includes(proxyPortalType.value as portaltype)
  })
})

const local = computed<localtype>(() => {
  return locale.value === 'de' ? 'de' : 'en'
})

const competencesBySearchString = computed(() => {
  const term = searchStr.value.toLowerCase().trim()
  return competencesByPortalType.value.filter((competence: Competence) => {
    const matching_cond = competence[`name_${local.value}`].toLowerCase().includes(term)
    const keywords_cond = competence[`keywords_${local.value}`].some((keyword: string) => keyword.toLowerCase().includes(term))
    return matching_cond || keywords_cond
  })
})

const sortedCompetences = computed(() => {
  return sortBy(competencesBySearchString.value, function(c: Competence) {
    return [active(parseInt(`${c.id}`)) ? 0 : 1, c[`name_${local.value}`]]
  })
})

const active = ((id: number | string) => {
  return selectedCompetences.value.map((el: any) => el.competence_id).includes(id)
})

function handleClick(competence: Competence) {
  const id = parseInt(`${competence.id}`)
  if(active(id)) {
    selectedCompetences.value = selectedCompetences.value.filter((el: CompetenceInput) => el.competence_id != id)
    showWarning.value = false
  }
  else if(selectedCompetences.value.length < 5) {
    selectedCompetences.value.push({
      competence_id: id,
      name_de: competence.name_de,
      name_en: competence.name_en,
      // @ts-ignore
      experience_level: null,
      is_important: false,
      custom_competence: null
    })
    searchStr.value = ""
  }
  else {
    showWarning.value = true
  }
}

const customCompetences = computed(() => {
  return selectedCompetences.value.filter((el: CompetenceInput) => el.custom_competence != null)
})

function handleAdd() {
  const competence = searchStr.value.trim()
  if(competence === "" || !proxyPortalType.value) return

  const idx = selectedCompetences.value.findIndex((el: CompetenceInput) => el.custom_competence === competence)
  if(idx === -1 && selectedCompetences.value.length < 5) {
    selectedCompetences.value.push({
      competence_id: null,
      name_de: competence,
      name_en: competence,
      // @ts-ignore
      experience_level: null,
      is_important: false,
      custom_competence: competence
    })
    searchStr.value = ""
  }
  else {
    showWarning.value = true
  }
}

function handleRemove(competence: string) {
  selectedCompetences.value = selectedCompetences.value.filter((el: CompetenceInput) => el.custom_competence != competence)
  showWarning.value = false
}

function selectType(type: portaltype) {
  if(proxyPortalType.value === type) return

  proxyPortalType.value = type
  selectedCompetences.value = []
  searchStr.value = ""
}

onMounted(() => {
  const path = talentsFindCompetencesPath()
  fetch(path)
    .then((r: any) => r.json())
    .then((r: any) => {
      competences.value = r
    })
})

const [parent] = useAutoAnimate()
const [parent_2] = useAutoAnimate()
</script>

<style lang="scss" scoped>
.badge:hover {
  background-color: #01080a;
  border-color: #01080a;
  color: #d9d9d9;
  cursor: pointer;
}
.btn {
  text-transform: none;
}
.portal-type {
  width: 250px;
  text-transform: none;
  line-height: 1.2;
}
</style>
