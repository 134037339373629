<template lang="pug">
div
  .mb-2
    RadioGroup(
      v-model="mainType"
      name="sub_job_type_group_1"
      :options="options['group_1']"
      style="font-size: 1.2rem"
    )

  CollapseTransition
    .mt-2.mb-2(v-if="mainType === 'lernende'")
      small.fst-italic
        | {{ $t('js.components.talent_profile.sub_job_types.sub_group') }}
      CheckboxGroup(
        v-model="subType"
        name="sub_job_type_group_2"
        :options="options['group_2']"
        :inline="true"
        style="font-size: 1.1rem"
      )

</template>

<script lang="ts" setup>
import { computed, ref, watch, onMounted } from 'vue'
import { getOptionValues } from "../talents_form"
import { RadioGroup, CheckboxGroup } from "@/elements"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"

const props = defineProps<{
  modelValue: string[]
}>()

const mainType = ref<string>('')
const subType = ref<string[]>([])

const options = {
  group_1: getOptionValues('sub_job_types_group_1'),
  group_2: getOptionValues('sub_job_types_group_2')
}

const emits = defineEmits(['update:modelValue'])

watch([mainType, subType], () => {
  if (mainType.value === 'lernende') {
    proxyValue.value = subType.value
  }
  else {
    proxyValue.value = [mainType.value]
  }
})

const proxyValue = computed({
  get: () => props.modelValue,
  set: (val) => emits('update:modelValue', val)
})

onMounted(() => {
  if (proxyValue.value.some(v => options['group_2'].map(v => v.value).includes(v))) {
    mainType.value = 'lernende'
    subType.value = proxyValue.value.filter(v => options['group_2'].map(v => v.value).includes(v))
  }
  else if (proxyValue.value.length > 0) {
    mainType.value = proxyValue.value[0]
  }
})

</script>
