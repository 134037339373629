<template lang="pug">
div(data-turbo='false')
  .row.mb-5
    .col-12.col-lg-6
      PriceCard(:name="$t('js.components.prices_app.jahresmitgliedschaft')"
        :href="'https://login.empfehlungsbund.de/arbeitgeber?' + utm"
        :price="minPrice / 12"
        :unit="$t('js.components.prices_app.month')"
        :starting="true")
        template(v-slot:buttons)
          div(class="d-flex justify-content-center gap-2 text-center")
            Button(variant="accent" @click='togglePriceMode')
              | {{ $t('js.components.prices_app.preis_berechnen') }}
            Button(variant="primary" :href="'https://www.empfehlungsbund.de/kontakt'")
              | {{ $t('js.components.prices_app.kontakt') }}

        template(v-slot:feature(1))
          | {{ $t('js.components.prices_app.die_mitgliedschaft_enthalt') }}
        template(v-slot:feature(2))
          span(v-html="$t('js.components.prices_app.automatischer_import_amp_n')")
        template(v-slot:feature(3))
          | {{ $t('js.components.prices_app.volle_teilnahme_am') }}
          |
          span(v-tooltip class="underline decoration-dashed underline-offset-2" :title='$t("js.components.prices_app.fachkrafteempfehlungsnetzw_tootip")')
            | {{ $t('js.components.prices_app.fachkrafteempfehlungsnetzw') }}
        template(v-slot:feature(4))
          | {{ $t('js.components.prices_app.zugang_uber_das_erweiterte') }}
        template(v-slot:feature(5))
          | {{ $t('js.components.prices_app.optionale_ma_nahmen_und_be') }}
        template(v-slot:feature(6))
          | {{ $t('js.components.prices_app.optionale_nutzung_aller_se') }}
          |
          span(
            class="underline decoration-dashed underline-offset-2"
            v-tooltip
            :title="$t('js.components.prices_app.ebms_tooltip')")
            |EBMS
          |, {{ $t('js.components.prices_app.schaltung_arbeitsagentur_b') }}

    .col-12.col-lg-6(v-if='false')
      PriceCard(:name="$t('js.components.prices_app.anzeigenkampagne')" :price="singlePrice" :href="'https://login.empfehlungsbund.de/stelle-schalten?' + utm" :unit="$t('js.components.prices_app.anzeige')")
        template(v-slot:feature(1))
          | {{ $t('js.components.prices_app.anbindung_am') }}
          |
          span(
            class="underline decoration-dashed underline-offset-2"
            v-tooltip
            :title='$t("js.components.prices_app.fachkrafteempfehlungsnetzw2_tooltip")')
            | {{ $t('js.components.prices_app.fachkrafteempfehlungsnetzw2') }}
          |
          | {{ $t('js.components.prices_app.des_empfehlungsbundes') }}
        template(v-slot:feature(2))
          | {{ $t('js.components.prices_app.anzeigenkampagne_fur_30_ta') }}
        template(v-slot:feature(3))
          | {{ $t('js.components.prices_app.individuelle_beratung_zur') }}
        template(v-slot:feature(4))
          | {{ $t('js.components.prices_app.optionale_schaltung_in_mar') }}
        template(v-slot:feature(5))
          | {{ $t('js.components.prices_app.automatischer_import_in_di') }}
        template(v-slot:feature(6))
          | {{ $t('js.components.prices_app.automatische_laufzeitverla') }}


  transition(name='fade')
    div(ref='calculatorRef' v-if='priceMode')
      price-calculator(:prices="prices" :utm='utm')

</template>

<script lang="ts" setup>
import PriceCalculator from './prices/PriceCalculator.vue'
import PriceCard from './prices/PriceCard.vue'
import { Button } from '@/elements'
import { ref } from 'vue'

const props = defineProps<{ prices: PriceListInput, utm: string, singlePrice: number }>()

const priceMode = ref(false)
const calculatorRef = ref<HTMLElement | null>(null)

const togglePriceMode = () => {
  priceMode.value = !priceMode.value
  if (priceMode.value) {
    setTimeout(() => {
      console.log(calculatorRef.value)
      calculatorRef.value?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }
}

const minPrice = props.prices.empfehlungsbundAngebotstyp.preisliste.levels[0].price.netto

</script>

<style scoped>
.underline {
  text-decoration: underline;
}
.underline-offset-2 {
  text-underline-offset: 2px;
}
.decoration-dashed {
  text-decoration-style: dashed;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

</style>
