import { email, required } from "@/utils/validators"
import useVuelidate from "@vuelidate/core"
import type { ValidationRuleWithoutParams } from "@vuelidate/core"
import { Ref, computed } from "vue"

import { useStorage, debounceFilter } from '@vueuse/core'

const form = useStorage<ApplicationForm>("apply-cache", {
  gender: undefined,
  first_name: "",
  last_name: "",
  email: "",
  telephone: "",
  street: "",
  postcode: "",
  city: "",
  country_code: "",
  livingInGermany: null,
  empfehlungscodes: [] as ValidationSucceed[],
  question_answers: {} as { [key: string]: string | string[] },
  comment: "",
  session_identifier: "",
  files: [] as ActiveStorage.Blob[],
}, localStorage, { eventFilter: debounceFilter(1000)  } )

if (form.value.country_code) {
  form.value.livingInGermany = form.value.country_code === "DE"
}

const resetForm = () => {
  form.value.gender = undefined
  form.value.first_name = ""
  form.value.last_name = ""
  form.value.email = ""
  form.value.telephone = ""
  form.value.street = ""
  form.value.postcode = ""
  form.value.city = ""
  form.value.country_code = "DE"
  form.value.empfehlungscodes = []
  form.value.question_answers = {}
  form.value.comment = ""
  form.value.session_identifier = ""
  form.value.files = []
}

export default (applicationInfo: Ref<ApplicationInfo | null>) => {
  const rules = computed(() => {
    const info = applicationInfo.value
    if (!info) return {}
    const base = {
      gender: info.required_fields.includes("gender") ? { required } : {},
      first_name: info.required_fields.includes("first_name") ? { required } : {},
      last_name: info.required_fields.includes("last_name") ? { required } : {},
      email: info.required_fields.includes("email") ? { required, email } : {},
      city: info.required_fields.includes("postal_address") ? { required } : {},
      street: info.required_fields.includes("postal_address") ? { required } : {},
      postcode: info.required_fields.includes("postal_address") ? { required } : {},
      files: info.required_fields.includes("files") ? { fileRequired: required } : {},
      livingInGermany: info.required_fields.includes("postal_address") ? { required } : {},
      country_code: info.required_fields.includes("postal_address") ? { required } : {},
      question_answers: {} as Record<string, Record<string, ValidationRuleWithoutParams>>,
      telephone: info.required_fields.includes("telephone") ? { required } : {},
    }
    if (info && info.questions.length > 0) {
      info.questions.forEach((question) => {
        base["question_answers"][question.question_id.toString()] = { required }
      })
    }
    return base
  })
  const v$ = useVuelidate(rules, form, {})

  const submitPayload = computed(() => {
    const value: Partial<ApplicationForm> = { ...form.value }
    const visibleFields = applicationInfo.value?.visible_fields
    if (visibleFields) {
      if (!visibleFields.includes("gender")) {
        delete value.gender
      }
      if (!visibleFields.includes("first_name")) {
        delete value.first_name
      }
      if (!visibleFields.includes("last_name")) {
        delete value.last_name
      }
      if (!visibleFields.includes("email")) {
        delete value.email
      }
      if (!visibleFields.includes("telephone")) {
        delete value.telephone
      }
      if (!visibleFields.includes("comment")) {
        delete value.comment
      }
      if (!visibleFields.includes("files")) {
        value.files = []
      }
      if (!visibleFields.includes("postal_address")) {
        delete value.street
        delete value.postcode
        delete value.city
        delete value.country_code
      }
    }
    delete value.session_identifier

    return {
      ...value,
      empfehlungscodes: form.value.empfehlungscodes.map((e) => e.code),
    }
  })
  return { rules, form, v$, resetForm, submitPayload }
}
export {
  form
}
