<template lang="pug">
.sidebar-display
  .chat-preview.p-2.rounded(
    v-for="conv in conversations"
    :class="{ 'preview--active': activeID === conv.id }"
  )
    ConvPreview(
      :conversation="conv"
      :active="activeID === conv.id"
      @select:conv="emits('select:conv', $event)"
    )

.modal-display
  .mb-3
    h4 Aktive Konversation
  .mb-3.preview--active.p-2.rounded(v-if="activeID")
    ConvPreview(
      :conversation="conversations.find(conv => conv.id === activeID)"
      :active="true"
      @select:conv="showModal = true"
    )
  .mb-3(v-else)
    button.btn.btn-outline-primary.w-100(@click="showModal = true")
      | Alle Konversationen
      i.fas.fa-address-book(style="margin-left: 0.5rem")

  Modal(
    v-model="showModal"
    :title="$t('js.components.profile.tabs.conversations')"
    size="lg"
  )
    .chat-preview(
      v-for="conv in conversations"
      :class="{ 'preview--active': activeID === conv.id}"
    )
      ConvPreview(
        :conversation="conv"
        :active="activeID === conv.id"
        @select:conv="selectConvModal($event)"
      )


</template>

<script setup lang="ts">
import { computed, ref, defineAsyncComponent } from "vue"
import { Modal } from "@/elements"
import ConvPreview from "@/talents/profile/chat/ConvPreview.vue"

const props = defineProps<{
  conversations: FrontendConversation[]
  activeID: number | null
}>()

const emits = defineEmits([ "select:conv" ])

const showModal = ref(false)

function selectConvModal(id: number) {
  emits("select:conv", id)
  showModal.value = false
}

</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .modal-display {
    display: none;
  }
  .sidebar-display {
    display: block;
  }
}

@media (max-width: 768px) {
  .sidebar-display {
    display: none;
  }
  .modal-display {
    display: block;
  }
}
</style>

