<template lang="pug">
overview-card(
  :title="$t('js.components.profile.tables.deine_wunschstelle')"
  icon="fa-briefcase"
  :hint="hint"
  :progress="progressValue"
)
  template(v-slot:toggle-actions)
    button.btn.btn-outline-primary.btn-sm.no-transform.d-flex.align-items-center(@click="proxyEditing = true" :disabled="proxyEditing")
      i.fas.fa-user-edit(style="margin-right: 0.5rem")
      span(v-if="!proxyEditing")
        | {{ $t("js.components.talent_profile.generic.bearbeiten") }}
      span(v-else)
        | {{ $t("js.components.talent_profile.generic.in_bearbeitung") }}
  template(v-slot:actions)
    collapse-transition
      .mt-3(v-if="proxyEditing")
        edit-job-desire(
          v-model="proxyEditing"
          @submit:makeToast="emits('submit:makeToast', $event)"
        )

</template>

<script setup lang="ts">
import { computed } from "vue"
import OverviewCard from "@/talents/profile/components/OverviewCard.vue"
import EditJobDesire from "@/talents/profile/components/edit/EditJobDesire.vue"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import { useI18n } from "vue-i18n"
import { jobDesire } from "@/talents/profile/profile"

const { t } = useI18n()

const props = defineProps({
  editing: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update:editing', 'submit:makeToast'])

const proxyEditing = computed({
  get: () => props.editing,
  set: (val) => emits('update:editing', val)
})

const progressValue = computed(() => {
  if (!jobDesire.value.progress) {
    return 0
  }
  return jobDesire.value.progress.progress
});

const fieldStr = computed(() => {
  if (!jobDesire.value.progress) {
    return ""
  }
  return jobDesire.value.progress.next_steps.map((step: NextStep) => step.display_name).join(", ")
})

const hint = computed(() => {
  if (progressValue.value < 100) {
    return  t(`js.components.progress.missing_steps`, { fields: fieldStr.value })
  } else {
    return t(`js.components.progress.hint.job_desire`)
  }
})

</script>

