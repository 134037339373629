import { Controller } from 'stimulus'

export default class extends Controller {
  declare idValue: number
  static values = { id: Number }

  connect() {
    fetch(`/ajax/jobs/count?id=${this.idValue}`)

    fetch(`/ajax/jobs/similar?id=${this.idValue}`)
      .then(res => res.json())
      .then(data => {
        this.element.innerHTML = data.html
      })
  }
}
