import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"
import Highcharts from "highcharts"
import HighchartsTimeline from "highcharts/modules/timeline"
HighchartsTimeline(Highcharts)

export default class extends Controller {
  static values = { kickoff: String, auswertungstermin: String, vorabtreffen: String, deadline: String }
  stop: () => void = () => { }
  isVisible = false

  connect() {
    const [_observe, stop] = useIntersection(this, { threshold: 0 })
    this.stop = stop
  }

  appear() {
    this.stop()
    const domElement = this.element as HTMLElement
    Highcharts.chart(domElement, {
      chart: {
        type: "timeline",
        backgroundColor: "#FFFFFF",
      },
      title: {
        text: "",
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      series: [
        {
          data: [
            {
              name: `<span style="font-size: 110%; font-weight: bold;">${this.deadlineValue}</span> Deadline Einreichung der Aufgabenstellung`,
              description: "Datum",
            },
            {
              name: `<span style="font-size: 110%; font-weight: bold;">${this.vorabtreffenValue}</span> Vorabtreffen aller Teilnehmenden Unternehmen mit dem Organisationsteam`,
              description: "14 January 2005, first soft landing on Titan also first soft landing in the outer Solar System.",
            },
            {
              name: `<span style="font-size: 110%; font-weight: bold;">${this.kickoffValue}</span> Kick-Off-Veranstaltung`,
              description: "Datum",
            },
            {
              name: "ca. 10-wöchentige Bearbeitungsphase: Bearbeitung der Aufgabenstellung unter regelmäßiger Absprache",
              description: "",
            },
            {
              name: `<span style="font-size: 110%; font-weight: bold;">${this.auswertungsterminValue}</span> Abschlussveranstaltung mit Vorstellung der Lösungen`,
              description: "Datum",
            },
          ],
        },
      ],
    })
  }
}
