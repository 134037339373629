<template lang="pug">
.message-box(:class="boxClass")
  .message-box__timestamp
    span {{ createdAt }}
  .message-box__content(
    :class="contentClass"
  )
    .file-box(v-if="message.file")
      a(:download="message.file.filename" :href="linkSource")
        .mb-2
          i.fas.fa-file(style="margin-right: 0.5rem")
          | {{ message.file.filename }}
        .preview-box.mb-2(v-if="message.file.url")
          img(:src="message.file.url")
        .mb-2(v-if="message.file.content_type.includes('image')")
          img(:src="linkSource" style="width: 150px; object-fit: cover;")
    div(v-html="parseMessage(content)")
</template>

<script setup lang="ts">
import { computed } from "vue"
import { parseMessage } from "@/talents/profile/chat/message"

const props = defineProps<{
  message: Message
}>()

const createdAt = computed(() => {
  return new Date(props.message.created_at).toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" })
})

const linkSource = computed(() => {
  if (!props.message.file) return ""

  return `data:${props.message.file.content_type};base64,${props.message.file.base64}`
})

const content = computed(() => {
  if (props.message.from_system) return `<i class="fas fa-robot" style="margin-right: 0.5rem"></i> ${props.message.content}`

  return props.message.content
})

const boxClass = computed(() => {
   return props.message.from_system ? "center" : props.message.from_org ? "links" : "rechts"
})

const contentClass = computed(() => {
  return  props.message.from_system ? "bg-yellow" : props.message.from_org ? "bg-grey" : "bg-blue"
})

</script>

<style scoped>
.bg-blue {
  background-color: #0e2e36dd;
  color: white;
}
.bg-grey {
  background-color: #f1f1f1;
  color: black;
}
.bg-yellow {
  background-color: #fff3cd;
  border-color: #ffecb5;
  color: #664d03;
}
.message-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}
.message-box.links {
  align-items: flex-start;
}
.message-box.rechts {
  align-items: flex-end;
}
.message-box.center{
  align-items: center;
}
.message-box__content {
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  max-width: 80%;
}
.message-box__timestamp {
  font-size: 0.8rem;
  color: #aaa;
}
.message-box__timestamp.right {
  text-align: right;
}
.file-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
}
.file-box a {
  text-decoration: underline;
}
.message-box.links .file-box a {
  color: black;
}
.message-box.rechts .file-box a {
  color: white;
}
</style>
