import ahoy from "@/utils/ahoy"
import { Controller } from "@hotwired/stimulus"

const cachedImpressions = [] as string[]

export default class extends Controller {
  static values = { jobId: String }
  declare observer: IntersectionObserver
  declare jobIdValue: string
  wasVisible = false

  connect() {
    this.wasVisible = false
    if (cachedImpressions.includes(this.jobIdValue)) return
    this.observer = new IntersectionObserver(this.callback.bind(this), {
      root: null,
      rootMargin: "0px",
      threshold: [0, 0.25, 0.5, 0.75, 1.0],
    })
    this.observer.observe(this.element)
  }

  callback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      const isIntersecting = entry.intersectionRatio > 0.5

      if (this.wasVisible) {
        if (isIntersecting) {
          return
        } else {
          this.wasVisible = false
        }
      } else if (isIntersecting) {
        this.wasVisible = true

        const that = this
        setTimeout(() => {
          if (that.wasVisible) {
            that.trackNow()
          }
        }, 2000)
      }
    })
  }

  trackNow() {
    this.observer.disconnect()

    ahoy.track("job/impression", { id: this.jobIdValue })

    if (cachedImpressions.includes(this.jobIdValue)) {
      return
    }
    cachedImpressions.push(this.jobIdValue)
  }

  disconnect() {
    if (this.observer) this.observer.disconnect()
  }
}
