import BaseController from "./base_controller"

// Jobs & Applications Chart oben auf Mediadatenseite
export default class extends BaseController {
  chartOptions() {
    const options = this.jsonChartOptions()
    options.yAxis[0].labels = {
      formatter: function () {
        return ["", "unzufrieden", "eher unzufrieden", "eher zufrieden", "sehr zufrieden"][this.value]
      },
    }
    return options
  }
}
