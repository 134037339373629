import { defineComponent, h } from "vue"
import { Select } from "@/elements"
import countries from "./countries.json"

export default defineComponent({
  name: "CountryField",
  props: {
    required: { type: Boolean, default: false },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    countryOptions() {
      const emptyCountry = {
        text: this.$t("js.form.properties.country"),
        value: "",
        disabled: true,
      }
      return [emptyCountry, ...countries.map((country) => ({
        text: this.$i18n.locale == "de" ? country.name_de : country.name_en,
        value: country.code,
      }))]
    },
  },
  render() {
    return h("div", {}, [
      h(
        Select,
        {
          modelValue: this.modelValue,
          required: this.required,
          options: this.countryOptions,
          "onUpdate:modelValue": (value: string) => this.$emit("update:modelValue", value),
        },
      ),
    ])
  },
})
