<template lang="pug">
.mt-5.card.bg-medium.text-white.neumorphism-on-medium.mx-auto.d-flex.p-m.w-100
  .row
    .col-12
      .mb-3
        h4.font-light
          | {{ $t('js.components.talent_profile.placeholders.email') }}
        .input-group.input-group-lg
          input.form-control(
            type="email"
            id="email"
            v-model="form.email"
            autocomplete='email'
            placeholder="max.musterman@mail.de"
          )
        .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="!fieldValid('email') && v$.email.$dirty")
          | {{ $t('js.components.talent_profile.errors.email') }}

      .mt-5
        p.fs-5(v-html="$t('js.components.talent_profile.registration_hints.nach_erfolgreich')")
      .mt-5.fs-5
        .form-group.mt-3
          .form-check
            input.form-check-input(type="checkbox" id="privacy" v-model="privacy")
            label.form-check-label(for="privacy")
              p(v-html="$t('js.components.talent_profile.registration_hints.ich_habe_die')")
          .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="!privacy && showError")
            | {{ $t('js.components.talent_profile.errors.privacy') }}


</template>

<script lang="ts" setup>
import { provide, ref, computed } from 'vue'
import { useValidations } from "../talents_form"
import useTalent from "../talents_form"

const { form, v$ } = useTalent()
provide("v", v$);

const showError = ref(false)
const privacy = ref(false)

const fields = ['email']
const genericValidations = useValidations(fields, v$)

const genericTouch = genericValidations.touchFields
const genericValid = genericValidations.valid
const fieldValid = genericValidations.fieldValid

const valid = computed(() => {
  return genericValid.value && privacy.value
})

function touchFields() {
  genericTouch()
  showError.value = true
}

defineExpose({
  valid,
  touchFields,
})
</script>
