<template lang="pug">
div
  .bg-medium-gradient.text-white.pt-5.pb-5.block-clip.block-clip--zoom2(ref='wizard')
    .container
      transition(name='fade')
        .bg-white.rounded.neumorphism-on-medium.text-dark.p-4.mb-5(
          v-if='showInfo && proxyValue === "yes"'
          style="position: relative;"
        )
          button.btn-close(style="position: absolute; top: 2px; right: 3px; font-size: 0.7rem" @click.prevent='showInfo = false')
          .d-flex.justify-content-between.align-items-center.gap-3
            i.fas.fa-info-circle.fa-3x
            | {{ $t('js.components.talent_profile.pages.prefill') }}
            button.btn.btn-accent(@click.prevent='prefill()')
              | {{ $t('js.components.talent_profile.pages.prefill_yes') }}

      transition(name='fade')
        .bg-white.rounded.neumorphism-on-medium.text-dark.p-4.mb-5(
          v-if='showInfo && proxyValue === "prefilled" && Object.keys(fields).length > 0'
          style="position: relative;"
        )
          button.btn-close(style="position: absolute; top: 2px; right: 3px; font-size: 0.7rem" @click.prevent='showInfo = false')
          .text-center
            .mb-1 Folgende Daten wurden von deiner Sitzung übernommen:
            .mb-1(v-for="field in Object.keys(fields)")
              b(style="font-size: 1.2rem; color: #185161; margin-right: 0.5rem;")
                | {{ $t(`js.components.talent_profile.pages.fields.${field}`) }}:
              template(v-if="field === 'recommendation_codes'")
                svg(style="width:24px;height:24px" viewBox="0 0 24 24" aria-label='schlüssel-icon')
                  path(fill="#bf436f" d="M7 14C5.9 14 5 13.1 5 12S5.9 10 7 10 9 10.9 9 12 8.1 14 7 14M12.6 10C11.8 7.7 9.6 6 7 6C3.7 6 1 8.7 1 12S3.7 18 7 18C9.6 18 11.8 16.3 12.6 14H16V18H20V14H23V10H12.6Z")
                code.code(style="font-size: 1.2rem; margin-left: 0.25rem;")
                  | {{ fields[field].join(', ') }}
              template(v-else-if="field === 'email'")
                span(style="font-size: 1.2rem;")
                  | {{ fields[field] }}
              template(v-else-if="field === 'company_blocklist'")
                span(style="font-size: 1.2rem;")
                  | {{ fields[field].join(', ') }}


      h3.display-5.mt-3.text-center
        |{{ page.title }}

      slot(:name="'page(' + currentPage +')'" v-bind:page="page" :index='currentPage')

      .mx-auto.fit-content.mt-4(v-if='!page.hideButton'): .btn-group
        button.btn.btn-outline-light.btn-lg(@click.prevent='goToPage(currentPage - 1)' v-if='currentPage > 0' style="z-index: 0;")
          | {{ $t('js.components.talent_profile.pages.back') }}
        template(v-if='currentPage == pages.length - 1')
          slot(name='last')
        template(v-else)
          button.btn.btn-accent.btn-xl(
            @click.prevent='goNextOrValidate()'
            style="z-index: 0;"
            :class="{'border-white': currentPage != 0}"
          )
            | {{ $t('js.components.talent_profile.pages.next') }}

</template>

<script>
export default {
  props: {
    pages: { type: Array, required: true },
    modelValue: { type: String, default: "no" },
    fields: { Object, default: () => {} },
  },
  data() {
    return {
      currentPage: 0,
      maxPage: 0,
      showInfo: true,
    }
  },
  mounted() {
    const t = this
    this.callback = (event) => {
      if (event.state && event.state.page) {
        t.currentPage = event.state.page
      }
    }
    window.addEventListener("popstate", this.callback)
    window.history.pushState({ page: 0 }, null, "#page-0")
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.callback)
  },
  computed: {
    proxyValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit("update:prefill", value)
      },
    },
    page() {
      return this.pages[this.currentPage]
    }
  },
  methods: {
    prefill() {
      this.proxyValue = "prefilled"
    },
    goNextOrValidate() {
      return this.page.isValid ? this.goNext(this.currentPage + 1) : this.$emit("invalid-next", this.currentPage)
    },
    goNext(page) {
      if (window.history) {
        window.history.pushState({ page: page }, null, `#page-${page}`)
      }
      this.currentPage = page
      if (page > this.maxPage) {
        this.maxPage = page
      }
      setTimeout(() => {
        this.$refs.wizard.scrollIntoView({behavior: 'smooth'})
      }, 100)
    },
    goToPage(i) {
      this.currentPage = i
      this.$refs.wizard.scrollIntoView({behavior: 'smooth'})
      window.history.replaceState({ page: i }, null, `#page-${i}`)
    },
  },
}
</script>

<style scoped>
.bs-clickable {
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
.bs-clickable:hover {
  transform: scale(1.05);
  filter: brightness(1.1);
}
>>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #eee;
  border-color: #fff;
  background-color: #206c80;
}
</style>
