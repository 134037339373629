<template>
  <select
    v-model="selected"
    v-bind="$attrs"
    class="form-select form-select-lg ">
    <option v-for="option in options" :value="option.value" :key="option.value" :disabled="option.disabled">
      {{ option.text }}
    </option>
  </select>
</template>

<script lang="ts" setup>
import { computed } from "vue"

const props = defineProps<{
  options: FormOption[],
  modelValue: string | number
}>()
const emits = defineEmits(["update:modelValue"])

const selected = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits("update:modelValue", value)
  },
})
</script>

<script lang="ts">
export default { name: "Select" }
</script>

<style scoped>
select:invalid { color: gray; }
</style>
