<template>
  <div>
    <div class="alert alert-success" v-if="state == 'success'">
      {{ $t("js.wizard.components.subscribe_form.wir_haben_ihnen_soeben_ein", { email: email }) }}
    </div>
    <form class="d-flex flex-column-on-sm gap-2" @submit="subscribe" v-else="">
      <input
        v-model="email"
        type="email"
        class="form-control form-control-lg"
        name="email"
        autocomplete="email"
        :placeholder="$t('js.wizard.components.subscribe_form.ihre_mail_de')" />
      <button class="btn btn-primary" type="submit" @click.prevent="subscribe" :disabled="isInvalid || state == 'loading'">
        {{ form.query }} abonnieren
      </button>
      <div v-if="state == 'error'" class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"

import { useStorage, debounceFilter } from "@vueuse/core"
import { form } from "@/wizard/form"
import { state, errorMessage, createEmailSubscription } from "@/components/apply/succeed/createEmailSubscription"

const email = useStorage("subscribe-email", null as string | null, localStorage, { eventFilter: debounceFilter(1000) })
const isInvalid = computed(() => {
  return !email.value || !email.value.match(/^[^@]+@[^@]+\.[^@]+\s*$/)
})

const subscribe = () => {
  if (isInvalid.value) return

  createEmailSubscription({
    email: email.value,
    query: form.query,
    radius: form.radius,
    position: form.location,
    sub_job_type: form.sub_job_type,
    portal_types: form.portal_type,
  })
}
</script>

<style scoped></style>
