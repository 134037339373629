import { defineComponent, h, computed, PropType } from "vue"

const common =
  "btn"

const variants = {
  white: "btn-outline-primary",
  secondary: "btn-secondary",
  primary: "btn-primary",
  dark: "btn-dark",
  accent: "btn-accent",
  outlineDark: "btn-outline-dark",
  outlinePrimary: "btn-outline-primary",
  outlineSecondary: "btn-outline-secondary",
  outlineSuccess: "btn-outline-success",
  outlineDanger: "btn-outline-danger",
  outlineWarning: "btn-outline-warning",
  outlineInfo: "btn-outline-info",
  success: "btn-success",
}

const active = {
  white: "active",
  secondary: "active",
  primary: "active",
  dark: "active",
}

const sizes = {
  sm: "btn-sm",
  md: "",
  lg: "btn-lg",
}

export default defineComponent({
  name: "Button",
  props: {
    variant: {
      type: String as PropType<keyof typeof variants | ''>,
      default: "primary",
    },
    size: {
      type: String as PropType<keyof typeof sizes>,
      default: "md",
      validator: (value: string) => ["sm", "md", "lg"].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    // href -> wird ein normaler Link
    href: {
      type: String,
      default: null,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const variantClasses = computed(() => {
      const classes = [common, variants[props.variant]]
      if (props.size) classes.push(sizes[props.size])
      if (props.active) classes.push(active[props.variant])
      let out = classes.join(" ")
      if (props.wide) {
        out = out.replace(/px-\d/, "px-16")
      }
      return out
    })
    return { variantClasses }
  },
  render() {
    const slots = this.$slots
    const slot = {
      default: () => (slots.default ? slots.default() : null),
    }
    if (this.href) {
      return (
        <a href={this.href} class={this.variantClasses} disabled={this.disabled ? true : undefined}>
          {() => slot.default()}
        </a>
      )
    }
    return (
      <button disabled={this.disabled} class={this.variantClasses} role="button">
        {() => slot.default()}
      </button>
    )
  },
})
