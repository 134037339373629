// src/application.js
import { Application } from "@hotwired/stimulus"

import AutohideController from "./autohide_controller"
import CounttoController from "./countto_controller"
import SmoothscrollController from "./smoothscroll_controller"
import RangeSliderController from "./range_slider_controller"
import AhoyController from "./ahoy_controller"
import SlickController from "./slick_controller"
import SimilarJobsController from "./similar_jobs_controller"
import HideontopController from "./hideontop_controller"
import ChartsApplicationsController from "./charts/applications_controller"
import ChartsFeedbackController from "./charts/feedback_controller"
import ChartsSubscriptionsController from "./charts/subscriptions_controller"
import ChartsBaseController from "./charts/base_controller"
import ChartsHeatmapController from "./charts/heatmap_controller"
import ChartsTagcloudController from "./charts/tagcloud_controller"
import ChartsKanaleoPolarController from "./charts/kanaleo_polar_controller"
import ChartsRingvorlesungController from "./charts/ringvorlesung_controller"
import MapController from "./map_controller"
import ImpressionController from "./impression_controller"
import ShareController from "./share_controller"
import ChoicesController from "./choices_controller"
import ContactController from "./contact_controller"

const Stimulus = Application.start()

Stimulus.register("autohide", AutohideController)
Stimulus.register("countto", CounttoController)
Stimulus.register("smoothscroll", SmoothscrollController)
Stimulus.register("range-slider", RangeSliderController)
Stimulus.register("ahoy", AhoyController)
Stimulus.register("slick", SlickController)
Stimulus.register("similar-jobs", SimilarJobsController)
Stimulus.register("hideontop", HideontopController)
Stimulus.register("charts--base", ChartsBaseController)
Stimulus.register("charts--applications", ChartsApplicationsController)
Stimulus.register("charts--feedback", ChartsFeedbackController)
Stimulus.register("charts--subscriptions", ChartsSubscriptionsController)
Stimulus.register("charts--heatmap", ChartsHeatmapController)
Stimulus.register("charts--tagcloud", ChartsTagcloudController)
Stimulus.register("charts--kanaleo-polar", ChartsKanaleoPolarController)
Stimulus.register("charts--ringvorlesung", ChartsRingvorlesungController)
Stimulus.register("map", MapController)
Stimulus.register("impression", ImpressionController)
Stimulus.register("share", ShareController)
Stimulus.register("choices", ChoicesController)
Stimulus.register("contact", ContactController)

window.Stimulus = Stimulus
