<script lang="ts" setup>
import { Icon } from "@/elements"
import { mdiKeyStar } from "@mdi/js"
const props = defineProps<{ code: ValidationSucceed }>()
const emits = defineEmits(["remove"])

const remove = () => {
  emits("remove", props.code)
}
</script>

<template>
  <div class="my-2 border border-gray-500 rounded d-flex align-items-center p-2">
    <div class="d-flex align-items-center">
      <Icon :path="mdiKeyStar" class="fill-primary-500 me-2">
      <img :src="code.logo" v-if="code.logo" class="block h-8 me-2">
    </Icon></div>
    <span class="text-sm flex-grow-1">
      <!-- i18n-use t("js.components.empfehlungscodes_field.recommended_by.#{code.type}") -->
      {{ $t(`js.components.empfehlungscodes_field.recommended_by.${code.type}`, { company: code.firma }) }}
    </span>

    <button @click.prevent="remove" class="btn btn-link">{{ $t('js.components.apply.empfehlungscode_list_item.entfernen') }}</button>
  </div>
</template>

<style scoped>
@media (max-width: 768px) {
  .align-items-center{
    flex-direction: column;
  }
}
</style>
