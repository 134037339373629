import { computed } from "vue"
import { useI18n } from "vue-i18n"
import countries from "@/components/apply/countries.json"

function useQuestionOptions() {
  const { t, locale } = useI18n()

  const citizenshipOptions = computed(() => ([
    {value: "eu_citizen" as const, text: t('js.components.talent_profile.answers.eu_citizen') },
    {value: "work_permit_yes" as const, text: t('js.components.talent_profile.answers.work_permit_yes') },
    {value: "work_permit_no" as const, text: t('js.components.talent_profile.answers.work_permit_no') }
  ]))

  const countryOptions = computed(() => {
    return countries.map((country) => ({
      text: locale.value == "de" ? country.name_de : country.name_en,
      value: country.code,
    }))
  })

  const workPermitOptions = computed(() => ([
    {value: "limited" as const, text: t('js.components.talent_profile.answers.limited') },
    {value: "unlimited" as const, text: t('js.components.talent_profile.answers.unlimited') },
    {value: "no_work_permit" as const, text: t('js.components.talent_profile.answers.no') }
  ]))

  const yesNoOptions = computed(() => ([
    {value: true, text: t('js.components.talent_profile.answers.yes') },
    {value: false, text: t('js.components.talent_profile.answers.no') },
  ]))

  return { citizenshipOptions, workPermitOptions, countryOptions, yesNoOptions }
}

export { useQuestionOptions }
