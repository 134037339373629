import ahoy from "ahoy.js"


window.ahoy = ahoy


ahoy.configure({
  cookies: false,
  visitsUrl: "/stellenanzeigen/visits",
  eventsUrl: "/stellenanzeigen/events",
  useBeacon: false,
})

document.addEventListener("DOMContentLoaded", () => {
  const video = document.querySelector('video')
  if (video) {
    video.addEventListener('play', () => {
      ahoy.track("arbeitgeber/video")
    })
  }
})

export default ahoy
