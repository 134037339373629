import i18n from "@/utils/i18n"
import { email, required, minLength, maxLength, helpers, createI18nMessage } from "@vuelidate/validators"

const t = i18n.global.t

const withI18nMessage = createI18nMessage({
  messagePath: ({ $validator }) => $validator,
  t(path: string, params): string {
    // i18n-use t("js.form.messages.#{path}") -->
    return t(`js.form.messages.${path}`, {
      model: params.model,
      property: t(
        `js.form.properties.${params.propertyPath.split('.')[0]}`,
      ),
    })
  },
})

const i18nEmail = withI18nMessage(email)
const i18nRequired = withI18nMessage(required)
const i18nMinLength = withI18nMessage(minLength, { withArguments: true })
const i18nMaxLength = withI18nMessage(maxLength, { withArguments: true })

export { i18nEmail as email, i18nRequired as required, i18nMinLength as minLength, i18nMaxLength as maxLength}
