<template>
  <ChecklistItem :done="false" v-if="application.job_id && appliedWithin1Week" :openIcon="mdiCalendarRefreshOutline">
    <div class="flex-grow-1">
      <div>
        {{ $t("js.components.apply.apply_succeed.calendar.title") }}
        <br />
        <small>
          {{ $t("js.components.apply.apply_succeed.calendar.subtitle") }}
        </small>
      </div>
      <Button variant="outlineDark" size="sm" class="mt-2" @click="downloadReminder">
        {{ $t("js.components.apply.apply_succeed.calendar.download") }}
      </Button>
    </div>
  </ChecklistItem>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { Button } from "@/elements"
import ChecklistItem from "@/elements/ChecklistItem.vue"
import { mdiCalendarRefreshOutline } from "@mdi/js"
import { jobApplicationCalendarPath } from "@/routes"

const props = defineProps<{
  application: StoredApplicationV2
}>()
const downloadReminder = () => {
  if (!props.application.job_id) return

  const params = {
    applied_on: props.application.applied_on,
  }
  const url = jobApplicationCalendarPath(props.application.job_id, params)
  window.open(url, "_blank")
}
const appliedWithin1Week = computed(() => {
  const appliedAt = new Date(props.application.applied_on)
  const now = new Date()
  const diff = now.getTime() - appliedAt.getTime()
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
  return diffDays <= 7
})
</script>

<style scoped></style>
