import { Controller } from "stimulus"

export default class extends Controller {
  eventListener: EventListener | null  = null

  connect() {
    let last_scroll_top = 0
    const element = this.element
    this.eventListener = function () {
      let scroll_top = window.scrollY
      if (scroll_top < last_scroll_top || scroll_top < 50) {
        element.classList.remove("autohide__scrolled-down")
        element.classList.add("autohide__scrolled-up")
      } else {
        element.classList.remove("autohide__scrolled-up")
        element.classList.add("autohide__scrolled-down")
      }
      last_scroll_top = scroll_top
    }
    window.addEventListener("scroll", this.eventListener)
  }
  disconnect() {
    if (this.eventListener) {
      window.removeEventListener("scroll", this.eventListener)
    }
  }
}
