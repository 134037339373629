<template lang="pug">
.card.bg-white.text-dark.neumorphism-on-white(ref='mainEl')
  .card-body
    div.mb-3.pb-3.d-flex.flex-wrap.gap-2.align-items-end.justify-content-between.company-line(:style="{ borderColor: payload.organisation.company_color || '#ccc' }")
      div
        img.img-fluid(:src='payload.organisation.logo_url' style='height: 45px' loading='lazy')
        br
        small.text-muted.text-truncate(style="max-width: 200px; display: block;")
          | {{payload.organisation.name}}

      organisation-badges(:organisation='payload.organisation' @click='openAndScrollToRewards')

    picture(v-if='payload.organisation.default_banner_url' style='width: 100%')
      img.banner(:src='payload.organisation.default_banner_url' loading='lazy' style='width: 100%' :style='{ aspectRatio: aspectRatio }')

    .row
      .col-12.col-lg-6.mt-3(v-if='payload.organisation.video_thumbnail_url && payload.organisation.video_url')
        video-player(
          :thumbnail='payload.organisation.video_thumbnail_url'
          :url='payload.organisation.video_url'
        )
      .col-12.col-lg-6.mt-3(:class='payload.organisation.video_thumbnail_url ? "col-lg-6" : "col-lg-12"')
        .blockquote.blockquote-homepage.font-light.my-2(
          v-html='payload.organisation.applicant_information'
          :style='{borderColor: payload.organisation.company_color || "#ccc"}'
          :class='{ "all": blockquoteOpen }'
          @click='blockquoteOpen = !blockquoteOpen'
        )

    .text-center.mx-auto.fit-content.my-5: button.btn-outline-primary.btn.btn-lg(@click='infoModal = !infoModal')
      template(v-if='infoModal')
        | {{ $t('js.wizard.organisation_card.weniger_zur_organisation') }}
      template(v-else)
        | {{ $t('js.wizard.organisation_card.mehr_zur_organisation') }}

    collapse-transition
      organisation-details(:organisation='payload.organisation' v-if='infoModal' ref='detailsEl')

    .mt-5.text-left
      job-list(:jobs='payload.jobs' :organisation='payload.organisation')

</template>

<script lang="ts" setup>
import { ref, computed, nextTick } from "vue"
import VideoPlayer from "@/wizard/VideoPlayer.vue"
import JobList from "@/wizard/JobList.vue"
import OrganisationDetails from "@/wizard/components/OrganisationDetails.vue"
import OrganisationBadges from "@/wizard/components/OrganisationBadges.vue"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import { useIntersectionObserver } from "@vueuse/core"
import ahoy from "@/utils/ahoy"

const mainEl = ref<HTMLElement | null>(null)

const blockquoteOpen = ref(false)
const infoModal = ref(false)
const detailsEl = ref<typeof OrganisationDetails | null>(null)

const props = defineProps<{
  payload: frontend.CompanyFinderResult["organisations"][0]
}>()
const aspectRatio = computed(() => {
  return props.payload.organisation.default_banner_aspect_ratio ? props.payload.organisation.default_banner_aspect_ratio : "initial"
})

const openAndScrollToRewards = () => {
  infoModal.value = true
  nextTick(() => {
    setTimeout(() => {
      const el = detailsEl.value
      el?.rewardEl?.scrollIntoView({ behavior: "smooth" })
    }, 200)
  })
}

const { stop } = useIntersectionObserver(mainEl, ([{ isIntersecting, intersectionRatio }], observerElement) => {
  if (isIntersecting) {
    ahoy.track("wizard/impression", { username: props.payload.organisation.username } )
    stop()
  }
}, { threshold: 0.2 })
</script>

<style scoped>
.company-line {
  border-bottom: 3px solid #ccc;
}
.blockquote {
  font-size: 1.2rem;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blockquote.all {
  display: block;
}
.banner {
  border-radius: 15px;
  margin-bottom: 10px;
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  object-position: top;
}
</style>
