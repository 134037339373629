<template lang="pug">
.d-flex.justify-content-between.gap-5.profile-header.flex-column-on-sm.mt-3
  .d-flex.justify-content-start.align-items-center.gap-5.flex-column-on-sm
    .name-circle.d-none.d-lg-flex(v-if="applicationProfile.data.first_name.length > 0")
      | {{ applicationProfile.data.first_name[0].toUpperCase() }}
    .name-circle.d-none.d-lg-flex(v-else-if="profile.data.email.length > 0")
      | {{ profile.data.email[0].toUpperCase() }}
    .short-info.full-width-on-sm.center-on-sm
      div(v-if="fullname.length > 1")
        i.fas.fa-user-circle(style="margin-right: 0.5rem")
        | {{ fullname }}
      .mt-2
        i.fas.fa-envelope(style="margin-right: 0.5rem")
        | {{ profile.data.email }}
      .mt-2(v-if="applicationProfile.data.telephone")
        i.fas.fa-phone(style="margin-right: 0.5rem")
        | {{ applicationProfile.data.telephone }}
  .d-flex.flex-column.justify-content-center.align-items-end.full-width-on-sm
    button.btn.btn-outline-danger.full-width-on-sm.no-transform(@click="showLogout=!showLogout" style="min-width: 150px")
      i.fas.fa-sign-out-alt(style="margin-right: 0.5rem")
      | {{ $t("js.components.profile.quick_info.abmelden") }}
    collapse-transition
      .mt-2(v-show="showLogout")
        small
          | {{ $t("js.components.profile.quick_info.bist_du_sicher") }}
        .d-flex.justify-content-end
          button.btn.btn-outline-success.btn-sm(style="margin-right: 0.5rem" @click="showLogout = false")
            | {{ $t("js.components.talent_profile.answers.no") }}
          button.btn.btn-danger.btn-sm(@click="logout")
            | {{ $t("js.components.talent_profile.answers.yes") }}
    button.btn.btn-outline-dark.mt-2.no-transform.full-width-on-sm#settings-button(@click="setModalContent('profile_settings')" style="min-width: 150px")
      i.fas.fa-gear(style="margin-right: 0.5rem")
      | {{ $t("js.components.profile.quick_info.settings") }}


</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { talentsLogoutPath } from "@/routes"
import { profile, applicationProfile } from "@/talents/profile/profile"
import { setModalContent } from  "@/talents/profile/contents/modal"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import csrfToken from "@/utils/csrfToken"

const showLogout = ref<boolean>(false)

function logout() {
  const logoutPath = talentsLogoutPath()

  fetch(logoutPath, {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": csrfToken.value || "",
    },
  })
    .then(() => {
      window.location.href = "/talents"
    })
    .catch((error) => {
      console.error(error)
    })
}

const fullname = computed(() => {
  if (applicationProfile.value && applicationProfile.value.data) {
    return applicationProfile.value.data.first_name + ' ' + applicationProfile.value.data.last_name
  }
  return ''
})
</script>

<style scoped>
.profile-header {
  padding-bottom: 2rem;
  border-bottom: 1px solid #dee2e6;
}

.name-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 5rem;
  font-weight: 600;
}

.short-info {
  font-size: 1.2rem;
  font-weight: 500;
  color: #103640;
}

@media (max-width: 768px) {
  .name-circle {
    width: 80px;
    height: 80px;
    font-size: 3rem;
  }
}

</style>

