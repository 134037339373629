import { defineComponent, h, PropType } from "vue"
import { FormInput, RadioGroup, CheckboxGroup, Select, FormGroup } from "@/elements"
import { useI18n } from "vue-i18n"

export default defineComponent({
  name: "Question",
  props: {
    modelValue: { type: [String, Object] as PropType<any>},
    question: {
      type: Object as PropType<Question>,
      required: true,
    },
  },
  setup() {
    const locale = useI18n().locale
    return {
      locale,
    }
  },
  render() {
    const label = this.locale == "de" ? this.question.question_de : this.question.question_en
    let answers: FormOption[] = []
    if (this.question.answer_type == "check_boxes" || this.question.answer_type == "radio_buttons" || this.question.answer_type == "select") {
      answers = this.question.answers.map((e) => (this.locale == "de" ? e.de : e.en || e.de)).map((e) => ({ text: e, value: e }))
    }
    const id = "question_answers[" + this.question.question_id.toString() + "]"

    switch (this.question.answer_type) {
      case "radio_buttons":
        return (
          <RadioGroup
            name={this.question.question_id.toString()}
            modelValue={this.modelValue}
            onUpdate:modelValue={(value: any) => this.$emit("update:modelValue", value)}
            label={label}
            options={answers}
          />
        )
      case "select":
        return (
          <FormGroup label={label} for={id}>
            <Select
              name={this.question.question_id.toString()}
              modelValue={this.modelValue}
              onUpdate:modelValue={(value: any) => this.$emit("update:modelValue", value)}
              options={answers}
            />
          </FormGroup>
        )
      case "check_boxes":
        return (
          <FormGroup label={false} for={id}>
            <CheckboxGroup
              name={this.question.question_id.toString()}
              modelValue={this.modelValue}
              onUpdate:modelValue={(value: any) => this.$emit("update:modelValue", value)}
              label={label}
              options={answers}
            />
          </FormGroup>
        )
      case "number":
        return (
          <FormInput
            type="number"
            name={this.question.question_id.toString()}
            modelValue={this.modelValue}
            label={label}
            step="0.01"
            placeholder={false}
            onUpdate:modelValue={(value: any) => this.$emit("update:modelValue", value)}
          />
        )
      case "date":
        return (
          <FormInput
            type="date"
            name={this.question.question_id.toString()}
            modelValue={this.modelValue}
            label={label}
            placeholder={false}
            onUpdate:modelValue={(value: any) => this.$emit("update:modelValue", value)}
          />
        )
      default:
        return (
          <FormInput
            type="textarea"
            name={this.question.question_id.toString()}
            modelValue={this.modelValue}
            label={label}
            placeholder={false}
            onUpdate:modelValue={(value: any) => this.$emit("update:modelValue", value)}
          />
        )
    }
  },
})
