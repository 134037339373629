<template lang="pug">
.d-flex.justify-content-around.gap-3(@click="selectConv" :id="`conv-${conversation.id}`")
  .circle--avatar
    img.img-fluid.avatar(
      v-if="organisation.logo_url"
      :src="organisation.logo_url" alt="organisation-logo"
    )
  .flex-grow-1.preview-content
    .display-name(:class="{ 'fw-bold': active || conversation.is_read === false }")
      span {{ organisation.name }}
    .last-message(:class="{ 'text-muted': !active }" v-html="lastMessage")
    .last-updated-at(:class="{ 'text-muted': !active }" v-html="lastUpdatedAt")
  .d-flex.justify-content-center.align-items-center(v-if="conversation.is_read === false" style="color: #bf436f")
    i.fas.fa-circle.fa-xs

</template>

<script setup lang="ts">
import { computed } from "vue"
import { pureMessage } from "@/talents/profile/chat/message"

const props = defineProps<{
  conversation: FrontendConversation
  active: boolean
}>()

const emits = defineEmits([ "select:conv" ])

function selectConv() {
  emits("select:conv", props.conversation.id)
}

const organisation = computed(() => {
  return props.conversation.organisation_data
})

import { useI18n } from "vue-i18n"
const { t } = useI18n()
const lastMessage = computed(() => {
  const message = props.conversation.last_message.content
  const hasFile = props.conversation.last_message.file

  if (hasFile && message.length === 0) {
    return `<i class="far fa-file" style="margin-right: 0.5rem"></i><em>${t("js.components.profile.conversation.attachment")}</em>`
  }
  return pureMessage(message)
})

const  lastUpdatedAt = computed(() =>{
  const lastUpdatedAt = props.conversation.last_updated_at

  return new Date(lastUpdatedAt).toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "numeric" })
})

</script>

<style lang="scss" scoped>
.fw-bold {
  font-weight: bold;
}
.preview-content {
  flex: 1;
  min-width: 0;
  .last-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .preview-content {
       .last-message {
      font-size: 0.9rem;
    }
    .last-updated-at {
      font-size: 0.9rem;
    }
  }
}
</style>
