<template>
  <div>
    <span v-for='i in 5'>
      <a href='#' @click.prevent='rate(i)' @mouseover='previewRating(i + 1)'>
        <svg viewBox='0 0 24 24' fill='currentColor' style='width: 24px; height: 24px'>
          <path :d='currentPreviewRating > i ? mdiStar : mdiStarOutline' />
        </svg>
      </a>
    </span>
  </div>
</template>

<script lang='ts' setup>
import { ref, computed } from 'vue'

// const props = defineProps<{}>()
import { mdiStarOutline, mdiStar } from "@mdi/js"

const currentPreviewRating = ref(0)
const previewRating = (stars: number) => {
  currentPreviewRating.value = stars
}

const emits = defineEmits<{
  (e: 'rate', value: number): void
}>()

const savedRating = ref(0)


function rate(value: number) {
  emits('rate', value)
  savedRating.value = value
}
</script>

<style scoped></style>

