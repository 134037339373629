<template>
  <label class="form-check">
    <input class="form-check-input" type="checkbox" :value="value" v-model="proxyValue" />
    <div class="form-check-label"><slot /></div>
  </label>
</template>

<script setup lang="ts">
import { computed } from "vue"

const emits = defineEmits(["update:modelValue"])

const props = defineProps(["modelValue", "value"])

const proxyValue = computed({
  get(): any[] {
    return props.modelValue
  },
  set(val: any[]) {
    emits("update:modelValue", val)
  },
})
</script>
<script lang="ts">
export default { name: "Checkbox" }
</script>
