<template lang="pug">
.d-flex.justify-content-start.flex-wrap.gap-3
  template(v-if="!minimize")
    button.btn.btn-primary.mb-3.pt-2.pb-2.full-width-on-sm(
      v-for="(competence, idx) in proxyValue"
      :key="idx"
      @click="toggleImportance(idx)"
      :class='active(idx) ? "active" : ""'
      style="text-transform: none; min-width: 200px;"
    )
      h5.m-0
        | {{ competence[`name_${locale}`] }}
  template(v-else)
    table.table.table-striped.table-sm
      thead
        tr
          th
            | {{ $t('js.components.talent_profile.important_competences.label') }}
          th.text-center
            |
      tbody
        tr(v-for="(competence, idx) in proxyValue" :key="idx")
          td
            | {{ competence[`name_${locale}`] }}
          td.text-center
            div
              input.form-check-input(
                type="checkbox"
                :key="idx"
                :id="`important-competence-${idx}`"
                :checked="active(idx)"
                style="width: 1.25rem; height: 1.25rem; cursor: pointer;"
                @change="toggleImportance(idx)"
              )
              label.form-check-label(
                :for="`important-competence-${idx}`"
              )


</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { computed } from "vue"

const { locale } = useI18n()

const props = withDefaults(defineProps<{
  modelValue: CompetenceInput[]
  minimize: boolean
}>(), {
  minimize: false,
})

const emits = defineEmits(["update:modelValue"])

const proxyValue = computed({
  get() {
    return props.modelValue
  },
  set(value: CompetenceInput[]) {
    emits("update:modelValue", value)
  },
})

function toggleImportance(idx: number) {
  const competence = proxyValue.value[idx]

  competence.is_important = !competence.is_important
}

const active = (id: number) => {
  const competence = proxyValue.value[id]
  if (competence.is_important === undefined || competence.is_important === null) {
    competence.is_important = false
  }

  return competence.is_important
}
</script>

<style scoped>
.active {
  background: #bf436f !important;
}
</style>
