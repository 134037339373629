<template lang="pug">
div
  .input-group.input-group-lg
    input.form-control(
      type="text"
      :name="name"
      v-model="searchStr"
      :placeholder="placeholder"
      @keyup.enter="handleSelect(`${enterOption}`)"
      @keydown.tab.exact="(e) => keyBoardSelectedIndexSet(1, e)"
      @keydown.down.prevent="(e) => keyBoardSelectedIndexSet(1, e)"
      @keydown.up.prevent="(e) => keyBoardSelectedIndexSet(-1, e)"
    )
    transition(name="fade")
      .auto-complete(
        v-if="searchStr.length > 0"
      )
        .auto-complete-item(
          v-for="(option, idx) in optionsSortedByStartWithAndLength"
          :class="idx === keyBoardSelectedIndex ? 'auto-complete-item-active' : ''"
          :key="option.value"
          @click="props.useTextOnly ? handleSelect(`${option.text}`) : handleSelect(`${option.value}`)"
        )
          | {{ option.text }}
  slot(name="selected")
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { debouncedRef } from '@vueuse/core'

const searchStr = ref<string>("")
const debouncedSearchStr = debouncedRef(searchStr, 300)

const props = withDefaults(defineProps<{
  modelValue: string[],
  options: TalentFormOption[],
  placeholder?: string,
  allowCustom?: boolean,
  allowOneOption?: boolean,
  name?: string,
  useTextOnly?: boolean,
}>(), {
  name: "select_auto_complete",
  placeholder: "Suche",
  allowCustom: false,
  useTextOnly: false,
})

const enterOption = computed(() => {
  if (keyBoardSelectedIndex.value === -1 && props.allowCustom) {
    return searchStr.value
  }

  if (keyBoardSelectedIndex.value > -1 && optionsSortedByStartWithAndLength.value.length > 0) {
    const option = optionsSortedByStartWithAndLength.value[keyBoardSelectedIndex.value]

    return props.useTextOnly ? option.text : option.value
  }

  return ""
});

const filteredOptions = computed(() => {
  if(debouncedSearchStr.value.length === 0) {
    return []
  }

  return props.options.filter((option: FormOption) => {
    const val = option.value?.toString().toLowerCase()
    const txt = option.text.toLowerCase()
    const term = debouncedSearchStr.value.toLowerCase()
    return val?.includes(term) || txt.includes(term)
  })
})

const optionsSortedByStartWithAndLength = computed(() => {
  return filteredOptions.value.sort((a: FormOption, b: FormOption) => {
    const aStartsWith = a.text.toString().toLowerCase().startsWith(debouncedSearchStr.value.toLowerCase())
    const bStartsWith = b.text.toString().toLowerCase().startsWith(debouncedSearchStr.value.toLowerCase())
    if(aStartsWith && !bStartsWith) {
      return -1
    }
    if(!aStartsWith && bStartsWith) {
      return 1
    }
    return a.text.length - b.text.length
  }).slice(0, 5)
})

const emits = defineEmits(["update:modelValue"])

const proxyValue = computed({
  get() {
    return props.modelValue
  },
  set(value: string[]) {
    emits("update:modelValue", value)
  },
})

function handleSelect(value: string) {
  const val = value.trim()
  if(val.length === 0) return

  if(props.allowOneOption) {
    proxyValue.value = [val]
  }
  else if(!proxyValue.value.includes(value)) {
    proxyValue.value = [...proxyValue.value, value]
  }
  keyBoardSelectedIndex.value = -1
  searchStr.value = ''
}

const keyBoardSelectedIndex = ref<number>(-1)

const keyBoardSelectedIndexSet = (index: number, e: Event) => {
  if (filteredOptions.value.length === 0) e.preventDefault()

  if (keyBoardSelectedIndex.value + index < 0) keyBoardSelectedIndex.value = 0
  else if (keyBoardSelectedIndex.value + index >= optionsSortedByStartWithAndLength.value.length - 1) {
    keyBoardSelectedIndex.value = optionsSortedByStartWithAndLength.value.length - 1
  } else keyBoardSelectedIndex.value += index
}

</script>

<style scoped lang="scss">
.input-group {
  position: relative;
}
.auto-complete {
  position: absolute;
  top: 100%;
  margin-top: 2px;
  z-index: 1;
  width: 100%;
  background-color: white;
  color: black;
  border: 2px solid #ced4da;
  height: fit-content;
  overflow-y: scroll;
}
.auto-complete-item {
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: #f8f9fa;
  }
}
.auto-complete-item-active {
  background-color: #d8dfe5;
}
.auto-complete-item:first-child::after{
  content: "";
  font-size: 1rem;
  position: absolute;
  top: -5px;
  right: 10px;
  color: #000;
}
.custom-badge{
  background-color: #103640;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
