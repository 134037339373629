<template>
  <FormGroup :v="v" :label="label" :for="name" :disableError="disableError">
    <div class="radio-options" :class="inline ? 'radio-options--inline' : ''">
      <div v-for="option in options" :key="option.value.toString()" class="form-check">
        <input
          :id="id(option)"
          :name="name"
          type="radio"
          :checked="modelValue == option.value"
          @input="emits('update:modelValue', option.value)"
          class="form-check-input" />
        <label :for="id(option)" class="form-check-label" v-html='option.text'>
        </label>
      </div>
    </div>
  </FormGroup>
</template>

<script lang="ts" setup>
import { FormGroup } from "@/elements"

const props = defineProps<{
  options: FormOption[]
  label?: string | boolean
  name: string
  modelValue: string | number | boolean | null
  inline?: boolean
  v?: any
  disableError?: boolean
}>()
const emits = defineEmits(["update:modelValue"])

const id = (option: FormOption) => {
  const v = option.value || "null"
  return `${props.name}_${v.toString().replace(/\W/g, "_")}`
}
</script>

<script lang="ts">
export default { name: "RadioGroup" }
</script>

<style scoped>
.radio-options--inline {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 0.5rem;
  margin-bottom: 1rem;
}
</style>
