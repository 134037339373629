<template>
  <ChecklistItem :done="false" :openIcon="mdiMailboxOpenUpOutline">
    <p><a class='link' href="#" @click.prevent="showForm = !showForm">
      {{ $t("js.components.apply.apply_succeed.subscribe.title") }}
    </a></p>

    <CollapseTransition>
      <div v-if="showForm">
        <div v-if="state == 'success'">
          {{ $t("js.wizard.components.subscribe_form.wir_haben_ihnen_soeben_ein", { email: email }) }}
        </div>
        <form class="d-flex gap-2" @submit="subscribe" v-else="">
          <div class="d-flex flex-column gap-2">
            <FormInput v-model="email" name="email" autocomplete="email" type="email" />
            <FormInput v-model="query" name="search_terms" />
            <FormInput v-model="position" name="position" />
            <button class="btn btn-primary" type="submit" @click.prevent="subscribe" :disabled="isInvalid || state == 'loading'">
              {{ $t("js.components.apply.apply_succeed.subscribe.button", { query: query }) }}
            </button>
            <div v-if="state == 'error'" class="alert alert-danger">
              {{ errorMessage }}
            </div>
          </div>
        </form>
      </div>
      </CollapseTransition>
  </ChecklistItem>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue"
import { FormInput, ChecklistItem } from "@/elements"
import { mdiMailboxOpenUpOutline } from "@mdi/js"
import { state, errorMessage, createEmailSubscription } from "@/components/apply/succeed/createEmailSubscription"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"

const showForm = ref(false)

const props = defineProps<{
  application: StoredApplicationV2
  job: JobPayload | (frontend.Job & { company_name: string })
}>()

const email = ref("")
const query = ref("")
const position = ref("")
const isInvalid = computed(() => {
  return !email.value || !email.value.match(/^[^@]+@[^@]+\.[^@]+\s*$/)
})

onMounted(() => {
  query.value = props.job.tag_list?.slice(0, 2)?.join(" ")
  if (props.job.location) {
    position.value = props.job.location.split(',')[0]
  }
})

const subscribe = () => {
  if (isInvalid.value) return

  createEmailSubscription({
    email: email.value,
    query: query.value,
    position: position.value,
    portal_types: props.job.portal_type
  })
}
</script>

<style scoped>
a.link {
  text-decoration: underline;
}

</style>
