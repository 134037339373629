import { ref, computed, onMounted, watch } from "vue"
import { useStorage, debounceFilter } from '@vueuse/core'
import { talentsProfileApplicationProfilePath, talentsProfileProfileSectionPath } from "@/routes"
import useError from "@/utils/unwrapError"
import csrfToken from "@/utils/csrfToken"

const persistPageInfo = useStorage<{ currentTab: number }>("page-info", {
  currentTab: 0,
}, sessionStorage, { eventFilter: debounceFilter(300) })

const profile = useStorage<{ data: TalentProfile, progress: Progress | null }>("profile", {
  data: {
    email: '',
    state: '',
    company_blocklist: [],
    language_skills: [],
    work_permit: '' ,
    country_code: '',
    german_residency: null,
    ready_to_move: null,
    competence_levels: [],
    portal_type: 'it',
    optional_text: ''
  },
  progress: null
}, localStorage, { eventFilter: debounceFilter(1000) })

const applicationProfile = useStorage<{ data: ApplicationProfile, progress: Progress | null }>("application-profile", {
  data: {
    first_name: '',
    last_name: '',
    telephone: '',
    files: [] as ActiveStorage.Blob[],
  },
  progress: null
}, localStorage, { eventFilter: debounceFilter(1000)})

const jobDesire = useStorage<{ data: JobDesire, progress: Progress | null }>("job-desire-progress", {
  data: {
    job_titles_preference: [],
    sub_job_types_preference: [],
    locations: [],
    work_location_state: 'onsite',
    salary_interval: null,
    salary_preference: null,
    radius: null
  },
  progress: null
}, localStorage, { eventFilter: debounceFilter(1000) })

const education = useStorage<{ data: Education, progress: Progress | null }>("education-progress", {
  data: {
    degree: '',
    certificates: []
  },
  progress: null
}, localStorage, { eventFilter: debounceFilter(1000)});

function useSubmit(type: UpdateType){
  const { error, fromFetchCatch, reset: resetError, isSubmitting } = useError()
  const dataSent = ref<boolean>(false);
  const form = ref<any>({})

  const LOCAL_FORM: { [key in UpdateType]: any } = {
    'application_profile': applicationProfile,
    'job_desire':  jobDesire,
    'education':  education,
    'profile':  profile
  }

  const updatePath = () => {
    if (type == 'application_profile') return talentsProfileApplicationProfilePath()
      return talentsProfileProfileSectionPath()
  }

  const updateForm = () => {
    const formData = LOCAL_FORM[type].value.data
    form.value = JSON.parse(JSON.stringify(formData))
  }

  watch(LOCAL_FORM[type], () => {
    updateForm()
  })

  onMounted(() => {
    updateForm()
  })

  const formChanged = computed(() => {
    if (form.value == null) return false

    const before = LOCAL_FORM[type].value.data
    return (JSON.stringify(form.value) != JSON.stringify(before))
  })

  async function submit(fields : string[] | null = null) {
    if (!formChanged.value) return

    let formToSubmit = null
    if (fields && fields.length > 0) {
      formToSubmit = {} as any
      for (let field of fields) {
        formToSubmit[field] = form.value[field]
      }
    }
    else {
      const { competence_levels, language_skills, radius, email, ... rest } = form.value
      formToSubmit = rest
    }

    resetError();
    const response = await fetch(updatePath(), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.value || "",
      },
      body: JSON.stringify({
        type: type,
        talents_profile_form: {
          ...formToSubmit,
        }
      }),
    })
    if (!response.ok) {
      fromFetchCatch(response)
    }
    else {
      const data = await response.json()
      // console.log(data)
      const toUpdate = LOCAL_FORM[type]
      toUpdate.value = data
    }
    isSubmitting.value = false
    dataSent.value = true
  };

  return { submit, error, isSubmitting, dataSent, form, formChanged }
}

export { useSubmit, profile, applicationProfile, jobDesire, education, persistPageInfo }

