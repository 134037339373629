<template lang='pug'>
section.d-flex(v-bind="$attrs" ref='element')
  .card.mt-3.mb-3
    .card-body.d-flex.flex-column.justify-content-between
      .mb-3.text-center
        small.text-muted
          |{{ job.organisation_data.name }}
      .m-auto(v-if="job.organisation_data.logo_url")
        img.img-fluid(:src='job.organisation_data.logo_url' loading='lazy' style="max-height: 8rem;")
      .mt-3
        .text-center
          .job-title(@click="showDetails()")
            | {{ job.job_data.title }}
          small.text-muted {{ job.job_data.location }}
        .teaser.mt-3
          | {{ job.teaser }}
        .mt-2
          job-badges(:job='job.job_data')
      .mt-3
        button.btn.btn-secondary.text-center.mt-3.w-100(@click="showDetails()")
          | {{ $t('js.components.profile.job_offer.stelle_details') }}
        a.btn.btn-outline-accent.text-center.mt-2.w-100(:href="previewPath(job.job_data.id)" target="_blank")
          | {{ $t('js.wizard.app.bewerben') }}
Modal.text-dark(
  v-model="showModal"
  id="job-details-modal"
  :title="$t('js.components.profile.job_offer.job_modal')"
  :hideFooter="true"
  size="xl"
)
  JobDetails(
    :job="job.job_data"
    :organisation="job.organisation_data"
  )
</template>

<script lang='ts' setup>
import { ref } from 'vue'
import { Modal } from "@/elements"
import JobBadges from "@/wizard/JobBadges.vue"
import JobDetails from "./JobDetails.vue"
import ahoy from "@/utils/ahoy"

let clickTracked = false
let impressionTracked = false

const props = defineProps<{ job: any }>()

function showDetails() {
  showModal.value = true
  if (!clickTracked) {
    ahoy.track('job_click', { id: props.job.job_data.id , loc: 'talents_jobboard' })
    clickTracked = true
  }
}
const showModal = ref(false)

import { talentsProfilePreviewPath } from "@/routes"
function previewPath(jobId: number) {
  return talentsProfilePreviewPath({ job_id: jobId })
}

import { useIntersectionObserver } from "@vueuse/core"
const element = ref<HTMLElement | null>(null)
const { stop } = useIntersectionObserver(element, ([{ isIntersecting, intersectionRatio }], observerElement) => {
  if (isIntersecting) {
    if (!impressionTracked) {
      ahoy.track("job/impression", { id: props.job.job_data.id })
      impressionTracked = true
    }
    stop()
  }
}, { threshold: 0.2 })
</script>

<style scoped>
.card {
  padding: 0.5rem;
  border-radius: 1rem;
  min-height: 10rem;
}
.job-title {
  color: #103640;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}
.job-title:hover {
  text-decoration: underline;
}

</style>
