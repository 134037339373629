<template lang="pug">
.mt-5.card.bg-medium.text-white.neumorphism-on-medium.mx-auto.d-flex.p-m
  .row
    .col-12: .mb-3
      h4.font-light.mb-4
        | {{ $t('js.components.talent_profile.job_titles.label') }}
      job-title-suggestions(
        v-model="form.job_titles_preference"
        :portalType="form.portal_type"
        :showError="!fieldValid('job_titles_preference') && v$.job_titles_preference.$dirty"
       )
</template>

<script lang="ts" setup>
import { provide } from 'vue'
import JobTitleSuggestions from '../components/JobTitleSuggestions.vue'
import { useValidations } from "../talents_form"

import useTalent from "../talents_form"
const fields = ['job_titles_preference']

const { form, v$ } = useTalent()
provide("v", v$);

const { touchFields, valid, fieldValid } = useValidations(fields, v$)

defineExpose({
  valid,
  touchFields
})
</script>
