import Glide from "@glidejs/glide"

import { Controller } from "stimulus"

export default class extends Controller {
  glide: Glide.Static | null = null
  static values = {
    autoplay: Boolean,
  }

  connect() {
    const glide = new Glide(this.element as HTMLElement, {
      type: "carousel",
      autoplay: this.autoplayValue ? 10000 : false,
      hoverpause: true,
      gap: 20,
      perView: 3,
      focusAt: "center",
      startAt: 1,
      breakpoints: {
        1000: {
          perView: 2,
        },
        600: {
          perView: 1,
        },
      },
    })
    glide.mount({})
    this.glide = glide
  }
  disconnect() {
    if (this.glide)
      this.glide.destroy()
  }
}
