<template>
  <FormGroup :class="wrapperClass" :v="v" :for="name" :label="label" v-slot="slotProps" :ns="ns">
    <component
      :is="type == 'textarea' ? 'textarea' : 'input'"
      :type="type || 'text'"
      @input="onChanged"
      :value="modelValue"
      @change="slotProps.touch"
      @blur="slotProps.touch"
      class="form-control form-control-lg"
      :class="slotProps.error ? 'border-red-500' : ''"
      :placeholder="showPlaceholder"
      :name="name"
      :id="name"
      v-bind="$attrs" />
  </FormGroup>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { FormGroup } from "@/elements"

import { useI18n } from "vue-i18n"
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    type?: "text" | "email" | "number" | "date" | "textarea"
    name: string
    wrapperClass?: string
    modelValue?: string | number | null
    placeholder?: string | boolean
    label?: string | boolean
    ns?: string
    v?: any
  }>(),
  {
    label: true,
    ns: "js.form.properties",
    placeholder: true,
    type: "text",
  }
)

const i18nLabel = computed(() => {
  const key = `${props.ns}.${props.name}`
  return t(key)
})

const showPlaceholder = computed(() => {
  if (props.placeholder === true) {
    return i18nLabel.value
  } else if (props.placeholder === false) {
    return null
  } else {
    return props.placeholder
  }
})

const emit = defineEmits(["update:modelValue"])
const onChanged = (e: Event) => {
  const target = e.target as HTMLInputElement

  emit("update:modelValue", target.value)
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
  name: "FormInput",
}
</script>
