<template lang="pug">
div
  .row.mt-3.job-board__row(v-if="!loaded")
    .col-lg-4.col-12(v-for="i in 3" :key="i"): .mb-5
      .skeleton-box
        .skeleton-box--image(style="width: 100%; height: 10rem")
        .mt-5
          .skeleton-box--title
        .mt-5
          .skeleton-box--content
          .skeleton-box--content
          .skeleton-box--content
  template(v-else)
    h2.profile-card-title.mb-3#top
      | {{ $t('js.components.profile.job_suggestions.header') }}
    p
      | {{ $t("js.components.profile.job_suggestions.intro") }}
    .text-center.text-muted.mt-3(v-if="jobSuggestions.length > 0")
      | {{ $t('js.components.profile.job_suggestions.page_von', { page: currentPage, total: totalPages }) }}
    .row.job-board__row(ref="parent" v-if="jobSuggestions.length > 0")
      job-card.col-lg-4.col-12(v-for="job in paginatedJobSuggestions" :key="job.id" :job='job')
    .text-center.border.rounded.p-3(v-else)
      i.fas.fa-calendar-day.mt-5.mb-5.fa-10x
      .profile-card-title(style="font-size: 1.2rem;")
        | {{ $t('js.components.profile.job_suggestions.empty') }}
    .row(v-if="jobSuggestions.length > 0")
      .col-6: .mt-3
        button.btn.btn-outline-primary(@click="prevPage()" :disabled="currentPage == 1")
          i.fas.fa-chevron-left(style="margin-right: 0.5rem")
          | {{ $t('js.components.talent_profile.pages.back') }}
      .col-6: .mt-3.text-right
        button.btn.btn-outline-primary(@click="nextPage()" :disabled="currentPage == totalPages")
          | {{ $t('js.components.talent_profile.pages.next') }}
          i.fas.fa-chevron-right(style="margin-left: 0.5rem")
      .col-12: .mt-3(v-if="currentPage == totalPages")
        button.btn.btn-primary.btn-lg.w-100(@click="loadNewQueue()" v-if="!last")
          | {{ $t('js.components.profile.job_suggestions.load_new') }}
        button.btn.btn-primary.btn-lg.w-100(v-else :disabled="true")
          | {{ $t('js.components.profile.job_suggestions.all_seen') }}

</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import JobCard from "./JobCard.vue"

const PER_PAGE = 3
const jobSuggestions = ref<JobSuggestion[]>([])
const last = ref<boolean>(false)
const loaded = ref(false)
const currentPage = ref(1)

const paginatedJobSuggestions = computed(() => {
  const start = (currentPage.value - 1) * PER_PAGE
  const end = currentPage.value * PER_PAGE
  return jobSuggestions.value.slice(start, end)
})

const totalPages = computed(() => {
  return Math.ceil(jobSuggestions.value.length / PER_PAGE)
})

import { talentsProfileJobSuggestionsPath } from "@/routes"

function fetchJobs(ids: number[]) {
  loaded.value = false
  currentPage.value = 1
  const url = talentsProfileJobSuggestionsPath({ seen_ids: ids })
  fetch(url)
    .then(res => res.json())
    .then(res => {
      jobSuggestions.value = res.suggestions
      last.value = res.last
      loaded.value = true
    })
}

function nextPage() {
  if (currentPage.value >= totalPages.value) return

  currentPage.value++
  const top = document.getElementById('top')
  if (top) top.scrollIntoView({ behavior: 'smooth' })
}

function prevPage() {
  if (currentPage.value <= 1) return

  currentPage.value--
  const top = document.getElementById('top')
  if (top) top.scrollIntoView({ behavior: 'smooth' })
}

function loadNewQueue() {
  if (last.value) return

  const seenIds  = jobSuggestions.value.map((job: JobSuggestion) => job.id)
  fetchJobs(seenIds)
}

onMounted(() => {
  fetchJobs([])
})

const [parent] = useAutoAnimate({})

</script>

<style scoped>
.job-board__row {
  min-height: 40rem;
}
</style>


