<template lang="pug">
.card.profile-card(:class="{'border-0': preview}")
  .card-body(v-if="form" :class="{'p-0': preview}")
    .row
      .col-12(v-if="isSubmitting && !preview")
        .loading-progress-bar.mt-2.mb-2
          .loading-progress-bar__progress
      .col-12: .mb-4(name="degree")
        RadioGroup(
          :label="$t('js.components.talent_profile.degrees.label')"
          name="degrees"
          v-model="form.degree"
          :options="options['degrees']"
        )

      .col-12: .mb-4
        label.mb-2(for="certificates" name="certificates")
          | {{ $t('js.components.profile.tables.deine_zertifikate') }}
        table.table.table-striped.table-hover.table-sm(v-if="form.certificates && form.certificates.length > 0")
          thead
            tr
              th.col
                | {{ $t('js.components.profile.tables.zertifikat') }}
              th.col.text-center
                | {{ $t('js.components.profile.tables.niveau') }}

          tbody
            tr(v-for="certificate in form.certificates")
              td
                | {{ certificate.name }}
              td.text-center
                template(v-if="certificate.grade")
                  | {{ certificate.grade }}
                template(v-else)
                  | -
        div(v-else)
          small
            | {{ $t('js.components.profile.tables.du_hast_noch') }}
        .pull-right.mb-2
          span.open-modal(@click="setModalContent('certificates')")
            template(v-if="form.certificates && form.certificates.length > 0")
              | {{ $t('js.components.profile.tables.zertifikat_bearbeiten') }}
              i.fas.fa-edit(style="margin-left: 0.5rem")
            template(v-else)
              | {{ $t('js.components.profile.tables.zertifikat_hinzufuegen') }}
              i.fas.fa-plus(style="margin-left: 0.5rem")
  .card-footer(v-if="!preview" :class='{"card-footer-sticky": formChanged }')
    .d-flex.justify-content-end.gap-2
      button.btn.btn-sm(@click="editing = false" :class="(!dataSent || error) ? 'btn-outline-danger' : 'btn-outline-success'")
        i.fas.fa-times(style="margin-right: 0.5rem")
        span(v-if="!dataSent || error")
          | {{ $t('js.components.talent_profile.generic.abbrechen') }}
        span(v-else)
          | {{ $t('js.components.talent_profile.generic.schliessen') }}
      button.btn.btn-primary.btn-sm(
        :class="{'tilt-shaking': formChanged && !dataSent}"
        :disabled="isSubmitting || !formChanged"
        @click="submitWithToast()"
      )
        i.fas.fa-save(style="margin-right: 0.5rem")
        | {{ $t('js.components.talent_profile.generic.speichern') }}

</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RadioGroup } from "@/elements"
import { useSubmit } from "@/talents/profile/profile"
import { setModalContent } from  "@/talents/profile/contents/modal"
import { getOptionValues } from "@/talents/creation_form/talents_form"
import { useI18n } from "vue-i18n"
import { debounceFilter, watchWithFilter } from '@vueuse/core'
const { t } = useI18n()

const { submit, error, isSubmitting, dataSent, form, formChanged } = useSubmit("education");

const options = {
  degrees: getOptionValues('degrees'),
}

const props = withDefaults(defineProps<{
  modelValue: boolean,
  preview: boolean,
}>(), {
  modelValue: false,
  preview: false,
})

watchWithFilter(
  form,
  () => { if(props.preview) submit(['degree']) },
  { eventFilter: debounceFilter(500), deep: true },
)

const emits = defineEmits(["update:modelValue", "submit:makeToast"])
const submitWithToast = () => {
  submit(['degree'])
  const type = error.value ? "error" : "success"
  emits("submit:makeToast", {
    message: t(`js.components.talent_profile.generic.status.${type}`),
    type: type,
  })
}

const editing = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emits("update:modelValue", value);
  },
});
</script>
