<template>
  <ChecklistItem :done="false" :openIcon='mdiCellphoneArrowDown'>
    {{ $t("js.components.apply.apply_succeed.subscription.title") }}

    <div class='mt-3 d-flex gap-3'>
      <a :href='apple' @click='trackApple'>
        <img :src="appStoreImage" alt=" App installieren" class="img-fluid">
      </a>
      <a :href='google' @click='trackGoogle'>
        <img :src="playImage" alt="App installieren" class="img-fluid">
      </a>
    </div>
  </ChecklistItem>
</template>

<script lang="ts" setup>
import ChecklistItem from "@/elements/ChecklistItem.vue"
import playImage from "asset_images/badges/google-play.svg"
import appStoreImage from "asset_images/badges/app-store.svg"
import ahoy from "@/utils/ahoy"
import { mdiCellphoneArrowDown } from '@mdi/js'

const apple = "https://itunes.apple.com/de/app/empfehlungsbund-jobsuche/id1201282033?mt=8"
const google = "https://play.google.com/store/apps/details?id=de.pludoni.empfehlungsbundmobile&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"

defineProps<{
  application: StoredApplicationV2
}>()
const trackApple = () => {
  ahoy.track("app_store/apple")
}
const trackGoogle = () => {
  ahoy.track("app_store/google")
}
</script>

<style scoped>
img {
  height: 45px;
}
</style>
