<template lang="pug">
div(v-if="!init")
  radio-group(
    stacked
    name="remote"
    v-model="proxyWorkLocationState"
    :options="options"
    style="font-size: 1.2rem;"
    @change="handleChoices()"
  )
  transition(name="fade")
    SelectAutoComplete.mt-2(
      v-if="proxyWorkLocationState === 'onsite' || proxyWorkLocationState === 'onsite_with_radius'"
      v-model="selectedLocations"
      :placeholder="$t('js.components.talent_profile.work_location.search')"
      :options="locationOptions"
      :allowCustom="true"
      :allowOneOption="proxyWorkLocationState === 'onsite_with_radius'"
      :useTextOnly="true"
    )
      template(v-slot:selected)
        .mt-2.d-flex.justify-content-start.flex-wrap.gap-2
          .d-flex.align-items-center.custom-badge(v-for="loc in selectedLocations")
            button.btn-close.btn-close-white(aria-label="remove" @click="remove(loc)" style="margin-right: 10px;")
            span.job
              | {{ loc }}
        .mt-2.form-group.m-auto(v-if="proxyWorkLocationState === 'onsite_with_radius'")
          .mb-1
            | {{ $t('js.components.talent_profile.work_location.radius') }}:
            b(style="margin-left: 0.25rem") {{ proxyRadius }} km
          input.search_radius(name="radius" type="range" v-model="proxyRadius" min="0" max="100" step="5" class="form-range")

  .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="showError")
    | {{ $t('js.components.talent_profile.errors.work_locations') }}

</template>

<script lang="ts" setup>
import { computed, ref, onMounted, watch } from 'vue'
import { RadioGroup, SelectAutoComplete } from "@/elements"
import { talentsWorkLocationsPath } from "@/routes"
import { useI18n } from "vue-i18n"

const t = useI18n().t
const locationOptions = ref<TalentFormOption[]>([])
const selectedLocations = ref<string[]>([])
const init = ref<boolean>(true)

const options = computed(() => ([
  { text: t('js.components.talent_profile.work_location.options.willing_to_move'), value: "any" },
  { text: t('js.components.talent_profile.work_location.options.only_remote'), value: "remote" },
  { text: t('js.components.talent_profile.work_location.options.onsite'), value: "onsite" },
  { text: t('js.components.talent_profile.work_location.options.onsite_with_radius'), value: "onsite_with_radius" }
]))

const emits = defineEmits(['update:modelValue', 'update:radius', 'update:work_location_state'])

const props = defineProps<{
  modelValue: WorkLocation[]
  work_location_state: WorkLocationState | null
  radius: number | null
  showError: boolean
}>();

const proxyRadius = computed({
  get: () => props.radius,
  set: (val) => emits('update:radius', val)
})

const proxyWorkLocationState = computed({
  get: () => props.work_location_state,
  set: (val) => emits('update:work_location_state', val)
})

watch(proxyRadius, (val) => {
  const curVal = props.modelValue[0]
  if (curVal) {
    // @ts-ignore
    curVal.radius = val
    emits('update:modelValue', [curVal])
  }
})

watch(selectedLocations, (val) => {
  if (init.value) return

  const proxyVal = val.map((v: string) => {
    if (proxyWorkLocationState.value === 'onsite_with_radius') {
      return { city: v, radius: proxyRadius.value }
    }

    const loc = props.modelValue.find((loc: WorkLocation) => loc.city === v)
    if (loc) return loc

    return { city: v }
  })
  emits('update:modelValue', proxyVal)
})

const fetchLocations = async () => {
  const path = talentsWorkLocationsPath()
  fetch(path)
    .then((response: any) => response.json())
    .then((data: any) => {
      locationOptions.value = data
    })
}

const remove = (loc: string) => {
  selectedLocations.value = selectedLocations.value.filter((l: string) => l !== loc)
}

function handleChoices() {
  proxyRadius.value = proxyWorkLocationState.value == 'onsite_with_radius' ? 30 : null
  selectedLocations.value = []
}

onMounted(() => {
  fetchLocations()
  selectedLocations.value = props.modelValue.map((loc: WorkLocation) => loc.city)
  init.value = false
})
</script>


<style scoped>
.custom-badge{
  background-color: #103640;
  color: #fff;
}
</style>
