<template lang="pug">
Modal.text-dark(
  v-model="proxyValue"
  id="response-modal"
  :title="$t('js.components.talent_profile.response_modal.error_title')"
  :hideFooter="true"
  size="lg"
)
  .p-3(v-if="props.error != null")
    .text-center
      i.fas.fa-exclamation-triangle.fa-5x.mt-3.mb-5.text-danger
      h5
        | {{ $t("js.components.talent_profile.response_modal.error_text") }}
      small.text-muted
        | {{ props.error }}
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Modal } from "@/elements"

const props = defineProps<{
  modelValue: boolean,
  error: string | null,
}>()

const emit = defineEmits(["update:modelValue"])

const proxyValue = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit("update:modelValue", value),
})

</script>
