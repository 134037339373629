<template lang="pug">
Modal.text-dark(
  v-model="modalContent.show"
  id="modal-wrapper"
  :title="modalTitle"
  :hideFooter="true"
  size="lg"
)
  template(v-if="componentLoaderInit && modalContent.show")
    component(:is="componentLoaderInit")
  .loading-progress-bar.mt-2.mb-2(v-else)
    .loading-progress-bar__progress

</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'
import { Modal } from "@/elements"
import { modalContent, contents } from "@/talents/profile/contents/modal"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

const currentContent = computed(() => {
  const content = contents.find((content: Content) => content.name === modalContent.value.content_name)
  return content
})

const modalTitle = computed(() => {
  if (currentContent.value) {
    return t(currentContent.value.title)
  }
  return ''
})

const componentLoader = () => {
  if (!currentContent.value) return

  const component = defineAsyncComponent({
    loader: () => /* @vite-ignore */ import(`@/talents/profile/contents/${currentContent.value?.component}.vue`),
  });
  return component;
}

const componentLoaderInit = computed(() => {
  return componentLoader()
})

</script>

<style scoped>

</style>

