import { Controller } from "@hotwired/stimulus"

import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

export default class extends Controller {
  static values = { options: Array }
  declare optionsValue: string[]
  declare instance: Tagify
  connect() {
    this.instance = new Tagify(this.element, {
      whitelist: this.optionsValue,
      originalInputValueFormat(valuesArr) { return valuesArr.map(item => item.value).join(',') },
      dropdown: {
        enabled: 0,
        highlightFirst: true
      }
    })
  }
}
