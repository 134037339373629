<template lang='pug'>
div
  .container(v-if='showAlert'): div.alert.alert-warning.my-5
    h4
      |{{ $t("js.components.non_german_ip_interstitial.notice.title")}}
    p.fs-5(v-html='$t("js.components.non_german_ip_interstitial.notice.intro")')

    div.mt-5
      a.btn.btn-dark.btn-lg(href='https://www.jobs4international.de', target='_blank')
        |{{ $t("js.components.non_german_ip_interstitial.notice.button")}}

  Modal(v-model='isOpen' :static='true' id='askCountryModal' size='lg' :hideFooter='true')
    template(v-slot:header)
      h5.modal-title
        |
      .language-toggle.d-none.d-md-flex: .btn-group(data-turbo='false')
        a.btn.btn-sm.btn-outline-secondary.language-selector-flag(:href='localeUrlDe' :class='{"btn-secondary": locale == "de"}' title='Auf Deutsch')
          img(:src='deSvg' alt='Deutsch' style='max-height: 2rem')
        a.btn.btn-sm.btn-outline-secondary.language-selector-flag(:href='localeUrlEn' :class='{"btn-secondary": locale == "en"}' title='Switch to English')
          img(:src='enSvg' alt='English' style='max-height: 2rem')
    p.fs-4(class='font-bold')
      | {{ $t("js.components.non_german_ip_interstitial.modal_intro")}}

    form.d-flex.gap-3.flex-column.fs-5.my-3
      radio-group(v-model='answers.country' :options='countryOptions' name='current_country' :label='true')
      radio-group(v-model='answers.workPermit' :options='workPermitOptions' name='current_work_permit' :label='true')


</template>

<script lang='ts' setup>
import { ref, computed, watch, onMounted } from 'vue'
import { Modal, RadioGroup } from "@/elements"
import { useSessionStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

import ahoy from '@/utils/ahoy'

import deSvg from 'asset_images/de-100.png'
import enSvg from 'asset_images/en-100.png'

const { t, locale } = useI18n()

const props = defineProps<{
  country: 'germany' | 'eu' | 'international',
  workPermit: 'germany_eu' | 'none',
  localeUrlDe: string,
  localeUrlEn: string
}>()


const answers = useSessionStorage('outsideip-answers', {
  country: null,
  workPermit: null
})

const showAlert = computed(() => {
  const { country, workPermit } = answers.value
  if (!country || !workPermit) {
    return false
  }
  // props.country is germany, but answers.country is not germany
  // props.country is eu, but answers.country is international
  // props.workPermit is germany_eu but answers.workPermit is none
  return (props.country == 'germany' && country != 'germany') ||
    (props.country == 'eu' && country == 'international') ||
    (props.workPermit == 'germany_eu' && workPermit == 'none')
})

const isOpen = ref(answers.value.country == null || answers.value.workPermit == null)
let tracked = false
watch(answers, (newAnswers) => {
  if (newAnswers.country && newAnswers.workPermit) {
    if (!tracked) {
      ahoy.track("NonGermanIpInterstitial/Answer", { country: newAnswers.country, workPermit: newAnswers.workPermit })
      tracked = true
    }
    isOpen.value = false
  }
}, { deep: true })

const countryOptions = computed(() => [
  { value: 'germany', text: t('js.components.non_german_ip_interstitial.country_options.germany') },
  { value: 'eu', text: t('js.components.non_german_ip_interstitial.country_options.eu') },
  { value: 'international', text: t('js.components.non_german_ip_interstitial.country_options.international') },
])
const workPermitOptions = computed(() => [
  { value: 'germany_eu', text: t('js.components.non_german_ip_interstitial.work_permit_options.germany_eu') },
  { value: 'none', text: t('js.components.non_german_ip_interstitial.work_permit_options.none') },
])
onMounted(() => {
  if (isOpen.value) {
    ahoy.track("NonGermanIpInterstitial/Show")
  }
})

</script>

<style scoped>
</style>
