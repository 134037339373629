<template lang="pug">
Modal.text-dark(
  v-model="modelValue"
  :title="$t(titleKey)"
  :hideFooter="true"
  @update:modelValue="emits('update:modelValue', $event)"
  size="md"
)
  .p-2
    slot(name="confirm_content")
    .d-flex.justify-content-between.gap-3
      button.btn.btn-outline-dark.w-100(
        type="button"
        @click="cancel()"
      )
        | {{ $t("js.components.talent_profile.answers.no") }}
      button.btn.btn-danger.w-100(
        type="button"
        @click="confirm()"
      )
        | {{ $t("js.components.talent_profile.answers.yes") }}

</template>

<script setup lang="ts">
import { Modal } from "@/elements"

defineProps<{
  modelValue: boolean
  titleKey: string
}>()

const emits = defineEmits(["confirm", "update:modelValue"])

function cancel() {
  emits('update:modelValue', false)
}

function confirm() {
  emits('confirm')
  emits('update:modelValue', false)
}
</script>
