<template lang="pug">
.input-group.input-group-lg
  input.form-control(
    type="text"
    name="salary"
    ref="inputRef"
    :placeholder="$t('js.components.talent_profile.placeholders.salary')"
  )

</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { debounceFilter, watchWithFilter } from "@vueuse/core"

export default {
  name: 'DebouncedCurrencyInput',
  props: {
    modelValue: Number, // Vue 2: value
    options: Object
  },
  setup (props, { emit }) {
    const { inputRef, numberValue } = useCurrencyInput(props.options, false)

    watchWithFilter(numberValue, (value) => emit('update:modelValue', value), { eventFilter: debounceFilter(1000) })

    return { inputRef }
  }
}
</script>
