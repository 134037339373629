import { computed, ref } from "vue"

import { feedbackAnswered, state } from "@/components/apply/history"

import * as Routes from "@/routes"

const useFeedback = (application: any) => {
  const feedbackDone = computed(() => {
    return state.value.feedbackAnswered
  })
  const showJobboersencheck = computed(() => {
    return state.value.feedbackGood && window.Locale == 'de'
  })

  const feedbackModalOpen = ref(false)
  const onFeedbackAnswered = () => {
    feedbackAnswered()
    feedbackModalOpen.value = false
  }

  const ratingUsability = ref(null as number | null)
  const ratingJobOffers = ref(null as number | null)

  const rateUsability = (stars: number) => {
    ratingUsability.value = stars
    checkFinished()
  }
  const rateJobOffers = (stars: number) => {
    ratingJobOffers.value = stars
    checkFinished()
  }

  function checkFinished() {
    const usability = ratingUsability.value
    const jobOffers = ratingJobOffers.value

    if (usability && jobOffers) {
      if (usability >= 4 && jobOffers >= 4) {
        createStaticFeedback(usability, jobOffers)
        feedbackAnswered(true)
      } else {
        feedbackModalOpen.value = true
      }
    }
  }
  const createStaticFeedback = (usability: number, jobOffers: number) => {
    const body = {
      feedback: {
        satisfaction_with_usability: usability - 1,
        satisfaction_with_jobs: jobOffers - 1,
        recommend: true,
        would_use_again: true,
        comment: "",
      },
      application: application
    }
    fetch(Routes.jobApplicationsFeedbackPath(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
  }

  return {
    feedbackDone,
    showJobboersencheck,
    onFeedbackAnswered,
    rateUsability,
    rateJobOffers,
    ratingUsability,
    ratingJobOffers,
    feedbackModalOpen,
  }
}
export default useFeedback
