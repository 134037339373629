import BaseController from "./base_controller"

import { medium } from './themes'

// Jobs & Applications Chart oben auf Mediadatenseite
export default class extends BaseController {
  chartOptions() {
    const options: Highcharts.Options = this.jsonChartOptions()
    if (options.xAxis && !("length" in options.xAxis)) {
      options.xAxis.labels ||= {}
      options.xAxis.labels.formatter = function () {
        if (this.isLast) {
          return "<span style='color: #206C80;'><strong>*</strong></span>" + this.value
        } else {
          return this.value.toString()
        }
      }
    }
    medium(options)

    return options
  }
}
