<template lang="pug">
.card.profile-card
  .card-body(v-if="form")
    .row
      .col-12(v-if="isSubmitting")
        .loading-progress-bar.mt-2.mb-2
          .loading-progress-bar__progress
      .col-12: .mb-4
        label.mb-2(for="blocked-companies")
          | {{ $t('js.components.profile.labels.blockierte_unternehmen') }}
        SelectAutoComplete(
          id="#company_blocklist"
          name="company_blocklist"
          v-model="form.company_blocklist"
          placeholder="Firmenname suchen"
          :options="companyOptions"
          :allowCustom="true"
          :useTextOnly="true"
        )
          template(v-slot:selected)
            template(v-if="form.company_blocklist && form.company_blocklist.length > 0")
              table.table.table-striped.table-sm.table-hover.mt-3
                thead
                  tr
                    th.col.text-sm
                      | {{ $t('js.components.profile.tables.firmenname') }}
                    th.col.text-center.text-sm
                      |
                tbody
                  tr(v-for="company in blockedList")
                    td.col.text-sm
                      | {{ company }}
                    td.col.text-center.text-sm
                      button.btn.btn-sm.btn-outline-danger(@click="removeCompany(company)")
                        i.fas.fa-trash-alt
            .mt-1(v-else)
              small
                | {{ $t('js.components.profile.tables.kein_unternehmen') }}

  .card-footer(:class='{"card-footer-sticky": formChanged }')
    .d-flex.justify-content-end.gap-2
      button.btn.btn-sm(@click="editing = false" :class="!dataSent ? 'btn-outline-danger' : 'btn-outline-success'" id="close_companyblocklist")
        i.fas.fa-times(style="margin-right: 0.5rem")
        span(v-if="!dataSent")
          | {{ $t('js.components.talent_profile.generic.abbrechen') }}
        span(v-else)
          | {{ $t('js.components.talent_profile.generic.schliessen') }}
      button.btn.btn-primary.btn-sm(
        id="submit_company_blocklist"
        :class="{'tilt-shaking': formChanged && !dataSent}"
        :disabled="isSubmitting || !formChanged"
        @click="submitWithToast()"
      )
        i.fas.fa-save(style="margin-right: 0.5rem")
        | {{ $t('js.components.talent_profile.generic.speichern') }}
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useSubmit } from "@/talents/profile/profile"
import { talentsCompanyListPath } from "@/routes"
import { SelectAutoComplete } from "@/elements"

const { submit, error, isSubmitting, dataSent, form, formChanged } = useSubmit("profile");

interface FormOption {
  value: string;
  text: string;
}

const companyOptions = ref<FormOption[]>([]);

const props = defineProps<{
  modelValue: boolean
}>();

const blockedList = computed(() => {
  return form.value.company_blocklist;
});

function removeCompany(value: string) {
  form.value.company_blocklist = form.value.company_blocklist.filter((id: string) => id !== value);
}

import { useI18n } from "vue-i18n"
const { t } = useI18n()
const emits = defineEmits(["update:modelValue", "submit:makeToast"])
const submitWithToast = () => {
  submit(['company_blocklist'])
  const type = error.value ? "error" : "success"
  emits("submit:makeToast", {
    message: t(`js.components.talent_profile.generic.status.${type}`),
    type: type,
  })
}

const editing = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emits("update:modelValue", value);
  },
});

onMounted(() => {
  const path = talentsCompanyListPath();
  fetch(path)
    .then(response => response.json())
    .then(data => {
      companyOptions.value = data;
    });
})
</script>

<style scoped>

</style>
