<template lang="pug">
.card.profile-card
  .card-body
    .d-flex.gap-4
      .card-icon
        i.fas(:class="icon")
      .card-content.w-100(style="min-height: 46px")
        .d-flex.justify-content-between.align-items-baseline
          .profile-card-title
            | {{ title }}
          small.text-muted(v-if="showProgress")
            | {{ progressText }}
        .card-overview(v-if="showProgress")
          .progress
            .progress-bar(
              role="progressbar"
              :style="{width: progress + '%'}"
              :class="bgClass"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            )

    hr

    .d-flex.justify-content-between.gap-3.mt-2.align-items-end
      .card-hint(v-html="hint")
      .card-actions.pull-right
        slot(name="toggle-actions")

    slot(name="actions")
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from "vue-i18n"
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    title: string
    icon: string
    progress?: number
    showProgress?: boolean
    hint: string
  }>(),
  {
    progress: 0,
    showProgress: true,
  }
)

const progressText = computed(() => {
  return props.progress + "% " + t(`js.components.progress.completed`)
})

const bgClass = computed(() => {
  if (props.progress < 50) {
    return 'bg-warning'
  } else {
    return 'bg-success'
  }
})

</script>


<style scoped>

.card-icon {
  width: 2rem;
  font-size: 2.5rem;
  color: #8a8d90;
  position: relative;
}

.card-icon i {
  position: absolute;
  bottom: 0;
}

.card-hint {
  font-size: 0.9rem;
}

</style>
