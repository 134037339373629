const BOLD_REGEX = /\*\*(.*?)\*\*/g
const ITALIC_REGEX = /\*(.*?)\*/g

function parseMessage(message: string) {
  const boldContent = message.replace(BOLD_REGEX, "<strong>$1</strong>")
  const italicContent = boldContent.replace(ITALIC_REGEX, "<em>$1</em>")

  return italicContent
}

function pureMessage(message: string) {
  const boldContent = message.replace(BOLD_REGEX, "$1")
  const italicContent = boldContent.replace(ITALIC_REGEX, "$1")

  return italicContent
}

export { parseMessage, pureMessage }
