export default (filesize: number) => {
  const units = ["byte", "kilobyte", "megabyte", "gigabyte"]
  let i = 0
  while (filesize >= 1024) {
    filesize /= 1024
    i++
  }
  const formatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2, unit: units[i], style: "unit" })
  return formatter.format(Math.round(filesize * 100) / 100)
}
