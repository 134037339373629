export const preprocessNodesAdmin = (links: Empfehlungsgraph.Link[]) => {
  let minLinkCount = 0
  let maxLinkCount = 0
  let minNodeCount = 0
  let maxNodeCount = 0
  const nodes: Empfehlungsgraph.Nodelist = {}

  links.forEach((link) => {
    link.count = +link.count

    if (nodes.hasOwnProperty(link.from)) {
      nodes[link.from].count = nodes[link.from].count + +link.count
    } else {
      nodes[link.from] = {
        id: link.from,
        logo: link.from_logo,
        count: +link.count,
      }
    }
    link.source = nodes[link.from]

    if (nodes.hasOwnProperty(link.to)) {
      nodes[link.to].count = nodes[link.to].count + +link.count
    } else {
      nodes[link.to] = {
        id: link.to,
        logo: link.to_logo,
        count: +link.count,
      }
    }
    link.target = nodes[link.to]

    maxLinkCount = Math.max(maxLinkCount, +link.count)
    minLinkCount = Math.min(minLinkCount, +link.count)

    maxNodeCount = Math.max(maxNodeCount, nodes[link.from].count)
    minNodeCount = Math.min(minNodeCount, nodes[link.from].count)

    maxNodeCount = Math.max(maxNodeCount, nodes[link.to].count)
    minNodeCount = Math.min(minNodeCount, nodes[link.to].count)
  })

  return { nodes, maxLinkCount, minLinkCount, maxNodeCount, minNodeCount }
}

export const preprocessNodes = (links: Empfehlungsgraph.Link[], userId: number) => {
  let minLinkCount = 0
  let maxLinkCount = 0
  let minNodeCount = 0
  let maxNodeCount = 0
  const nodes: Empfehlungsgraph.Nodelist = {}
  // compute the distinct nodes from the links
  links.forEach((link) => {
    link.count = +link.count

    if (nodes.hasOwnProperty(link.from) && nodes[link.from]) {
      nodes[link.from].count += +link.count
      if (link.to == userId) {
        nodes[link.from].incoming = true
      }
    } else {
      nodes[link.from] = {
        id: link.from,
        logo: link.from_logo,
        count: +link.count,
        incoming: link.to == userId,
        outgoing: false,
      }
    }
    link.source = nodes[link.from]

    if (nodes.hasOwnProperty(link.to)) {
      nodes[link.to].count += +link.count
      if (link.from == userId) nodes[link.to].outgoing = true
    } else {
      nodes[link.to] = {
        id: link.to,
        logo: link.to_logo,
        count: +link.count,
        incoming: false,
        outgoing: link.from == userId,
      }
    }
    link.target = nodes[link.to]

    maxLinkCount = Math.max(maxLinkCount, +link.count)
    minLinkCount = Math.min(minLinkCount, +link.count)

    if (link.from != userId) {
      maxNodeCount = Math.max(maxNodeCount, nodes[link.from].count)
      minNodeCount = Math.min(minNodeCount, nodes[link.from].count)
    }

    if (link.to != userId) {
      maxNodeCount = Math.max(maxNodeCount, nodes[link.to].count)
      minNodeCount = Math.min(minNodeCount, nodes[link.to].count)
    }
  })

  // always show center node
  nodes[userId].incoming = true
  nodes[userId].outgoing = true
  return { nodes, maxLinkCount, minLinkCount, maxNodeCount, minNodeCount }
}
