<template>
  <div style="min-height: 500px" class="empfehlungsgraph card neumorphism-on-white">
    <div class="svgContainer" ref="container">
      <div v-if="!graph">{{ $t('js.components.empfehlungsgraph.empfehlungsaktivitat_wird') }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { recommendationGraph } from "~/utils/recommendationGraph"

type GraphResponse = {
  links: Empfehlungsgraph.Link[],
  users: {
    id: string
    name: string
    username: string
  }[]
}

const graph = ref<GraphResponse>()
const container = ref<HTMLElement>()
let simulation = null

const start = () => {
  const width = container.value?.clientWidth || window.innerWidth
  const height = width / 16 * 9
  const options = {
    minStrokeWidth: 0.3,
    maxStrokeWidth: 5.0,
    forceDistanceMax: 750,
    forceStrength: -3000,
    minScale: 0.244140625,
    maxScale: 1.8,
    width: width + 150,
    height: height + 150,
    defaultZoom: 0.5,
    maxNodeSize: 60,
    minNodeSize: 45,
    colorIncoming: "#999",
    colorOutgoing: "#999",
    disablePanning: true,
  }
  if (graph.value && container.value) {
    simulation = recommendationGraph(container.value, graph.value.links, options)
    //simulation.force("link")
  }
}

onMounted(() => {
  fetch("/arbeitgeber/api/empfehlungsgraph")
    .then((r) => r.json())
    .then((data) => {
      graph.value = data
      start()
    })
})
</script>

<style lang="scss" scoped>
.empfehlungsgraph :deep(svg) {
  height: auto;
  aspect-ratio: 16/9;
  width: 100%;
}

.empfehlungsgraph :deep(path.link) {
  stroke: #666;
  stroke-width: 1.5px;
  fill: none;
}

.empfehlungsgraph :deep(circle) {
  stroke: #c0c0c0;
  stroke-width: 1px;
  fill: #fff;
}

.empfehlungsgraph :deep(svg) {
  .link {
    stroke: #ccc;
  }
  .node text {
    pointer-events: none;
    font: 10px sans-serif;
  }
  .links line {
    stroke: #999;
    stroke-opacity: 0.6;
  }

  .nodes circle {
    stroke: #aaa;
    stroke-width: 1.5px;
  }
}
</style>
