<template>
  <ChecklistItem :done="feedbackDone" v-if="!feedbackDone">
    <div class="flex-grow-1">
      <div>
        {{ $t("js.components.apply_card.feedback") }}
      </div>
    </div>
    <div class='d-flex gap-3 flex-column mt-3'>
      <div class='d-flex flex-column gap-1'>
        <label>
          {{ $t("js.form.properties.satisfaction_with_usability") }}
        </label>
        <StarRating @rate="rateUsability" />
      </div>
      <div class='d-flex flex-column gap-1'>
        <label>
          {{ $t("js.form.properties.satisfaction_with_jobs") }}
        </label>
        <StarRating @rate="rateJobOffers" />
      </div>
    </div>
  </ChecklistItem>
  <ChecklistItem :done="feedbackDone" v-if="feedbackDone">
    <div class="flex-grow-1">
      <div>
        {{ $t("js.components.apply_card.feedback_success") }}
      </div>
    </div>
    <div v-if='showJobboersencheck'>
      <p class='fs-6'>Vielen Dank für Ihr wertvolles Feedback</p>
      <p>Wir freuen uns sehr, wenn Sie Ihre Erfahrung auch auf der unabhängigen Bewertungsplattform Jobbörsencheck teilen!
        Damit helfen Sie Arbeitgebern eine fundierte Meinung über Empfehlungsbund und seine Arbeitgebercommunitys
        einzuholen. Vielen Dank für Ihre Unterstützung!</p>

      <div class='text-center d-flex flex-column gap-2'>
        <a href="https://jobboersencheck.de/fragebogen/bewerber/basisdaten?jm=159" target="_blank"
          class="btn btn-primary btn-lg">Zu Jobbörsencheck</a>
      </div>
    </div>
  </ChecklistItem>
  <FeedbackModal v-model="feedbackModalOpen" :application="application" @answer="onFeedbackAnswered"
    :initialFeedback="{ satisfaction_with_usability: ratingUsability, satisfaction_with_jobs: ratingJobOffers }" />
</template>

<script lang="ts" setup>
import ChecklistItem from "@/elements/ChecklistItem.vue"
import FeedbackModal from "./FeedbackModal.vue"
import StarRating from "./StarRating.vue"

const props = defineProps<{
  application: StoredApplicationV2
}>()

import useFeedback from "./feedback"

const {
  showJobboersencheck,
  onFeedbackAnswered,
  feedbackDone,
  feedbackModalOpen,
  ratingUsability,
  ratingJobOffers,
  rateUsability,
  rateJobOffers
} = useFeedback(props.application)

</script>

<style scoped></style>
