<template>
  <div>
    <Alert
      class="my-3"
      variant="info"
      :title="$t('js.components.apply.apply_form.bewerbung_unter_folgendem')"
      v-if="isExternal && application.redirect_url">
      <p>
        {{ $t("js.components.apply.apply_form.die_firma_nutzt_ein_bewerb") }}
      </p>
      <div>
        <Button :href="application.redirect_url" variant="primary" size="lg" class="mt-2" target="_blank">
          {{ $t("js.components.apply.apply_form.bewerbung_vervollstandigen") }}
        </Button>
      </div>
    </Alert>
    <Alert variant="success" :title="title">
      <template v-slot:bottom>
        <p>
          {{ $t("js.components.apply_card.next_steps") }}
        </p>
        <ul class="list-unstyled">
          <KanaleoItem :application="application" />
          <FeedbackItem :application="application" />
          <CalendarItem :application='application'/>
          <JobSubscriptionItem :application="application" :job="job" v-if='job'/>
          <AppItem :application="application" v-if='isGerman'/>

          <CompanyFinderItem :application="application" v-if='job' :job="job"/>
        </ul>
      </template>
    </Alert>
  </div>
</template>

<script lang="ts" setup>
import { Button, Alert } from "@/elements"
import CalendarItem from './succeed/CalendarItem.vue'
import FeedbackItem from './succeed/FeedbackItem.vue'
import KanaleoItem from './succeed/KanaleoItem.vue'
import AppItem from './succeed/AppItem.vue'
import JobSubscriptionItem from './succeed/JobSubscriptionItem.vue'
import CompanyFinderItem from './succeed/CompanyFinderItem.vue'

defineProps<{
  isExternal?: boolean
  title: string
  job?: JobPayload | (frontend.Job & { company_name: string })
  application: StoredApplicationV2
}>()

const isGerman = window.Locale == 'de'

</script>
