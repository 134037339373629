/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/view_components(.:format) */
export function previewViewComponentsPath(options?: object): string {
  return "/" + "rails" + "/" + "view_components" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/view_components/*path(.:format) */
export function previewViewComponentPath(path: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "view_components" + "/" + path.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["path","format"]);
}

/** /impressum(.:format) */
export function impressumPath(options?: object): string {
  return "/" + "impressum" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mediadaten(.:format) */
export function mediadatenPath(options?: object): string {
  return "/" + "mediadaten" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /reichweite(.:format) */
export function reichweitePath(options?: object): string {
  return "/" + "reichweite" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /agb(.:format) */
export function agbPath(options?: object): string {
  return "/" + "agb" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /datenschutz(.:format) */
export function datenschutzPath(options?: object): string {
  return "/" + "datenschutz" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /datenschutz_app(.:format) */
export function datenschutzAppPath(options?: object): string {
  return "/" + "datenschutz_app" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /partnerstimmen(.:format) */
export function partnerstimmenPath(options?: object): string {
  return "/" + "partnerstimmen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /faq(.:format) */
export function faqPath(options?: object): string {
  return "/" + "faq" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kooperationen(.:format) */
export function kooperationenPath(options?: object): string {
  return "/" + "kooperationen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mediadaten/more(.:format) */
export function mediadatenMorePath(options?: object): string {
  return "/" + "mediadaten" + "/" + "more" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /integration(.:format) */
export function integrationPath(options?: object): string {
  return "/" + "integration" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /integrationen(.:format) */
export function integrationenPath(options?: object): string {
  return "/" + "integrationen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /online-meeting(.:format) */
export function onlineMeetingPath(options?: object): string {
  return "/" + "online-meeting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /geschichte-des-empfehlungsbundes(.:format) */
export function historyPath(options?: object): string {
  return "/" + "geschichte-des-empfehlungsbundes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /studien(.:format) */
export function studiesPath(options?: object): string {
  return "/" + "studien" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /studien/new(.:format) */
export function newStudyPath(options?: object): string {
  return "/" + "studien" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /studien/:id/edit(.:format) */
export function editStudyPath(id: ScalarType, options?: object): string {
  return "/" + "studien" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /studien/:id(.:format) */
export function studyPath(id: ScalarType, options?: object): string {
  return "/" + "studien" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /studien/:study_id/anfordern(.:format) */
export function requestStudyPath(study_id: ScalarType, options?: object): string {
  return "/" + "studien" + "/" + study_id + "/" + "anfordern" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["study_id","format"]);
}

/** /api */
export function apiPath(options?: object): string {
  return "/" + "api" + $buildOptions(options, []);
}

/** /pages/impressum(.:format) */
export function pagesImpressumPath(options?: object): string {
  return "/" + "pages" + "/" + "impressum" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /pages/datenschutzerklarung(.:format) */
export function pagesDatenschutzerklarungPath(options?: object): string {
  return "/" + "pages" + "/" + "datenschutzerklarung" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /pages/kooperationen(.:format) */
export function pagesKooperationenPath(options?: object): string {
  return "/" + "pages" + "/" + "kooperationen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /newsletter(.:format) */
export function newsletterPath(options?: object): string {
  return "/" + "newsletter" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mailchimp_import/subscribe(.:format) */
export function mailchimpImportSubscribePath(options?: object): string {
  return "/" + "mailchimp_import" + "/" + "subscribe" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/reminder(.:format) */
export function apiReminderPath(options?: object): string {
  return "/" + "api" + "/" + "reminder" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/apply(.:format) */
export function statisticsApplyPath(options?: object): string {
  return "/" + "statistics" + "/" + "apply" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /validator(.:format) */
export function validatorPath(options?: object): string {
  return "/" + "validator" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitsagentur(.:format) */
export function arbeitsagenturPath(options?: object): string {
  return "/" + "arbeitsagentur" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /login(.:format) */
export function loginPath(options?: object): string {
  return "/" + "login" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /partner_events(.:format) */
export function partnerEventsPath(options?: object): string {
  return "/" + "partner_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /ihkw(.:format) */
export function ihkwPath(options?: object): string {
  return "/" + "ihkw" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents(.:format) */
export function talentsPath(options?: object): string {
  return "/" + "talents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/creation(.:format) */
export function talentsCreationPath(options?: object): string {
  return "/" + "talents" + "/" + "creation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/login(.:format) */
export function talentsLoginPath(options?: object): string {
  return "/" + "talents" + "/" + "login" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/login/:token(.:format) */
export function talentsLoginWithTokenPath(token: ScalarType, options?: object): string {
  return "/" + "talents" + "/" + "login" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /talents/logout(.:format) */
export function talentsLogoutPath(options?: object): string {
  return "/" + "talents" + "/" + "logout" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile(.:format) */
export function talentsProfilePath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/data(.:format) */
export function talentsProfileDataPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "data" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/job_subscriptions(.:format) */
export function talentsProfileJobSubscriptionsPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "job_subscriptions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/recommendations(.:format) */
export function talentsProfileRecommendationsPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "recommendations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/recommendation(.:format) */
export function talentsProfileRecommendationPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "recommendation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/links(.:format) */
export function talentsProfileLinksPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "links" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/link(.:format) */
export function talentsProfileLinkPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "link" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/job_suggestions(.:format) */
export function talentsProfileJobSuggestionsPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "job_suggestions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/job_application(.:format) */
export function talentsProfileJobApplicationPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "job_application" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/preview(.:format) */
export function talentsProfilePreviewPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "preview" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/reject_survey(.:format) */
export function talentsProfileRejectSurveyPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "reject_survey" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile_delete(.:format) */
export function talentsProfileDeletePath(options?: object): string {
  return "/" + "talents" + "/" + "profile_delete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/conversations(.:format) */
export function talentsConversationsPath(options?: object): string {
  return "/" + "talents" + "/" + "conversations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/conversation(.:format) */
export function talentsConversationPath(options?: object): string {
  return "/" + "talents" + "/" + "conversation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/conversation/read(.:format) */
export function talentsConversationReadPath(options?: object): string {
  return "/" + "talents" + "/" + "conversation" + "/" + "read" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/conversation/close(.:format) */
export function talentsConversationClosePath(options?: object): string {
  return "/" + "talents" + "/" + "conversation" + "/" + "close" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/conversation/request_to_reopen(.:format) */
export function talentsConversationRequestToReopenPath(options?: object): string {
  return "/" + "talents" + "/" + "conversation" + "/" + "request_to_reopen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/conversation/trigger(.:format) */
export function talentsConversationTriggerPath(options?: object): string {
  return "/" + "talents" + "/" + "conversation" + "/" + "trigger" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/conversation/confirm_hire(.:format) */
export function talentsConversationConfirmHirePath(options?: object): string {
  return "/" + "talents" + "/" + "conversation" + "/" + "confirm_hire" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/conversation/decline_hire(.:format) */
export function talentsConversationDeclineHirePath(options?: object): string {
  return "/" + "talents" + "/" + "conversation" + "/" + "decline_hire" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/application_profile(.:format) */
export function talentsProfileApplicationProfilePath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "application_profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/profile/profile_section(.:format) */
export function talentsProfileProfileSectionPath(options?: object): string {
  return "/" + "talents" + "/" + "profile" + "/" + "profile_section" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/email-abo(.:format) */
export function talentsEmailAboPath(options?: object): string {
  return "/" + "talents" + "/" + "email-abo" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/api/find_competences(.:format) */
export function talentsFindCompetencesPath(options?: object): string {
  return "/" + "talents" + "/" + "api" + "/" + "find_competences" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/api/suitable_job_titles(.:format) */
export function talentsSuitableJobTitlesPath(options?: object): string {
  return "/" + "talents" + "/" + "api" + "/" + "suitable_job_titles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/api/work_locations(.:format) */
export function talentsWorkLocationsPath(options?: object): string {
  return "/" + "talents" + "/" + "api" + "/" + "work_locations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/api/company_list(.:format) */
export function talentsCompanyListPath(options?: object): string {
  return "/" + "talents" + "/" + "api" + "/" + "company_list" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /talents/api/suggest_with_ba_jobs(.:format) */
export function talentsSuggestWithBaJobsPath(options?: object): string {
  return "/" + "talents" + "/" + "api" + "/" + "suggest_with_ba_jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /jobs/search(.:format) */
export function jobsSearchPath(options?: object): string {
  return "/" + "jobs" + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /jobs/embed(.:format) */
export function jobsEmbedPath(options?: object): string {
  return "/" + "jobs" + "/" + "embed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /job_applications/check_code(.:format) */
export function jobApplicationsCheckCodePath(options?: object): string {
  return "/" + "job_applications" + "/" + "check_code" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /job_applications/:job_id(.:format) */
export function jobApplicationInfoPath(job_id: ScalarType, options?: object): string {
  return "/" + "job_applications" + "/" + job_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["job_id","format"]);
}

/** /job_applications/:job_id/apply(.:format) */
export function jobApplicationCreatePath(job_id: ScalarType, options?: object): string {
  return "/" + "job_applications" + "/" + job_id + "/" + "apply" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["job_id","format"]);
}

/** /job_applications/feedback(.:format) */
export function jobApplicationsFeedbackPath(options?: object): string {
  return "/" + "job_applications" + "/" + "feedback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /job_applications/:job_id/calendar(.:format) */
export function jobApplicationCalendarPath(job_id: ScalarType, options?: object): string {
  return "/" + "job_applications" + "/" + job_id + "/" + "calendar" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["job_id","format"]);
}

/** /jobs(.:format) */
export function jobsPath(options?: object): string {
  return "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mitgliedschaften(.:format) */
export function mitgliedschaftenPath(options?: object): string {
  return "/" + "mitgliedschaften" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /job/:id(/:slug)(.:format) */
export function jobShowHerePath(id: ScalarType, options?: object): string {
  return "/" + "job" + "/" + id + ($hasPresentOwnProperty(options, "slug") ? "/" + (options as any).slug : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","slug","format"]);
}

/** /jobs/:id(/:slug)(.:format) */
export function jobPath(id: ScalarType, options?: object): string {
  return "/" + "jobs" + "/" + id + ($hasPresentOwnProperty(options, "slug") ? "/" + (options as any).slug : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","slug","format"]);
}

/** /ajax/jobs/similar(.:format) */
export function ajaxJobsSimilarPath(options?: object): string {
  return "/" + "ajax" + "/" + "jobs" + "/" + "similar" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /ajax/jobs/count(.:format) */
export function ajaxJobsCountPath(options?: object): string {
  return "/" + "ajax" + "/" + "jobs" + "/" + "count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber(.:format) */
export function arbeitgeberPath(options?: object): string {
  return "/" + "arbeitgeber" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontakt(.:format) */
export function kontaktPath(options?: object): string {
  return "/" + "kontakt" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/api/event_stream(.:format) */
export function arbeitgeberApiEventStreamPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "api" + "/" + "event_stream" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/api/empfehlungsgraph(.:format) */
export function arbeitgeberApiEmpfehlungsgraphPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "api" + "/" + "empfehlungsgraph" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/api/competences(.:format) */
export function arbeitgeberApiCompetencesPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "api" + "/" + "competences" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/api/community_managers(.:format) */
export function arbeitgeberApiCommunityManagersPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "api" + "/" + "community_managers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/api/kanaleo_public_chart(.:format) */
export function arbeitgeberApiKanaleoPublicChartPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "api" + "/" + "kanaleo_public_chart" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/api/kanaleo_tag_cloud(.:format) */
export function arbeitgeberApiKanaleoTagCloudPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "api" + "/" + "kanaleo_tag_cloud" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/api/kanaleo_heat_map(.:format) */
export function arbeitgeberApiKanaleoHeatMapPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "api" + "/" + "kanaleo_heat_map" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /funktion(.:format) */
export function funktionPath(options?: object): string {
  return "/" + "funktion" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /preise(.:format) */
export function preisePath(options?: object): string {
  return "/" + "preise" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/angebot(.:format) */
export function arbeitgeberAngebotPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "angebot" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgeber/angebot(.:format) */
export function createOfferPdfPath(options?: object): string {
  return "/" + "arbeitgeber" + "/" + "angebot" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /email-job-abo/:id/confirm(.:format) */
export function confirmJobSubscriberPath(id: ScalarType, options?: object): string {
  return "/" + "email-job-abo" + "/" + id + "/" + "confirm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /email-job-abo/:job_subscriber_id/job_subscriptions/:id(.:format) */
export function jobSubscriberJobSubscriptionPath(job_subscriber_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "email-job-abo" + "/" + job_subscriber_id + "/" + "job_subscriptions" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["job_subscriber_id","id","format"]);
}

/** /email-job-abo/:id(.:format) */
export function jobSubscriberPath(id: ScalarType, options?: object): string {
  return "/" + "email-job-abo" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /email-abo(.:format) */
export function emailAboPath(options?: object): string {
  return "/" + "email-abo" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /email-job-abo/jobs/:job_subscription_id/:job_id(.:format) */
export function jobSubscriptionJobTrackingPath(job_subscription_id: ScalarType, job_id: ScalarType, options?: object): string {
  return "/" + "email-job-abo" + "/" + "jobs" + "/" + job_subscription_id + "/" + job_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["job_subscription_id","job_id","format"]);
}

/** /events(.:format) */
export function eventsPath(options?: object): string {
  return "/" + "events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /events/:id(.:format) */
export function eventPath(id: ScalarType, options?: object): string {
  return "/" + "events" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /events/:id/register(.:format) */
export function communityWorkshopRegistrationPath(id: ScalarType, options?: object): string {
  return "/" + "events" + "/" + id + "/" + "register" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /events/:id/register(.:format) */
export function communityWorkshopRegistrationSubmitPath(id: ScalarType, options?: object): string {
  return "/" + "events" + "/" + id + "/" + "register" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /veranstaltungen(.:format) */
export function veranstaltungenPath(options?: object): string {
  return "/" + "veranstaltungen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /ueberuns(.:format) */
export function ueberunsPath(options?: object): string {
  return "/" + "ueberuns" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /styles/domains(.:format) */
export function stylesDomainsPath(options?: object): string {
  return "/" + "styles" + "/" + "domains" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /app(.:format) */
export function appPath(options?: object): string {
  return "/" + "app" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /news(.:format) */
export function newsPath(options?: object): string {
  return "/" + "news" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /changelog(.:format) */
export function changelogPath(options?: object): string {
  return "/" + "changelog" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /news/tag/:tag_name(.:format) */
export function newsTagPath(tag_name: ScalarType, options?: object): string {
  return "/" + "news" + "/" + "tag" + "/" + tag_name + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["tag_name","format"]);
}

/** /news/:id(/:slug)(.:format) */
export function newsShowPath(id: ScalarType, options?: object): string {
  return "/" + "news" + "/" + id + ($hasPresentOwnProperty(options, "slug") ? "/" + (options as any).slug : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","slug","format"]);
}

/** /cms/blog_posts(.:format) */
export function cmsBlogPostsPath(options?: object): string {
  return "/" + "cms" + "/" + "blog_posts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cms/blog_posts/new(.:format) */
export function newCmsBlogPostPath(options?: object): string {
  return "/" + "cms" + "/" + "blog_posts" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cms/blog_posts/:id/edit(.:format) */
export function editCmsBlogPostPath(id: ScalarType, options?: object): string {
  return "/" + "cms" + "/" + "blog_posts" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cms/blog_posts/:id(.:format) */
export function cmsBlogPostPath(id: ScalarType, options?: object): string {
  return "/" + "cms" + "/" + "blog_posts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cms/uploader/image(.:format) */
export function cmsUploaderImagePath(options?: object): string {
  return "/" + "cms" + "/" + "uploader" + "/" + "image" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cms/uploads/:id/:filename(.:format) */
export function cmsUploadPath(id: ScalarType, filename: ScalarType, options?: object): string {
  return "/" + "cms" + "/" + "uploads" + "/" + id + "/" + filename + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","filename","format"]);
}

/** /cms/static_pages(.:format) */
export function cmsStaticPagesPath(options?: object): string {
  return "/" + "cms" + "/" + "static_pages" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cms/static_pages/new(.:format) */
export function newCmsStaticPagePath(options?: object): string {
  return "/" + "cms" + "/" + "static_pages" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cms/static_pages/:id/edit(.:format) */
export function editCmsStaticPagePath(id: ScalarType, options?: object): string {
  return "/" + "cms" + "/" + "static_pages" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cms/static_pages/:id(.:format) */
export function cmsStaticPagePath(id: ScalarType, options?: object): string {
  return "/" + "cms" + "/" + "static_pages" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /code(.:format) */
export function recommendationCodeValidationPath(options?: object): string {
  return "/" + "code" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /code(.:format) */
export function codePath(options?: object): string {
  return "/" + "code" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /wizard(.:format) */
export function wizardPath(options?: object): string {
  return "/" + "wizard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /wizard/location(.:format) */
export function wizardLocationPath(options?: object): string {
  return "/" + "wizard" + "/" + "location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /wizard/competences(.:format) */
export function wizardCompetencesPath(options?: object): string {
  return "/" + "wizard" + "/" + "competences" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /wizard/companies(.:format) */
export function wizardCompaniesPath(options?: object): string {
  return "/" + "wizard" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /wizard/reverse_geocode(.:format) */
export function wizardReverseGeocodePath(options?: object): string {
  return "/" + "wizard" + "/" + "reverse_geocode" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /wizard/api/fairekarriere(.:format) */
export function wizardApiFairekarrierePath(options?: object): string {
  return "/" + "wizard" + "/" + "api" + "/" + "fairekarriere" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /ringvorlesung(.:format) */
export function ringvorlesungPath(options?: object): string {
  return "/" + "ringvorlesung" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /ringvorlesung/:semester_id(.:format) */
export function semesterPath(semester_id: ScalarType, options?: object): string {
  return "/" + "ringvorlesung" + "/" + semester_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["semester_id","format"]);
}

/** /gehaltsbenchmark(.:format) */
export function gehaltsbenchmarkPath(options?: object): string {
  return "/" + "gehaltsbenchmark" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /waiting_list_requests(.:format) */
export function waitingListRequestsPath(options?: object): string {
  return "/" + "waiting_list_requests" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /waiting_list_requests/new(.:format) */
export function newWaitingListRequestPath(options?: object): string {
  return "/" + "waiting_list_requests" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /absageschreiben(.:format) */
export function absageschreibenPath(options?: object): string {
  return "/" + "absageschreiben" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /arbeitgebergemeinschaft(.:format) */
export function arbeitgebergemeinschaftPath(options?: object): string {
  return "/" + "arbeitgebergemeinschaft" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /bewerber(.:format) */
export function bewerberPath(options?: object): string {
  return "/" + "bewerber" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /bewerbermanagementsystem(.:format) */
export function bewerbermanagementsystemPath(options?: object): string {
  return "/" + "bewerbermanagementsystem" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /bewerbung(.:format) */
export function bewerbungPath(options?: object): string {
  return "/" + "bewerbung" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /bewerbungsprozess(.:format) */
export function bewerbungsprozessPath(options?: object): string {
  return "/" + "bewerbungsprozess" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /candidate-experience(.:format) */
export function candidateExperiencePath(options?: object): string {
  return "/" + "candidate-experience" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /recruiting-controlling-definition(.:format) */
export function recruitingControllingDefinitionPath(options?: object): string {
  return "/" + "recruiting-controlling-definition" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kb(.:format) */
export function kbPath(options?: object): string {
  return "/" + "kb" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /wissensdatenbank(.:format) */
export function wissensdatenbankPath(options?: object): string {
  return "/" + "wissensdatenbank" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /aws_ses_sns_engine */
export function cloudMailingPath(options?: object): string {
  return "/" + "aws_ses_sns_engine" + $buildOptions(options, []);
}

/** /aws_ses_sns_engine/sns/sns_endpoint(.:format) */
export function cloudMailingSnsSnsEndpointPath(options?: object): string {
  return "/" + "aws_ses_sns_engine" + "/" + "sns" + "/" + "sns_endpoint" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /aws_ses_sns_engine/mail_deliveries(.:format) */
export function cloudMailingMailDeliveriesPath(options?: object): string {
  return "/" + "aws_ses_sns_engine" + "/" + "mail_deliveries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /aws_ses_sns_engine/mail_deliveries/:id(.:format) */
export function cloudMailingMailDeliveryPath(id: ScalarType, options?: object): string {
  return "/" + "aws_ses_sns_engine" + "/" + "mail_deliveries" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /aws_ses_sns_engine/notifications/:id(.:format) */
export function cloudMailingNotificationPath(id: ScalarType, options?: object): string {
  return "/" + "aws_ses_sns_engine" + "/" + "notifications" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** / */
export function pludoniAadLoginPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** //auth/pludoni(.:format) */
export function pludoniAadLoginAuthPludoniPath(options?: object): string {
  return "/" + "/" + "auth" + "/" + "pludoni" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** //auth/pludoni/callback(.:format) */
export function pludoniAadLoginAuthPludoniCallbackPath(options?: object): string {
  return "/" + "/" + "auth" + "/" + "pludoni" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** //auth/developer/callback(.:format) */
export function pludoniAadLoginAuthDeveloperCallbackPath(options?: object): string {
  return "/" + "/" + "auth" + "/" + "developer" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/pghero */
export function pgHeroPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + $buildOptions(options, []);
}

/** /rails/pghero(/:database)/space(.:format) */
export function pgHeroSpacePath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "space" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/space/:relation(.:format) */
export function pgHeroRelationSpacePath(relation: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "space" + "/" + relation + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","relation","format"]);
}

/** /rails/pghero(/:database)/index_bloat(.:format) */
export function pgHeroIndexBloatPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "index_bloat" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/live_queries(.:format) */
export function pgHeroLiveQueriesPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "live_queries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/queries(.:format) */
export function pgHeroQueriesPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "queries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/queries/:query_hash(.:format) */
export function pgHeroShowQueryPath(query_hash: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "queries" + "/" + query_hash + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","query_hash","format"]);
}

/** /rails/pghero(/:database)/system(.:format) */
export function pgHeroSystemPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "system" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/cpu_usage(.:format) */
export function pgHeroCpuUsagePath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "cpu_usage" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/connection_stats(.:format) */
export function pgHeroConnectionStatsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "connection_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/replication_lag_stats(.:format) */
export function pgHeroReplicationLagStatsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "replication_lag_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/load_stats(.:format) */
export function pgHeroLoadStatsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "load_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/free_space_stats(.:format) */
export function pgHeroFreeSpaceStatsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "free_space_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/explain(.:format) */
export function pgHeroExplainPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "explain" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/tune(.:format) */
export function pgHeroTunePath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "tune" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/connections(.:format) */
export function pgHeroConnectionsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/maintenance(.:format) */
export function pgHeroMaintenancePath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "maintenance" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/kill(.:format) */
export function pgHeroKillPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "kill" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/kill_long_running_queries(.:format) */
export function pgHeroKillLongRunningQueriesPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "kill_long_running_queries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/kill_all(.:format) */
export function pgHeroKillAllPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "kill_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/enable_query_stats(.:format) */
export function pgHeroEnableQueryStatsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "enable_query_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/reset_query_stats(.:format) */
export function pgHeroResetQueryStatsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "reset_query_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/system_stats(.:format) */
export function pgHeroSystemStatsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "system_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero(/:database)/query_stats(.:format) */
export function pgHeroQueryStatsPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + ($hasPresentOwnProperty(options, "database") ? "/" + (options as any).database : "") + "/" + "query_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/pghero/(:database)(.:format) */
export function pgHeroRootPath(options?: object): string {
  return "/" + "rails" + "/" + "pghero" + "/" + ($hasPresentOwnProperty(options, "database") ? (options as any).database : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["database","format"]);
}

/** /rails/sidekiq */
export function sidekiqWebPath(options?: object): string {
  return "/" + "rails" + "/" + "sidekiq" + $buildOptions(options, []);
}

/** /share/jobs/:id/:provider(/:tracker_id)(.:format) */
export function shareProxyPath(id: ScalarType, provider: ScalarType, options?: object): string {
  return "/" + "share" + "/" + "jobs" + "/" + id + "/" + provider + ($hasPresentOwnProperty(options, "tracker_id") ? "/" + (options as any).tracker_id : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","provider","tracker_id","format"]);
}

/** /it-personalvermittlung(.:format) */
export function itPersonalvermittlungPath(options?: object): string {
  return "/" + "it-personalvermittlung" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /it-personalvermittlung/kontakt(.:format) */
export function itPersonalvermittlungKontaktPath(options?: object): string {
  return "/" + "it-personalvermittlung" + "/" + "kontakt" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /it-personalvermittlung/confirmed(.:format) */
export function itPersonalvermittlungConfirmedPath(options?: object): string {
  return "/" + "it-personalvermittlung" + "/" + "confirmed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /_bsc(.:format) */
export function BscPath(options?: object): string {
  return "/" + "_bsc" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /recede_historical_location(.:format) */
export function turboRecedeHistoricalLocationPath(options?: object): string {
  return "/" + "recede_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /resume_historical_location(.:format) */
export function turboResumeHistoricalLocationPath(options?: object): string {
  return "/" + "resume_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /refresh_historical_location(.:format) */
export function turboRefreshHistoricalLocationPath(options?: object): string {
  return "/" + "refresh_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token","format"]);
}
