import { createI18n } from 'vue-i18n'

import en from '~/generated/locales/js.en'
import de from '~/generated/locales/js.de'

const i18n = createI18n({
  locale: window.Locale || 'de',
  //fallbackLocale: 'en',
  allowComposition: true,
  messages: {
    ...de,
    ...en,
  },
})

window.i18n = i18n.global

export default i18n
