<template lang="pug">
.mt-5.card.bg-medium.text-white.neumorphism-on-medium.mx-auto.d-flex.p-m: .row
  .col-12
    .d-flex.flex-column
      h4.font-light.mb-2
        legend(for='citizenship')
          | {{ $t('js.components.talent_profile.questions.citizenship') }}
      div: radio-group(
        name='citizenship'
        v-model="citizenship"
        :options="citizenshipOptions"
        style="font-size: 1.2rem"
      )

    .mt-5.d-flex.flex-column
      h4.font-light.mb-2
        legend(for='nationality')
          | {{ $t('js.components.talent_profile.questions.nationality') }}
      .col-12.col-lg-6
        Select(
          v-model="form.country_code"
          :options="countryOptions"
        )

    .mt-5.d-flex.flex-column
      h4.font-light.mb-2
        legend(for='germanresidency')
          | {{ $t('js.components.talent_profile.questions.german_residency') }}
        div: radio-group(
          name='germanresidency'
          v-model="germanResidency"
          :options="yesNoOptions"
          style="font-size: 1.2rem"
        )

    .mt-5.d-flex.flex-column
      h4.font-light.mb-2
        legend(for='readytomove')
          | {{ $t('js.components.talent_profile.questions.ready_to_move') }}
      div: radio-group(
        name='readytomove'
        v-model="readyToMove"
        :options="yesNoOptions"
        style="font-size: 1.2rem"
      )

    .mt-5.d-flex.flex-column
        h4.font-light.mb-2
          legend(for='current_employer')
            | {{ $t('js.components.talent_profile.questions.current_employer') }}
        div: radio-group(
          name="current_employer"
          v-model="isWorking"
          :options="yesNoOptions"
          style="font-size: 1.2rem"
        )

    collapse-transition
      .mt-5.d-flex.flex-column(v-if="isWorking == false")
        h4.font-light.mb-2
          legend(for='last_employer')
            | {{ $t('js.components.talent_profile.questions.last_employer') }}
        div: radio-group(
          name="has_last_employer"
          v-model="hasLastEmployer"
          :options="yesNoOptions"
          style="font-size: 1.2rem"
        )
    collapse-transition
      .mt-5.d-flex.flex-column(v-if="isWorking == true || hasLastEmployer == true")
        h4.font-light.mb-2
          legend(for='employer_name')
            | {{ $t('js.components.talent_profile.questions.employer_name') }}
        .col-12.col-lg-6
          SelectAutoComplete(
            id="creation_blocklist"
            name="company_blocklist"
            v-model="form.company_blocklist"
            :placeholder="$t('js.components.talent_profile.placeholders.company_blocklist')"
            :options="companyOptions"
            :allowCustom="true"
            :useTextOnly="true"
          )
            template(v-slot:selected)
              .mt-2.d-flex.justify-content-start.flex-wrap.gap-2
                .d-flex.align-items-center.custom-badge(v-for="com in form.company_blocklist")
                  button.btn-close.btn-close-white(aria-label="remove" @click="remove(com)" style="margin-right: 10px;")
                  span
                    | {{ com }}
        .alert.alert-info.mt-3
          i.fa.fa-info-circle.fa-lg(style="margin-right: 10px;")
          | {{ $t('js.components.talent_profile.questions.employer_hints') }}

    .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="showError && !valid")
      | {{ $t('js.components.talent_profile.errors.work_permit') }}

</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { form, onboardingForm } from "@/talents/creation_form/talents_form"
import { talentsCompanyListPath } from "@/routes"
import { useQuestionOptions } from '@/talents/question_options'
import { Select, SelectAutoComplete, RadioGroup } from "@/elements"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"

interface FormOption {
  value: string;
  text: string;
}

const companyOptions = ref<FormOption[]>([]);

const { citizenshipOptions, yesNoOptions, countryOptions } = useQuestionOptions()

function remove(company: string) {
  form.value.company_blocklist = form.value.company_blocklist.filter((c) => c !== company);
}

const isWorking = computed<boolean | null>({
  get() {
    return onboardingForm.value.is_working
  },
  set(val: boolean | null) {
    onboardingForm.value.is_working = val
  },
})

const hasLastEmployer = computed<boolean | null>({
  get() {
    return onboardingForm.value.has_last_employer
  },
  set(val: boolean | null) {
    if (val === false) {
      form.value.company_blocklist = []
    }
    onboardingForm.value.has_last_employer = val
  },
})

const citizenship = computed<string | null>({
  get() {
    return onboardingForm.value.citizenship
  },
  set(val: string | null) {
    onboardingForm.value.citizenship = val
  },
})

const germanResidency = computed<boolean | null>({
  get() {
    return form.value.german_residency
  },
  set(val: boolean | null) {
    form.value.german_residency = val
  },
})

const readyToMove = computed<boolean | null>({
  get() {
    return form.value.ready_to_move
  },
  set(val: boolean | null) {
    form.value.ready_to_move = val
  },
})

const showError = ref<boolean>(false)

const valid = computed(() => {
  return citizenship.value !== "" && germanResidency.value !== null && readyToMove.value !== null
  && ((isWorking.value == true || (isWorking.value != null && hasLastEmployer.value != null)))
})

const touchFields = () => {
  showError.value = true
}

onMounted(() => {
  const path = talentsCompanyListPath();
  fetch(path)
    .then(response => response.json())
    .then(data => {
      companyOptions.value = data;
    });
})


defineExpose({
  valid,
  touchFields
})
</script>

<style scoped>
.custom-badge {
  background-color: #103640;
  color: #fff;
}

</style>
