<template lang="pug">
.mb-3(ref="searchbarHolder")
  .d-flex.input-group-lg
    .d-flex.justify-content-start.align-items-center.flex-wrap.search-bar.flex-grow-1.bg-white
      .search-icon.d-none.d-lg-flex
        i.fas.fa-search(style="color: #fff;")
      .flex-grow-1
        input.search-input(
          type="text"
          id="new_job_title"
          :class="showError ? 'is-invalid' : ''"
          v-model="searchStr"
          autocomplete="off"
          @input="debouncedSearch()"
          @keydown.enter.prevent="handleEnter()"
          :placeholder="$t('js.components.talent_profile.job_titles.search')"
        )
    button.btn.btn-dark(@click="handleEnter()" id="add_custom_competence")
      .d-none.d-md-block
        | {{ $t('js.components.talent_profile.pages.add') }}
      .d-md-none
        i.fas.fa-plus
  .job-suggestion--box(:class="minimize ? 'minimize' : ''")
    .loading-progress-bar.mt-1(v-show="loading")
      .loading-progress-bar__progress
    .mb-1(v-if="!noResults")
      small
        | {{ $tc('js.components.talent_profile.job_titles.suggestion_hint', autocompleteResults.length) }}
    .mb-1(v-else)
      small
        | {{ $t('js.components.talent_profile.job_titles.no_result') }}
    .d-flex.justify-content-start.flex-wrap.gap-2.mb-3.flex-column-on-sm(v-if="autocompleteResults.length > 0")
      .job-suggestion--item.full-width-on-sm(v-for="item in autocompleteResults" :key="item.value" @click="addTitle(item.text)")
        | {{ item.text }}
    .mb-1
      small
        | {{ $t('js.components.talent_profile.job_titles.often_used')}}
    .d-flex.justify-content-start.flex-wrap.gap-2
      .job-suggestion--item.full-width-on-sm.center-on-sm(
        v-for="(item, idx) in staticJobTitles"
        :key="idx"
        @click="addTitle(item[`title_${locale}`])")
          | {{ item[`title_${locale}`] }}

  .mt-3(v-show="proxyValue.length > 0")
    small
      | {{ $t('js.components.talent_profile.job_titles.selected') }}
  .d-flex.flex-wrap.mt-2.gap-2
    .d-flex.align-items-center.job-title--badge.full-width-on-sm(v-for="title in proxyValue" :key="title")
      button.btn-close.btn-close-white.btn-sm(aria-label="remove" @click="removeTitle(title)" style="margin-right: 10px;")
      span.job.text-white.text-center.flex-grow-1
        | {{ title }}

  .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="showError")
    | {{ $t('js.components.talent_profile.errors.job_titles') }}
</template>


<script setup lang="ts">
import { ref, computed } from "vue"
import { useDebounceFn } from "@vueuse/core"
import { talentsSuggestWithBaJobsPath } from "@/routes"
import json from "@/talents/job_titles.json"
import { useI18n } from "vue-i18n"

const { locale } = useI18n()

const staticJobTitles = computed(() => {
  return json.filter((item: any) => item.portal_type === props.portalType)
})

const searchbarHolder = ref<HTMLElement | null>(null)
const noResults = ref<boolean>(false)

const props = withDefaults(defineProps<{
  modelValue: string[],
  portalType: portaltype,
  showError?: boolean
  minimize?: boolean
}>(), {
  showError: false,
  minimize: false,
})

const emits = defineEmits(["update:modelValue"])

const loading = ref<boolean>(false)

const proxyValue = computed({
  get() {
    return props.modelValue
  },
  set(value: string[]) {
    emits("update:modelValue", value)
  },
})

type option = {
  value: string,
  text: string,
}
const autocompleteResults = ref<option[]>([])

const requestOptions = {
  method: "GET",
  headers: {
    Accept: "application/json",
  },
}

const searchStr = ref<string>("")

const autocomplete = async () => {
  const term = searchStr.value.trim()
  if (term.length < 3) return

  loading.value = true
  const path = talentsSuggestWithBaJobsPath({term: searchStr.value.trim(), portal_type: props.portalType})
  await fetch(path, requestOptions)
    .then((res: any) => res.json())
    .then((res: any) => {
      autocompleteResults.value = res
      if (autocompleteResults.value.length === 0){
        noResults.value = true
      } else {
        noResults.value = false
      }
      loading.value = false
    })
}

const debouncedSearch = useDebounceFn(() => {
  autocomplete()
}, 500)

function handleEnter() {
  const title = searchStr.value.trim()
  if (title === "") {
    return
  }
  addTitle(title)
}

function addTitle(title: string) {
  const idx = proxyValue.value.indexOf(title)
  if(idx === -1) {
    proxyValue.value.push(title)
  }
}

function removeTitle(title: string) {
  const idx = proxyValue.value.indexOf(title)
  proxyValue.value.splice(idx, 1)
}

</script>


<style lang="scss" scoped>
.job-title--badge {
  font-size: 1.2rem;
  padding: 6px 12px;
  background-color: #bf436f;
  border-radius: 5px;
  border: 1px solid #802d4a;
}

.search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  background-color: #103640;
  width: 45px;
  height: 45px;
  margin: 5px 10px;
}

.search-input {
  font-size: 1.3rem;
  border:none;
  padding-left: 10px;
  width: 100%;
}

.search-input:focus {
  outline: none;
}

.btn-dark{
  border-radius: 0 10px 0 0;
}

.search-bar {
  border-radius: 10px 0 0 0;
  border: 1px solid #ccc;
}

.job-title-wrapper {
  overflow: auto;
  white-space: nowrap;
}

.job-suggestion--box {
  width: 100%;
  border-radius: 0 0 10px 10px;
  padding: 10px 20px;
  background-color: rgba(255,255,247,.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.job-suggestion--item {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #103640;
  color: #fff;
  cursor: pointer;
}

.minimize {
  background-color: rgba(128,164,218,.3) !important;
}

</style>
