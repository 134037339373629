import BaseController from "./base_controller"

import { medium } from './themes'

export default class extends BaseController {
  chartOptions() {
    const options = this.jsonChartOptions()
    options.series[2].tooltip = {
      pointFormatter: function () {
        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.y * -1 + " Abmeldungen<br/>"
      },
    }
    medium(options)
    return options
  }
}
