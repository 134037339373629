<template>
  <div class="card" :class="classes" v-bind="$attrs">
    <component :is="withBody ? 'div' : 'template'" class="card-body">
      <slot />
    </component>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"

const variants = {
  primary: "bg-primary text-white",
  medium: "bg-secondary text-white",
  white: "bg-white text-dark",
}

const neumorphism = {
  primary: "neumorphism-on-primary",
  medium: "neumorphism-on-medium",
  white: "neumorphism-on-white",
}
type Neumorphism = keyof typeof neumorphism | false

interface Props {
  variant?: keyof typeof variants
  neumorphism?: Neumorphism
  withBody?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  variant: "primary",
  neumorphism: undefined,
  withBody: true,
})

const neumorphismVariant = computed(() => {
  if (props.neumorphism == undefined) return props.variant
  return props.neumorphism
})

const classes = computed(() => {
  const a = []
  a.push(variants[props.variant])
  const nv = neumorphismVariant.value
  if (nv) a.push(neumorphism[nv])
  return a
})
</script>

<style scoped></style>
