<template lang="pug">
.chat(@click="emits('chat-focused')")
  .chat-body#chat-region
    template(v-if="conversation.messages.length === 0")
      .text-center.text-muted.h-100.mt-3
        | Keine Nachrichten vorhanden.
    template(v-else)
      .mt-1.mb-1.text-center(v-if="conversation.is_read_by_org && !lastMessageFromOrg")
        small.text-muted
          | {{ $t('js.components.profile.conversation.seen_by_org', {company: conversation.organisation_data.name}) }}
        i.fas.fa-check-double.text-success(style="margin-left: 0.5rem")
      Message(
        v-for="message in conversation.messages"
        :message="message"
        :key="message.id"
      )

  .chat-footer
    .chat-box.position-relative
      .uploaded-file.mb-2(v-if="uploaded")
        i.fa.fa-file(style="margin-right: 0.5rem")
        | {{ uploaded.file.name }}
        button.btn.btn-sm.ms-auto(type="button" @click="uploaded = null; fileBlob = null")
          i.fa.fa-times
      .error-message.mb-2(v-if="showUploadError")
        | {{ $t("js.components.profile.conversation.upload_error") }}

      .loading(v-if="loading")
        .spinner-border.text-primary

      textarea.form-control#chat-input(
        v-model.trim="message"
        ref="chatInput"
        type="text"
        name="chat-input"
        :placeholder="placeholder"
        :disabled="conversation.status === 'closed'"
        style="resize: none;" rows="3"
        @keyup.ctrl.enter="sendMessage"
        @keyup.meta.enter="sendMessage"
      )
      input(id="chat-file-upload" ref="fileUpload" name="chat-file-upload" type="file" class="sr-only" :multiple="false" :accept="accept" @input="uploadFileEvent")
      .d-flex.justify-content-between.gap-1.mt-2.pl-1.pr-1
        .d-flex.justify-content-start.gap-1
          button.btn.btn-secondary(
            type="button"
            @click="openFileUpload"
            id="upload-button"
            :disabled="conversation.status === 'closed'"
          )
            i.fa.fa-paperclip
          button.btn.btn-secondary.d-none.d-lg-block(
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :disabled="conversation.status === 'closed'"
            :title="$t('js.components.profile.conversation.fett')"
            @click="toggleBold"
          )
            i.fa.fa-bold
          button.btn.btn-secondary.d-none.d-lg-block(
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :disabled="conversation.status === 'closed'"
            :title="$t('js.components.profile.conversation.kursiv')"
            @click="toggleItalic"
          )
            i.fa.fa-italic

        .d-flex.gap-1
          button.btn.btn-outline-dark.transform-none(
            :disabled="conversation.status === 'closed' || conversation.hire_status != 'no_offer_yet'"
            type="button"
            @click="showTriggerModal = true"
          )
            template(v-if="!conversation.is_public")
              i.fas.fa-id-card
              span.show-text-from-md(style="margin-left: 0.5rem")
                | {{ $t("js.components.profile.conversation.public_data")}}
            template(v-else)
              i.fas.fa-user-lock
              span.show-text-from-md(style="margin-left: 0.5rem")
                | {{ $t("js.components.profile.conversation.private_data")}}

          button.btn.btn-primary(
            type="button"
            @click="sendMessage"
            :disabled="(message.length === 0 && !uploaded) || conversation.status === 'closed'"
            style="min-width: 70px;"
            id="chat-send-button"
          )
            i.fa.fa-paper-plane
            span.show-text-from-md(style="margin-left: 0.5rem")
              | {{$t('js.components.profile.conversation.send') }}

  confirm-modal(
    v-model="showTriggerModal"
    :titleKey="triggerModalTitle"
    @confirm="emits('trigger-profile');"
  )
    template(v-slot:confirm_content)
      div(v-if="!conversation.is_public")
        .mb-3.text-center
          i.fas.fa-unlock-alt.fa-3x
        p
          | {{ $t('js.components.profile.conversation.confirm_modal.text_public') }}
      div(v-else)
        .mb-3.text-center
          i.fas.fa-lock.fa-3x
        p
          | {{ $t('js.components.profile.conversation.confirm_modal.text_private') }}

</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import { DirectUpload } from "@rails/activestorage"
import Message from "@/talents/profile/chat/Message.vue"
import ConfirmModal from "./ConfirmModal.vue"

const props = defineProps<{
  conversation: FrontendConversation
  loading: boolean
}>()

const chatInput = ref<HTMLTextAreaElement | null>(null)
const message = ref<string>("")
const showTriggerModal = ref(false)

const triggerModalTitle = computed(() => {
  return props.conversation.is_public ? "js.components.profile.conversation.confirm_modal.title_private" : "js.components.profile.conversation.confirm_modal.title_public"
})

const lastMessageFromOrg = computed(() => {
  if (!props.conversation.last_message) return false

  return props.conversation.last_message.from_org
})

const emits = defineEmits(["send-message", "trigger-profile", "chat-focused"])

function sendMessage() {
  if (message.value.length == 0 && !uploaded.value) return

  const payload = {
    message: message.value,
    file: null as ActiveStorage.Blob | null,
  }
  if (uploaded.value) {
    payload["file"] = fileBlob.value
  }
  emits('send-message', payload)
  message.value = ""
  uploaded.value = null
}

function toggleBold() {
  appendSymbolBetweenSelectedText("**")
}

function toggleItalic() {
  appendSymbolBetweenSelectedText("*")
}

function appendSymbolBetweenSelectedText(symbol: string) {
  const start = chatInput.value?.selectionStart || 0
  const end = chatInput.value?.selectionEnd || 0
  const text = message.value
  const selectedText = text.substring(start, end)
  const beforeText = text.substring(0, start)
  const afterText = text.substring(end, text.length)
  const newText = beforeText + " " + symbol + selectedText + symbol + " " + afterText
  message.value = newText
  chatInput.value?.focus()
}

import { useI18n } from "vue-i18n"
const { t } = useI18n()
const placeholder = computed(() => {
  if (props.conversation.status === "closed") {
    return t("js.components.profile.conversation.placeholders.disabled")
  }
  return t("js.components.profile.conversation.placeholders.enabled")
})

const uploadUrl = "/rails/active_storage/direct_uploads"
const maxSize = 10 // MB

const progress = ref<number>(0)
const showUploadError = ref<boolean>(false)
const uploaded = ref<{ file: File; upload: any } | null>(null)
const fileBlob = ref<ActiveStorage.Blob | null>(null)
const fileUpload = ref<HTMLInputElement | null>(null)

function openFileUpload() {
  if (props.conversation.status === 'closed') return

  fileUpload.value?.click()
}

const accept = computed(() => {
  return "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*"
})

const onProgress = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
  progress.value = (event.loaded / event.total) * 100
}

const directUploadWillStoreFileWithXHR = (xhr: XMLHttpRequest) => {
  xhr.upload.addEventListener("progress", (event) => onProgress(event))
}

const uploadFile = (file: File) => {
  if (maxSize && file.size > maxSize * 1024 * 1024) {
    showUploadError.value = true
    return
  }
  const upload = new DirectUpload(file, uploadUrl, { directUploadWillStoreFileWithXHR })
  uploaded.value = { file, upload }
  progress.value = 0
  upload.create((error, blob) => {
    if (error) {
      showUploadError.value = true
    }
    else {
      fileBlob.value = blob
    }
  })
}

const uploadFileEvent = (event: Event) => {
  showUploadError.value = false
  const files = (event.target as HTMLInputElement).files
  if (!files) {
    return
  }
  for (let i = 0; i < files.length; i++) {
    uploadFile(files[i])
  }
}

</script>

<style scoped>
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 700px;
}
.chat-body {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  padding: 1rem;
  overflow-y: scroll;
}
.chat-footer {
  padding: 0 1rem 1rem 1rem;
}
.chat-box {
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
}
@media (max-width: 768px) {
  .chat-footer {
    padding: 0;
  }
  .chat-box {
    border: none;
  }
  .chat {
    height: 500px;
  }
}
@media (max-width: 992px) {
  .show-text-from-md {
    display: none;
  }
}
.form-control {
  border: none;
  box-shadow: none;
}
.uploaded-file {
  display: flex;
  padding-left: 10px;
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}
.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 5px;
  font-size: 0.9rem;
}
.loading {
  position: absolute;
  top: 10%;
  right: 1rem;
  height: 100%;
}
</style>
