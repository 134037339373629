<template>
  <div>
    <transition name="fade">
      <div v-if="!form.country_code" class='mb-3'>
        <FormGroup :label="true" for="livingInGermany">
          <RadioGroup v-model="form.livingInGermany" :options="livingInGermanyOptions" name="livingInGermany" />
        </FormGroup>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="form.country_code || showCountrySelector">
        <label for="street">
          {{ $t("js.components.apply_card.postal_address") }}
          <abbr v-if="fieldRequired('postal_address')" class="required" title="required">*</abbr>
        </label>
        <CountryField v-model="form.country_code" :required='fieldRequired("postal_address")'/>
        <FormInput name="street" rows="3" v-model="form.street" autocomplete="street-address" :label="false" class="mt-2" />
        <div class="d-flex gap-2 mt-2">
          <FormInput name="postcode" v-model="form.postcode" autocomplete="postal-code" :label="false" wrapper-class="flex-grow-1" />
          <FormInput name="city" v-model="form.city" autocomplete="address-level1" :label="false" wrapper-class="flex-grow-1" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue"
import { RadioGroup, FormGroup, FormInput } from "@/elements"
import CountryField from "@/components/apply/CountryField"
import { useI18n } from "vue-i18n"

const { t: $t } = useI18n()

const props = defineProps<{
  modelValue: ApplicationForm
  applicationInfo: ApplicationInfo
}>()

const livingInGermanyOptions = computed(() => {
  return [
    { text: $t("js.components.apply.apply_form.living_in_germany_options.true"), value: true },
    { text: $t("js.components.apply.apply_form.living_in_germany_options.false"), value: false },
  ]
})

const showCountrySelector = ref(props.modelValue.livingInGermany !== null)

const fieldRequired = (f: string) => {
  if (props.applicationInfo.required_fields) {
    return props.applicationInfo.required_fields.includes(f)
  } else {
    return false
  }
}

const emit = defineEmits<{
  (e: "update:modelValue", value: ApplicationForm): void
}>()

const form = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})

watch(
  () => form.value.livingInGermany,
  (value) => {
    console.log("VALUE", { value })
    if (value === true) {
      form.value.country_code = "DE"
    } else if (value === false) {
      form.value.country_code = ""
      showCountrySelector.value = true
    }
  }
)
</script>

<style scoped></style>
