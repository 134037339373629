<template>
  <FilterBar @search="search" />
  <div v-if="isLoading" class="my-4 text-center text-muted">
    {{ $t("js.wizard.organisation_list.loaded") }}
  </div>
  <div v-else-if="jobCount && jobCount > 0" class="my-4 text-center text-muted">
    <!-- i18n-use t("js.wizard.organisation_list.found") -->
    {{ $tc("js.wizard.organisation_list.found", organisationCount) }}
  </div>
  <div class="d-flex flex-column gap-3">
    <OrganisationSkeletonCard v-if="isLoading" style='min-height: 1000px' />
    <OrganisationCard v-for="organisation in orgsBefore" :key="organisation.organisation.username || '111'"
      :payload="organisation" />
  </div>
  <div class="my-6">
    <SubscribeCard :bestMatchingCommunity="bestMatchingCommunity" />
  </div>
  <div class="d-flex flex-column gap-3">
    <Lazyload v-for="organisation in orgsAfter" :key="organisation.organisation.username || '111'"
      rootMargin="500px 0px 500px 0px">
      <OrganisationCard :payload="organisation" />
      <template v-slot:loading>
        <OrganisationSkeletonCard style='min-height: 500px' />
      </template>
    </Lazyload>
  </div>
  <div class="my-6" v-if='orgsAfter.length > 3'>
    <SubscribeCard :bestMatchingCommunity="bestMatchingCommunity" />
  </div>
  <ScrollToTop />
</template>

<script lang="ts" setup>
import OrganisationCard from "./OrganisationCard.vue"
import OrganisationSkeletonCard from "@/wizard/components/OrganisationSkeletonCard.vue"
import SubscribeCard from "./components/SubscribeCard.vue"
import Lazyload from "@/components/Lazyload.vue"
import ScrollToTop from "@/components/ScrollToTop.vue"
import { watch, computed, onMounted, ref, provide } from "vue"
import FilterBar from "@/wizard/FilterBar.vue"
import { form } from "@/wizard/form"
import * as Routes from "@/routes"

const props = defineProps<{
  form: typeof form
  tracking: string
  recommendations: ValidationSucceed[]
}>()

const organisations = ref<frontend.CompanyFinderResult["organisations"]>([])

const isLoading = ref(true)
const jobCount = ref(0)
const organisationCount = ref(0)
const bestMatchingCommunity = ref<frontend.BestMatchingCommunity | null>(null)

const refresh = () => {
  isLoading.value = true
  return fetch(Routes.wizardCompaniesPath(form))
    .then((r) => r.json())
    .then((r) => {
      isLoading.value = false
      organisations.value = r.organisations
      jobCount.value = r.job_count
      organisationCount.value = r.organisation_count
      bestMatchingCommunity.value = r.best_matching_community
    })
}
const updateUrlFromParams = () => {
  // convert form to url query params
  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(form)) {
    if (value) {
      if (typeof value == 'object' && value.length) {
        params.set(key, value.join(','))
      } else {
        params.set(key, value.toString())
      }
    }
  }
  // update history url
  window.history.replaceState(null, null, `${window.location.pathname}?${params.toString()}`)
}
const search = () => {
  updateUrlFromParams()
  refresh()
}


watch(
  () => props.form,
  (f) => {
    Object.assign(form, f)
    refresh()
  }
)
onMounted(() => {
  Object.assign(form, props.form)
  refresh()
})

const orgsBefore = computed(() => {
  return organisations.value.slice(0, 2)
})

const orgsAfter = computed(() => {
  return organisations.value.slice(2)
})
provide("tracking", props.tracking)
provide("recommendations", props.recommendations)
</script>

<style scoped>

</style>
