<template lang="pug">
.w-100(style="padding: 0 15px 15px 15px")
  .d-flex.flex-column.justify-content-center.flex-wrap.gap-3
    div(v-for="star in starTypes" :key="star.name")
      .text-center
        small
          | {{ $t(`js.components.partner_review.${star.name}`) }}
      .d-flex.justify-content-center
        Icon(
          v-for="n in star.fullStars"
          :key="n" :path="mdiStar"
          fill="#FFC107"
          size="20"
        )
        Icon(
          v-if="star.lastStar.length > 0"
          :path="star.lastStar"
          fill="#FFC107"
          size="20"
        )
        Icon(
          v-for="n in star.emptyStars"
          :key="n"
          :path="mdiStarOutline"
          size="20"
          fill="#FFF"
        )

</template>

<script setup lang="ts">
import { computed } from 'vue'
import { mdiStarHalfFull, mdiStar, mdiStarOutline } from '@mdi/js'
import Icon from "../elements/Icon"

const props = defineProps({
  survey: {
    type: Object,
    required: true
  }
})

const types = ['averageScore', 'totalSatisfaction', 'beratungScore', 'commentRecommend']

const starTypes = computed(() => {
  return types.map((type) => {
    const score = props.survey[type]
    if (!score) return null

    const stars = 6 - score
    const fullStars = Math.floor(stars)
    const leftOver = stars % 1
    let lastStar = ''

    if (0.25 < leftOver && leftOver < 0.75) {
      lastStar = mdiStarHalfFull
    }
    else if (leftOver >= 0.75) {
      lastStar = mdiStar
    }

    const emptyStars = 5 - fullStars - (lastStar.length > 0 ? 1 : 0)

    return {
      name: type,
      fullStars,
      lastStar,
      emptyStars
    }
  }).filter((el) => el != null)
})
</script>
