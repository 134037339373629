import { Controller } from 'stimulus'

export default class extends Controller {
  observer: IntersectionObserver | null = null
  connect() {
    var target = document.querySelector("footer");
    if (!target) return;
    const element = this.element
    this.observer = new IntersectionObserver((entries) => {
      // The callback will return an array of entries, even if you are only observing a single item
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Show button
          element.classList.remove('d-none')
        } else {
          // Hide button
          element.classList.add('d-none')
        }
      })
    })
    this.observer.observe(target);
  }
  disconnect() {
    if (this.observer)
      this.observer.disconnect()
  }
}

