<template lang='pug'>
div(ref="mainElement")
  ul.nav.nav-pills
    li.nav-item(v-for="portalType in portalTypes")
      button.nav-link(:class="{ active: activeType === portalType }" @click="changeType(portalType)")
        |{{ portalType }} {{ $t('js.components.berufe_selector.berufe') }}

  .row.mt-3
    .col-6.col-lg-8
      .berufe-list
        button.btn.btn-outline-primary.btn-lg(v-for="beruf in berufe" @click.prevent='activeCompetence = beruf' :class="{ active: activeCompetence === beruf }")
          |{{ beruf.display_name }}
        a.btn.btn-outline-primary.btn-lg(href='/kontakt?type=berufsprofil')
          | {{ $t('js.components.berufe_selector.weitere') }}
          br
          | {{ $t('js.components.berufe_selector.berufsprofile') }}
      div(style="height: 500px; text-align: center" class="text-dark" v-if="berufe.length == 0")
        | {{ $t('js.components.berufe_selector.wahlen_sie_einen_berufstyp') }}
    .col-lg-4.col-6.d-lg-flex.d-block.card.bg-primary.p-3
      div.text-start.card-body.text-white(v-if='activeCompetence' style='position: sticky; top: 80px;')
        h4.h2.font-light.hyphens
          |{{ activeCompetence.display_name }}
        p {{ $t('js.components.berufe_selector.in_den_letzten_365_tagen') }}
        ul.list-checkbox(style='font-size: 1.2rem; hyphens: auto')
          li(v-if=' activeCompetence.this_year_click_count ')
            | {{ formatNumber(activeCompetence.this_year_click_count) }} {{ $t('js.components.berufe_selector.jobsuchende') }}
          li
            | {{ formatNumber(activeCompetence.this_year_application_count) }} {{ $t('js.components.berufe_selector.bewerbungen') }}
          li(v-if='activeCompetence.this_year_applications_with_code_count > 0')
            | {{ formatNumber(activeCompetence.this_year_applications_with_code_count) }} {{ $t('js.components.berufe_selector.empfehlungen') }}
          li
            | {{ activeCompetence.this_year_user_count }} {{ $t('js.components.berufe_selector.empfehlende_arbeitgeber') }}
          li
            | ca. {{ activeCompetence.first_application_after }} {{ $t('js.components.berufe_selector.tage_bis_zur_ersten') }}
          li(v-if='activeCompetence.this_year_impressions_count > 0')
            | {{ activeCompetence.this_year_impressions_count }} {{ $t('js.components.berufe_selector.impressions') }}
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { useIntersectionObserver } from "@vueuse/core"

const portalTypes: PortalType[] = ["IT", "MINT", "OFFICE", "SANO"]
const activeType = ref<PortalType | null>(null)

const berufe = ref<CompetenceResult[]>([])
const activeCompetence = ref<CompetenceResult | null>(null)
const changeType = (type: PortalType) => {
  activeType.value = type
  fetch(`/arbeitgeber/api/competences?portal_type=${type}`)
    .then((res) => res.json())
    .then((data) => {
      berufe.value = data.slice(0, 15)
      activeCompetence.value = berufe.value[0]
    })
}

const formatNumber = (number: number) => {
  // add thousend separator
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

const mainElement = ref<HTMLElement | null>(null)
const { stop } = useIntersectionObserver(mainElement, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting) {
    changeType("IT")
    stop()
  }
})
</script>

<style scoped>
.berufe-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  grid-gap: 1rem;
  min-height: 350px;
}

.berufe-list .btn {
  hyphens: auto;
  word-wrap: break-word;
  min-height: 3.5em;
}
</style>
