<template lang="pug">
.card.profile-card(:class="{'border-0': preview}")
  .card-body(:class="{'p-0': preview}")
    .row
      template(v-if="!preview")
        .col-12(v-if="isSubmitting")
          .loading-progress-bar.mt-2.mb-2
            .loading-progress-bar__progress
      .col-12: .mb-4
        label.mb-2(for="active_recommendations")
          | {{ $t('js.components.profile.labels.active_recommendations') }}
        .input-group.input-group-lg
          input.form-control(
            type="text"
            id="add-recommendation"
            v-model="code"
            :placeholder="$t('js.components.profile.recommendations.placeholder')"
            name="recommendations"
          )
          button.btn.btn-outline-primary.btn-lg(
            type="button"
            id="submit-recommendation"
            @click="submit"
            :disabled="isSubmitting"
          )
            i.fas.fa-plus

        table.table.table-hover.table-sm.mt-3(v-if="recommendations && recommendations.length > 0")
          thead
            tr
              th.col
                | {{ $t('js.components.profile.tables.empfehlungscode') }}
              th.col
                | {{ $t('js.components.profile.tables.firmenname') }}
              th.col.text-center.text-sm
                |
          tbody
            tr(v-for="r in recommendations" :key="r.id")
              td
                | {{ r.code }}
              td
                | {{ r.firma }}
              td.text-sm.text-center
                button.btn.btn-sm.btn-outline-danger(@click="showConfirmation = r.id")
                  i.fas.fa-trash-alt
                collapse-transition
                  .mt-1(v-if="showConfirmation == r.id")
                    div
                      small
                        | {{ $t('js.components.profile.recommendations.confirmation') }}
                    div
                      button.btn.btn-sm.btn-outline-primary(@click="showConfirmation = -1" style="margin-right: 0.5rem")
                        | {{ $t('js.components.talent_profile.answers.no') }}
                      button.btn.btn-sm.btn-accent(@click="removeCode(r.id)")
                        | {{ $t('js.components.talent_profile.answers.yes') }}


        div(v-else)
          small
            | {{ $t('js.components.profile.tables.keine_empfehlungen') }}


  .card-footer(v-if="!preview")
    .d-flex.justify-content-end.gap-2
      button.btn.btn-sm.btn-outline-danger(@click="editing = false" id="close_edit_recommendation")
        i.fas.fa-times(style="margin-right: 0.5rem")
        span(v-if="!dataSent")
          | {{ $t('js.components.talent_profile.generic.abbrechen') }}
        span(v-else)
          | {{ $t('js.components.talent_profile.generic.schliessen') }}

</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { talentsProfileRecommendationsPath, talentsProfileRecommendationPath } from "@/routes"
import csrfToken from "@/utils/csrfToken"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import { useI18n } from "vue-i18n"

const { t } = useI18n()
const dataSent = ref<boolean>(false);
const isSubmitting = ref<boolean>(false);
const error = ref<boolean>(false);
const showConfirmation = ref<number>(-1);

const recommendations = ref<Recommendation[]>([]);

const code = ref<string>("");

const props = withDefaults(defineProps<{
  modelValue: boolean,
  preview: boolean,
}>(), {
  modelValue: false,
  preview: false,
})

const emits = defineEmits(["update:modelValue", "submit:makeToast"])

const editing = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emits("update:modelValue", value);
  },
});

function submit() {
  const path = talentsProfileRecommendationPath();
  const trimmed = code.value.trim();
  if (trimmed.length === 0) return

  const params = {
    code: trimmed,
  }
  isSubmitting.value = true;
  error.value = false;
  dataSent.value = false;

  fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      talents_profile_form: params,
    }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        error.value = true;
      } else {
        recommendations.value = data;
        code.value = "";
      }
      dataSent.value = true;
      isSubmitting.value = false;
      const type = error.value ? "error" : "success"
      emits("submit:makeToast", {
        message: t(`js.components.talent_profile.generic.status.${type}`),
        type: type,
      })
    });
}

function removeCode(id: number) {
  const path = talentsProfileRecommendationPath({id: id});
  isSubmitting.value = true;
  error.value = false;
  dataSent.value = false;
  showConfirmation.value = -1;

  fetch(path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        error.value = true;
      } else {
        recommendations.value = data;
      }
      dataSent.value = true;
      isSubmitting.value = false;
    });
}

onMounted(() => {
  const path = talentsProfileRecommendationsPath();
  fetch(path)
    .then(response => response.json())
    .then(data => {
      recommendations.value = data;
    });
})

</script>

<style scoped>

</style>
