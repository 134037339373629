import { reactive } from 'vue'

const form = reactive({
  query: "",
  location: null as string | null,
  sub_job_type: null as string | null,
  ignore_remote: null as boolean | null,
  full_remote: null as boolean | null,
  company_sizes: null as string[] | null,
  portal_type: null as string | null,
  radius: 30
})

export {
  form
}
