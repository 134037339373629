import * as Turbo from "@hotwired/turbo"
// export type TurboLoadEvent = CustomEvent<{ url: string; timing: TimingData }>

document.addEventListener("turbo:load", function(event) {
  if (!event.detail.url.match(/#[a-z-]+$/)) {
    // Firefox Scroll Bug - Turbolinks keeps scroll position when switching pages
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
})
export default Turbo
