import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output", "input"]

  declare readonly outputTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement

  connect() {
    this.outputTarget.innerHTML = this.inputTarget.value + "km"
  }

  update(event: Event) {
    const v = (event.target as HTMLInputElement).value
    this.outputTarget.innerHTML = v + "km"
  }
}
