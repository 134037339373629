<template>
  <Card class="d-flex flex-column overflow-hidden" variant="primary">
    <div>
      <h3>
        <span class="badge badge-primary bg-accent text-white text-uppercase">
          {{ name }}
        </span>
      </h3>
    </div>

    <div class="w-full align-items-center gap-5 d-flex">
      <div class="d-flex flex-grow-1 flex-column">
        <div class="d-flex align-items-baseline justify-content-end fs-3">
          <small v-if="starting" class="me-2 fs-6 text-muted">
            <a href="#star" class="text-muted">
              {{ $t("js.components.prices.price_card.starting") }}
            </a>
          </small>
          <span v-html="formatCurrency(price)" />
          <span class="ms-1 text-muted fs-6">/{{ unit }}</span>
        </div>
      </div>
    </div>

    <slot />

    <div class="d-flex flex-grow-1 flex-column justify-content-between y-3 px-3">
      <ul role="list" class="y-3 list-unstyled">
        <li v-for="feature in featureSlots" class="d-flex align-items-start gap-2">
          <div class="flex-shrink-0">
            <Icon :path="mdiCheck" class="h-6 w-6 text-success" aria-hidden="true" />
          </div>
          <p class="ms-3">
            <slot :name="feature" />
          </p>
        </li>
      </ul>
    </div>
    <p class="mx-auto mb-2 max-w-sm text-center small text-light" v-if="starting" id="star">
      {{ $t("js.components.prices.price_card.star") }}
    </p>
    <slot name="buttons">
      <div class="text-center">
        <Button wide :href="href">{{ $t("js.components.prices.price_card.book") }}</Button>
      </div>
    </slot>
  </Card>
</template>

<script lang="ts" setup>
import { Icon, Card, Button } from "@/elements"
import { mdiCheck } from "@mdi/js"
import { computed, useSlots } from "vue"

const props = defineProps<{
  name: string
  price: number
  href: string
  starting?: boolean
  unit: string
}>()

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: "EUR",
  }).format(value)

const slots = useSlots()

const featureSlots = computed(() => {
  return Object.keys(slots).filter((e) => e !== "default" && e.startsWith("feature"))
})
</script>
