<template>
  <div>
    <label v-if="label && showLabel" :for="name">{{ showLabel }}</label>
    <div class="mt-1" :class="inline ? 'checkbox-options--inline' : ''">
      <div v-for="option in options" :key="option.value" class="form-check">
        <label class="form-check-label">
          <div class="flex items-center h-5">
            <input
              class="form-check-input"
              type="checkbox"
              :value="option.value"
              :modelValue="modelValue"
              @input="updateValue(option.value, $event.target.checked)"
              :checked="modelValue ? modelValue.includes(option.value) : false"
              :id="id(option)"
              :name="name" />
          </div>
          <div class="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300">{{ option.text }}</div>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useI18n } from "vue-i18n"

const props = defineProps<{
  options: FormOption[]
  label?: string | boolean
  name: string
  modelValue: FormValueType[] | null
  inline?: boolean
}>()
const emits = defineEmits(["update:modelValue"])

const updateValue = (key: FormValueType, value: FormValueType) => {
  let newValue = [
    ...(props.modelValue || []),
  ]
  if (value) {
    newValue.push(key)
  } else {
    newValue = newValue.filter(v => v !== key)
  }
  emits("update:modelValue", Array.from(new Set(newValue)))
}

const id = (option: FormOption) => `${props.name}_${option.value.toString().replace(/\W/g, "_")}`

const t = useI18n().t
const i18nLabel = computed(() => {
  const key = `js.form.properties.${props.name}`
  return t(key)
})
const showLabel = computed(() => {
  if (props.label === true) {
    return i18nLabel.value
  } else if (props.label === false) {
    return null
  } else {
    return props.label
  }
})
</script>

<script lang="ts">
export default { name: "CheckboxGroup" }
</script>

<style scoped>
.checkbox-options--inline {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
  margin-bottom: 1rem;
}
</style>
