<template>
  <div>
    <div class="my-4 d-flex flex-wrap gap-1 align-items-center">
      <a class="btn btn-lg btn-light shadow full-width-on-sm" :href="nextUrl(iplookup.city)" v-if="iplookup">
        {{ iplookup.city }}

        <!-- i18n-use t("js.wizard.location.firmen") -->
        <span class="badge badge-pill bg-medium ms-2">{{ $tc("js.wizard.location.firmen", iplookup.user_count) }}</span>
        <br />
        <small class="text-muted">{{ $t("js.wizard.location.ungefahrer_standort") }}</small>
      </a>
      <a class="btn btn-lg btn-light shadow full-width-on-sm" :href="nextUrl('remote')" v-if="iplookup">
        {{ $t("js.wizard.location.100_remote") }}

        <span class="badge badge-pill bg-medium ms-2">{{ $tc("js.wizard.location.firmen", full_remote_count) }}</span>
        <br />
        <small class="text-muted">{{ $t("js.wizard.location.home_office") }}</small>
      </a>

      <a class="btn btn-lg btn-light shadow full-width-on-sm" :href="nextUrl(geocodingResult.city, { loc: geocodingResult.loc })" v-if="geocodingResult">
        <Icon :path="mdiCrosshairsQuestion" />
        {{ geocodingResult.city }}

        <span class="badge badge-pill bg-medium ms-2">{{ $tc("js.wizard.location.firmen", geocodingResult.count) }}</span>
        <br />

        <small>{{ $t("js.wizard.location.standort_durch_dein_gerat") }}</small>
      </a>
      <button @click="geocode" v-else-if="geocodingEnabled" class="btn-light btn btn-lg full-width-on-sm">
        <Icon :path="mdiCrosshairsQuestion" />
        <br />
        <small v-if="geocodingInProgress">{{ $t("js.wizard.location.wird_bestimmt") }}</small>
        <small v-else>{{ $t("js.wizard.location.standort_durch_dein_gerat") }}</small>
      </button>
    </div>

    <p>{{ $t("js.wizard.location.haufige_arbeitsorte") }}</p>
    <div class="d-flex gap-2 flex-wrap">
      <a class="btn btn-lg btn-light shadow full-width-on-sm" :href="nextUrl(city)" v-for="[city, count] in slicedCities">
        {{ city }}
        <span class="badge badge-pill bg-medium ms-2">{{ $tc("js.wizard.location.firmen", count) }}</span>
      </a>
    </div>

    <div class="d-flex input-group my-5" style="max-width: 600px">
      <input
        id="city"
        name="city"
        type="text"
        class="form-control form-control-lg"
        v-model="city"
        @keyup.enter="goToNextUrl"
        :placeholder="$t('js.wizard.location.anderer_arbeitsort')" />
      <a
        :href="nextUrl(city)"
        :disabled="city.length < 3"
        :class="{ disabled: city.length < 3 }"
        class="btn btn-primary width-300-on-lg d-flex align-items-center justify-content-center"
        >
          {{ $t("js.wizard.location.stellen_in_city_suchen", { city: city }) }}
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import * as Routes from "@/routes"
import { mdiCrosshairsQuestion } from "@mdi/js"
import Icon from "@/elements/Icon"

const props = defineProps<{
  sub_job_type: string
  cities: Record<string, number>
  full_remote_count: number
  iplookup: { city: string; loc: string; user_count: number }
}>()

const nextUrl = (city: string, opts = {}) => {
  return Routes.wizardCompetencesPath({ ...opts, sub_job_type: props.sub_job_type, location: city })
}

const city = ref("")
const goToNextUrl = () => {
  window.location.href = nextUrl(city.value)
}

const geocodingEnabled = navigator.geolocation && typeof navigator.geolocation.getCurrentPosition == "function"
const geocodingResult = ref<{ city: string; loc: string; count: number } | null>(null)
const geocodingInProgress = ref(false)
const latLng = ref<string | null>(null)

const reverseGeocode = (position: GeolocationPosition) => {
  return fetch(Routes.wizardReverseGeocodePath({ lat: position.coords.latitude, lng: position.coords.longitude }))
    .then((r) => r.json())
    .then((r) => {
      geocodingResult.value = {
        city: r.geocoding.address.city,
        loc: `${position.coords.latitude},${position.coords.longitude}`,
        count: r.count,
      }
      geocodingInProgress.value = false
    })
}

const geocode = () => {
  geocodingInProgress.value = true
  navigator.geolocation.getCurrentPosition(
    (position) => {
      latLng.value = `${position.coords.latitude},${position.coords.longitude}`
      reverseGeocode(position).then(() => {
        geocodingInProgress.value = false
      })
    },
    (error) => {
      geocodingInProgress.value = false
      console.error(error)
    }
  )
}

const slicedCities = computed(() => {
  const cities = props.cities
  const keys = Object.keys(cities)
  const content: [string, number][] = keys.slice(0, 15).map((k) => [k, cities[k]])
  return content
})
</script>

<style scoped>
.btn-unstyled {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  vertical-align: baseline;
}
.btn {
  text-transform: none;
}
</style>
