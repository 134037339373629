/* eslint-disable eqeqeq */
import { select, event } from "d3-selection"
import { scaleLinear } from "d3-scale"
import { zoom as d3Zoom } from "d3-zoom"
import { forceSimulation, forceY, forceX, forceLink, forceCollide, forceCenter, forceManyBody } from "d3-force"
import { values } from "d3-collection"
import { drag } from "d3-drag"

let setLinkMode

import { preprocessNodesAdmin } from "./empfehlungsgraphPreprocessNodes"

const defaultConfig = {
  width: 800,
  height: 800,
  minScale: 0.625,
  maxScale: 1.8,
  minStrokeWidth: 0.5,
  maxStrokeWidth: 0.5,
  maxNodeSize: 60,
  minNodeSize: 35,
  colorIncoming: "#ff7e00",
  colorOutgoing: "#006cb7",
  forceStrength: -2000,
  forceDistanceMax: 450,
  disablePanning: true,
  forceEnableCenter: true,
  defaultZoom: 1.0,
}

const recommendationGraph = (target: HTMLElement, links: Empfehlungsgraph.Link[], config = {}) => {
  const {
    width,
    height,
    minScale,
    maxScale,
    minStrokeWidth,
    maxStrokeWidth,
    maxNodeSize,
    minNodeSize,
    colorIncoming,
    forceStrength,
    forceDistanceMax,
    forceEnableCenter,
    defaultZoom,
    disablePanning,
  } = { ...defaultConfig, ...config }

  const { nodes, maxLinkCount, minLinkCount, maxNodeCount, minNodeCount } = preprocessNodesAdmin(links)

  // create scales
  const strokeScale = scaleLinear().domain([minLinkCount, maxLinkCount]).range([minStrokeWidth, maxStrokeWidth])

  const nodeScale = scaleLinear().domain([minNodeCount, maxNodeCount]).range([minNodeSize, maxNodeSize])

  const circleScale = (d: Empfehlungsgraph.Node) => nodeScale(d.count) || 0
  const logoScale = (d: Empfehlungsgraph.Node) => circleScale(d) * 1.5

  // create svg
  const svg = select(target)
    .append("svg")
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr("viewBox", `0 0 ${width} ${height}`)
    .classed("svgContentResponsive", true)

  // add container to svg that will contain the zoomable content
  const container = svg.append("g")

  // zoom
  const zoom = d3Zoom().scaleExtent([minScale, maxScale])
  // .center([width / 2, height / 2])
  if (!disablePanning)
    zoom.on("zoom", () => {
      container.attr("transform", `translate(${event.transform.x},${event.transform.y}) scale(${event.transform.k})`)
    })
  svg.call(zoom)

  if (disablePanning) {
    // https://stackoverflow.com/questions/22302919/unregister-zoom-listener-and-restore-scroll-ability-in-d3-js
    svg.on("MozMousePixelScroll.zoom", null)
    svg.on("dblclick.zoom", null)
    svg.on("mousedown.zoom", null)
    svg.on("mousemove.zoom", null)
    svg.on("mousewheel.zoom", null)
    svg.on("touchend.zoom", null)
    svg.on("touchmove.zoom", null)
    svg.on("touchstart.zoom", null)
    svg.on("wheel.zoom", null)
  }

  // function to update the link mode (0 = both, 1 = incoming, 2 = outgoing)
  // set force layout
  const simulation = forceSimulation()
    .force("link", forceLink())
    .force("charge", forceManyBody().strength(forceStrength).distanceMax(forceDistanceMax))
    .force(
      "collide",
      forceCollide()
        .radius((d) => nodeScale(d.count) * 1.2)
        .strength(1.0)
    )
    .force(
      "y",
      forceY().y((_) => 0)
    )
  if (forceEnableCenter) {
    simulation
      .force('x', forceX(width / 2).strength(0.10))
      .force('y',  forceY(height / 2).strength(0.315))
  }

  simulation.force("link").links(links)

  // Arrow point
  container
    .append("defs")
    .selectAll("marker")
    .data(links)
    .enter()
    .append("marker")
    .attr("id", (_, idx) => `marker-${idx}`)
    .attr("viewBox", "0 -5 10 10")
    // fit arrow to the stroke

    // vom ende des pfeils wieviel px zurück:
    .attr("refX", (_, _idx) => 7)
    .attr("markerWidth", 6)
    .attr("markerHeight", 6)
    .attr("orient", "auto")
    .style("fill", (_) => colorIncoming)
    .append("path")
    .attr("d", "M0,-5L10,0L0,5")

  // remove old path

  const link = container
    .append("g")
    .attr("class", "links")
    .selectAll("path")
    .data(links)
    .enter()
    .append("path")
    .attr("class", "link")
    .style("stroke", (_) => colorIncoming)
    //.style("stroke-width", (d) => strokeScale(d.count) || 0)
    .style("stroke-width", (d) => 1)
    .attr("marker-end", (d, idx) => `url(#marker-${idx})`)

  // define the nodes = Firmen Logos
  const node = container
    .append("g")
    .attr("class", "nodes")
    .selectAll(".node")
    .data(values(nodes))
    .enter()
    .append("g")
    .attr("class", "node")
    .call(
      drag()
        .on("start", (d) => {
          if (!event.active) simulation.alphaTarget(0.3).restart()
          d.fx = d.x
          d.fy = d.y
        })
        .on("drag", (d) => {
          d.fx = event.x
          d.fy = event.y
        })
        .on("end", (d) => {
          if (!event.active) simulation.alphaTarget(0)
          d.fx = null
          d.fy = null
        })
    )
  // Logos
  node.append("circle").attr("r", circleScale)
  node
    .append("image")
    .attr("x", (d) => -logoScale(d) / 2)
    .attr("y", (d) => -logoScale(d) / 2)
    .attr("xlink:href", (d) => d.logo.replace("http:", "https:"))
    .attr("width", (d) => logoScale(d))
    .attr("height", (d) => logoScale(d))

  // Für `link`
  // simulation.nodes(values(nodes)).on("tick", () => {
  //   link
  //     .attr("x1", d => d.source.x)
  //     .attr("y1", d => d.source.y)
  //     .attr("x2", d => d.target.x)
  //     .attr("y2", d => d.target.y)
  //   node.attr("transform", d => `translate(${[d.x, d.y]})`)
  // })
  // Für `path`

  simulation.force("link").links(links).distance(250)
  simulation.nodes(values(nodes))
  simulation.stop()
  simulation.tick(100);

  simulation.on("tick", () => { })

  const setNodePositions = () => {
  node.attr("transform", (d) => `translate(${[d.x, d.y]})`)
  link.attr("d", (d) => {
    if (!d.target || !d.source) return

    let radius = circleScale(d.target) + 5
    const width = strokeScale(d.count) || 0
    radius += width

    const aX = (d.target.x || 0) - (d.source.x || 0)
    const aY = (d.target.y || 0) - (d.source.y || 0)
    const aLength = Math.sqrt(aX * aX + aY * aY)
    const rPerLength = radius / aLength
    const targetX = d.target.x - aX * rPerLength
    const targetY = d.target.y - aY * rPerLength

    let dx = targetX - d.source.x
    let dy = targetY - d.source.y
    // greater factor = less curviness of the lines
    dx *= 4
    dy *= 4

    const dr = Math.sqrt(dx * dx + dy * dy)
    return `M${d.source.x},${d.source.y}A${dr},${dr} 0 0,1 ${targetX},${targetY}`
  })
  }
  setNodePositions()

  simulation.on("tick", setNodePositions)

  zoom.scaleTo(container, defaultZoom)
  return simulation
}

export { recommendationGraph }
