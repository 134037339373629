<template lang="pug">
div
  #top-of-reviews
  .text-center.mt-3.mb-3
    b
      | {{ portalTypes.find(pt => pt.id == currentPortalType).tagline }}
  .d-flex.flex-wrap.justify-content-center.gap-3.mb-5
    template(v-for="pt in portalTypes" :key="pt.id")
      button.btn.btn-secondary.rounded.d-flex.align-items-center.justify-content-center(
        @click="changePortalType(pt.id)"
        :class="{ active: pt.id == currentPortalType }"
        style="flex: 1 1 0px"
      )
        | {{ pt.name }}
  .d-flex.gap-2.align-items-baseline.justify-content-end(v-if="usersWithLastSurvey.length > 0")
    p
      | {{ $t('js.components.partner_review.sort_by') }}:
    .button-group.mb-3
      button.btn.dropdown-toggle.btn-outline-secondary(type="button" data-bs-toggle="dropdown" aria-expanded="false" style="text-transform: none; border-radius: 0px")
        | {{ sortOptions.find(o => o.value == currentSort).text }}
      ul.dropdown-menu.dropdown-menu-end(style="border-radius: 0px")
        li(v-for="option in sortOptions" :key="option.value")
          .dropdown-item(@click="changeSort(option.value)")
            | {{ option.text }}

  .review-card.border.mb-5.neumorphism-on-white(v-for="user in paginatedUsers" :key="user.id")
    .review-card-body(:style="companyBackground(user)")
      .row(v-if="user.survey")
        .col-12.col-lg-10.order-2.order-lg-1.position-relative
          .d-flex.justify-content-between.p-3.m-2.flex-column-on-sm.gap-3
            .d-flex.justify-content-around.flex-column.partner-box
              .text-center
                img.rounded.img-responsive(v-if="user.quote.author_picture" :src="user.quote.author_picture" :alt="user.quote.author" loading='lazy' style='width: 100px')
                .rounded(v-else)
                  i.fas.fa-user-circle.fa-3x
              small.text-center.mt-2
                | {{ user.quote.author }}

            .w-100
              .review-quote.d-flex.justify-content-center.align-content-center.flex-column
                .quote-text.hyphens
                  b {{ user.quote.text }}
              .mt-2.pull-right
                small.fst-italic
                  | {{ user.quote.author }}

              .mt-5.quote-text(v-if="user.survey.commentLikeEb")
                | {{ $t('js.components.partner_review.question') }}
                .mt-1
                  b {{ user.survey.commentLikeEb}}
                .mt-2.mb-3.pull-right
                  span(v-if="user.survey.personName")
                    small
                      | {{ $t('js.components.partner_review.bewertung_von') }}: {{ user.survey.personName }}&nbsp;|&nbsp;
                  span
                    small
                      | {{ $t('js.components.partner_review.date') }}: {{ formattedDate(user.survey.answeredOn) }}

        .col-12.col-lg-2.order-1.order-lg-2
          .text-center.mt-2.p-3
            img.rounded.img-responsive(:src="user.logo_resized" :alt="user.firma" loading="lazy")
          review-stars.mb-1(:survey="user.survey")
          .text-center.mb-3
            i.fas.fa-glass-cheers(style="margin-right: 5px;")
            small.fst-italic
              | {{ $tc('js.components.partner_review.year_membership', { count: user.billingYears } ) }}

      .row(v-else)
        .col-12.col-lg-10.order-2.order-lg-1
          .d-flex.justify-content-between.p-3.m-2.flex-column-on-sm.gap-3
            .d-flex.justify-content-around.flex-column.partner-box
              .text-center
                img.rounded.img-responsive(v-if="user.quote.author_picture" :src="user.quote.author_picture" :alt="user.quote.author" loading='lazy' style='width: 100px')
              small.text-center.mt-2
                | {{ user.quote.author }}
            .review-quote.d-flex.justify-content-center.align-content-center.flex-column.w-100
              .ref-quote-text.hyphens.ref-quote-succint
                b {{ user.quote.text }}
        .col-12.col-lg-2.order-1.order-lg-2
          .text-center.p-3.m-2
            img.rounded.img-responsive(:src="user.logo_resized" :alt="user.firma" loading="lazy")
          .text-center.mb-3(v-if="user.billingYears")
            i.fas.fa-glass-cheers(style="margin-right: 5px;")
            small.fst-italic
              | {{ $tc('js.components.partner_review.year_membership', { count: user.billingYears} ) }}

  .text-center
    .btn-group.text-center(style="max-width: 300px")
      button.btn(
        v-for="page in totalPages"
        :key="page"
        :class="{ active: (page-1) == currentPage }"
        @click="changePage(page-1)"
      )
        | {{ page }}


</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import ReviewStars from './ReviewStars.vue'

const props = defineProps({
  users: {
    type: Array as PropType<any[]>,
    required: true
  },
  surveys: {
    type: Array as PropType<any[]>,
    required: true
  },
  portalTypes: {
    type: Array as PropType<any[]>,
    required: true
  },
})

const currentPortalType = ref<number>(0)

const usersByPortalType = computed(() => {
  if (currentPortalType.value == 0) return [...props.users]

  return [...props.users].filter((user) => {
    const portalTypeIds = user.portal_types.map((el: any) => el.id)
    return portalTypeIds.includes(currentPortalType.value)
  })
})

const usersWithLastSurvey = computed (() => {
  return [...usersByPortalType.value].map((user) => {
    const userSurvey = props.surveys.find((survey: any) => survey.username.trim() == user.user_name)
    if (userSurvey) {
      const lastAnsweredSurvey = userSurvey.surveys.filter((el: any) => el.answeredOn != null).at(-1)
      user['billingYears'] = userSurvey.billingYears
      user['survey'] = lastAnsweredSurvey
    }
    return user
  })
})

const usersSortedBySurveyDate = computed(() => {
  return [...usersWithLastSurvey.value].sort((a, b) => {
    if (!a.survey) return 1;
    if (!b.survey) return -1;

    return new Date(b.survey.answeredOn).getTime() - new Date(a.survey.answeredOn).getTime()
  })
})

const usersSortedBySurveyScore = computed(() => {
  return [...usersWithLastSurvey.value].sort((a, b) => {
    if (!a.survey) return 1;
    if (!b.survey) return -1;

    const ats = a.survey.totalSatisfaction ? a.survey.totalSatisfaction : 0
    const abs = a.survey.beratungScore ?  a.survey.beratungScore : 0

    const bts = b.survey.totalSatisfaction ? b.survey.totalSatisfaction : 0
    const bbs = b.survey.beratungScore ?  b.survey.beratungScore : 0

    return (a.survey.averageScore + ats + abs) - (b.survey.averageScore + bts + bbs)
  })
})

const currentSort = ref('date')
const sortOptions = [
  { value: 'date', text: 'Datum' },
  { value: 'alphabet', text: 'Firmenname' },
  { value: 'score', text: 'Bewertung' }
]
const currentPage = ref(0)
const perPage = 7

const totalPages = computed(() => {
  return Math.ceil(usersByPortalType.value.length / perPage)
})

const paginatedUsers = computed(() => {
  const start = currentPage.value * perPage
  const end = start + perPage
  if(currentSort.value == 'date') {
    return usersSortedBySurveyDate.value.slice(start, end)
  }
  if (currentSort.value == 'score') {
    return usersSortedBySurveyScore.value.slice(start, end)
  }
  return usersWithLastSurvey.value.slice(start, end)
})

function scrollToTopOfReviews() {
  const topOfReviews = document.getElementById('top-of-reviews')
  if (topOfReviews) {
    topOfReviews.scrollIntoView({ behavior: 'smooth' })
  }
}

function changePage(page: number) {
  currentPage.value = page
  scrollToTopOfReviews()
}

function changeSort(sort: string) {
  if (sort == currentSort.value) return

  currentSort.value = sort
  currentPage.value = 0
}

function changePortalType(portalType: number) {
  if (portalType == currentPortalType.value) return

  currentPortalType.value = portalType
  currentPage.value = 0
}

function companyBackground(user: any) {
  return {
    'background-color': user.company_color || '#23788f',
    'color': luminosity(user.company_color)
  }
}

function luminosity(rgb: any) {
  if (!rgb) rgb = "#000000"
  const r = parseInt(rgb.slice(1, 3), 16)
  const g = parseInt(rgb.slice(3, 5), 16)
  const b = parseInt(rgb.slice(5, 7), 16)
  const value = (0.299 * r + 0.587 * g + 0.114 * b) / 255 * 100

  if (value < 50) return '#f3f3f3'
  return '#000'
}

const formattedDate = ((date: string) => {
  const d = new Date(date)
  return d.toLocaleDateString('de')
})

</script>

<style scoped>
.partner-box {
  width: 150px
}
@media (max-width: 768px) {
  .partner-box {
    width: 100%;
  }
}
.review-quote:before {
  content: "“";
  font-size: 6rem;
  top: -10px;
  left: -17.5px;
  opacity: 30%;
  font-style: italic;
  height: 50px;
  position: relative;
}
.review-card {
  border-radius: 10px;
}
.review-card-body {
  border-radius: 10px;
}
.rounded {
  border-radius: 20px;
}
.ref-quote-author {
  font-size: 1rem;
}
.ref-quote-content {
  min-height: 200px;
}
.active {
  background-color: #bf436f;
  color: #f3f3f3;
}
.btn-secondary {
  max-width: 120px;
}
@media (min-width: 992px) {
  .quote-text {
    margin-left: 15px;
  }
}
.quote-text {
  font-style: italic;
  font-size: 16px;
}
</style>
