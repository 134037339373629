import csrfToken from "@/utils/csrfToken"
import { ref } from 'vue'

type JobSubscriptionForm = {
  email: string
  query: string
  radius?: string | number
  position?: string | null
  sub_job_type?: string | null
  portal_types?: string | null
}
const state = ref<"nothing" | "loading" | "success" | "error">("nothing")
const errorMessage = ref<string | null>(null)

const createEmailSubscription = (payload: JobSubscriptionForm) => {
  state.value = "loading"
  errorMessage.value = null

  return fetch("/email-abo", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({ job_subscription_form: payload }) ,
  }).then((r) => {
    if (r.ok) {
      state.value = "success"
    } else if (r.status > 400 && r.status < 500) {
      r.json().then((data) => {
        state.value = "error"
        errorMessage.value = data.errors.join(" ")
      })
    } else {
      state.value = "error"
      errorMessage.value = r.statusText
    }
  })
}

export {
  createEmailSubscription,
  state,
  errorMessage,
}
