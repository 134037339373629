<script lang="ts" setup>
import { ref, computed } from "vue"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import companySizes from "@/wizard/companySizes.json"
import portalTypeInfo from "@/wizard/portalTypeIcons"

import { Checkbox, CheckboxGroup } from "@/elements"
import { form } from "@/wizard/form"
import { useI18n } from "vue-i18n"

const { locale, t } = useI18n()
const companySizeOptions = computed(() => {
  return companySizes.map((size) => ({
    text: locale.value == "de" ? size.text_de : size.text_en,
    value: size.value,
  }))
})

const portalTypeOptions = computed(() =>
  (Object.keys(portalTypeInfo) as portaltype[]).map((ptKey) => ({
    value: ptKey,
    text: locale.value == "de" ? portalTypeInfo[ptKey].title_de : portalTypeInfo[ptKey].title_en,
  }))
)

const portalTypeProxy = computed<string[]>({
  get(): string[] {
    return form.portal_type?.split(",") || []
  },
  set(value: string[]) {
    form.portal_type = value.join(",")
  },
})

const moreOpen = ref(false)

const subJobTypes = [
  "all",
  "fachkraft",
  "fuehrungskraft",
  "freelancer",
  "hilfskraft",
  "praktikum",
  "werkstudent",
  "volunteer",
  "ausbildung",
  "weiterbildung",
  "duales_studium",
]

const emits = defineEmits(["search"])

const search = () => {
  emits("search", form)
}

const subJobTypeName = (st: string) => {
  return t(`js.wizard.sub_job_type.types.${st}.name`)
}
</script>

<template lang="pug">
.my-5
  .card.bg-medium.mainfilter
    form.card-body.text-white(@submit="search")
      .align-items-center.d-flex.gap-2.form-items
        select.form-control.form-control-lg.fit-content(v-model="form.sub_job_type" @change='search')
          option(v-for="st in subJobTypes" :value="st")
            | {{ subJobTypeName(st) }}
        input.form-control.form-control-lg(v-model="form.query" type="text" :placeholder="$t('js.wizard.filter_bar.suchbegriff')" @change='search')
        |
        | in
        |
        input(v-model="form.location" class="form-control form-control-lg" type="text" :placeholder="$t('js.wizard.filter_bar.standort')" @change='search')
        button.btn.btn-primary(type="submit" @click.prevent="search")
          | {{ $t('js.wizard.filter_bar.laden') }}
  .card.bg-primary.mx-5.subfilter.text-white
    button.btn.btn-link.text-white(type="button" @click.prevent="moreOpen = !moreOpen")
      template(v-if='moreOpen')
        | {{ $t('js.wizard.filter_bar.less_filter') }}
      template(v-else)
        | {{ $t('js.wizard.filter_bar.mehr_filter') }}
    collapse-transition
      div(v-if="moreOpen" class="p-4")
        .row.justify-content-between
          .col-12.col-lg-6
            div.mb-4
              span.text-lg
                | {{ $t('js.wizard.filter_bar.tatigkeitsfeld') }}

              CheckboxGroup(v-model="portalTypeProxy" :options='portalTypeOptions' name='portalType')

            div.mb-4
              span.text-lg
                | {{ $t('js.wizard.filter_bar.arbeitsort') }}
              Checkbox(v-model="form.ignore_remote")
                | {{ $t('js.wizard.filter_bar.100_remote_ausblenden') }}
              Checkbox(v-model="form.full_remote")
                | {{ $t('js.wizard.filter_bar.nur_100_remote_stellen_anz') }}

            .form-group.mt-4
              label.form-label.text-lg(for="radius")
                | {{ $t('js.wizard.filter_bar.radius_zur_umkreissuche') }}

              .input-group.input-group-lg(style="max-width: 180px")
                input.form-control.form-control-lg(v-model="form.radius" type="number" placeholder="30" id="radius")
                span.input-group-text
                  |km
          .col-12.col-lg-6
            span.text-lg
              | {{ $t('js.wizard.filter_bar.arbeitgeber') }}

            CheckboxGroup(v-model='form.company_sizes' :options='companySizeOptions' name='company_sizes' :label="$t('js.wizard.filter_bar.unternehmensgro_e_mitarbei')")
</template>

<style scoped>
.subfilter {
  margin-top: -3px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: 0;
}
.mainfilter {
  z-index: 1;
  border-radius: 15px;
}
@media (max-width: 768px) {
  .form-items {
    flex-direction: column;
  }
  .form-control {
    width: 100%;
    margin-bottom: 10px;
  }
}
.btn-link:focus {
  outline: 0;
  box-shadow: none;
}
</style>
