<template lang="pug">
div
  SelectAutoComplete(
    v-model="selectedLangs"
    :options="languages"
    :placeholder="$t('js.components.talent_profile.languages.search')"
  )
    template(v-slot:selected)
      .mt-4.mb-3
        small
          | {{ $tc('js.components.talent_profile.languages.fluency_levels.text', selectedLanguages.length) }}
      .row(ref="parent")
        template(v-for="lang in selectedLanguages")
          .col-12.col-lg-4: .d-flex.justify-content-start.gap-2.align-items-center.mb-3
            button.btn-sm.btn-close(aria-label="remove" @click="remove(lang.value)" style="margin-right: 10px" :class= "minimize ? '' : 'btn-close-white'")
            div(style="font-size: 1.2rem")
              | {{ lang.text }}
          .col-12.col-lg-8: .mb-3
            .d-flex.flex-column-on-sm
              button.btn.btn-fluency.btn-primary.text-center.full-width-on-sm(
                v-for="level in fluencyLevels"
                @click="handleClick(lang.value, level)"
                :style="{ background: active(lang.value, level) ? activeColor : '' }"
                style="text-transform: none;"
                :class="minimize ? 'btn-sm' : ''"
              )
                | {{ $t(`js.components.talent_profile.languages.fluency_levels.${level}`) }}

</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { SelectAutoComplete } from "@/elements"
import { useI18n } from "vue-i18n"
import { useAutoAnimate } from '@formkit/auto-animate/vue'

import isoLanguages from '../../../../../db/languages.json'

const { locale } = useI18n()

const props = withDefaults(defineProps<{
  modelValue: LanguageSkill[],
  minimize: boolean,
  closeButtonClass: string,
  activeColor: string,
}>(), {
  minimize: false,
  closeButtonClass: 'btn-close-white',
  activeColor: '#bf436f !important',
})

const fluencyLevels= ['a1_a2', 'b1_b2', 'c1', 'c2_native']

const selectedLangs = ref<string[]>([])

const selectedLanguages = computed(() => {
  return languages.value.filter((l: FormOption) => selectedLangs.value.includes((l.value).toString()))
})

const languages = computed(() => {
  const key = locale.value === 'de' ? 'name_de' : 'name_iso'
  return isoLanguages.map((l: any) => ({
    text: l[key],
    value: l.iso2,
  }))
})

const emits = defineEmits(["update:modelValue"])

const proxyValue = computed({
  get() {
    return props.modelValue
  },
  set(value: LanguageSkill[]) {
    emits("update:modelValue", value)
  },
})

onMounted(() => {
  selectedLangs.value = proxyValue.value.map((l: LanguageSkill) => l.language_iso_code)
})

function remove(lang: string) {
  const index = selectedLangs.value.findIndex((l: string) => l === lang)
  if (index > -1) {
    selectedLangs.value = selectedLangs.value.filter((l: string) => l !== lang)
    proxyValue.value = proxyValue.value.filter((l: LanguageSkill) => l.language_iso_code !== lang)
  }
}

function active(lang: string, level: string) {
  const index = proxyValue.value.findIndex((l: LanguageSkill) => l.language_iso_code === lang)
  return index > -1 && proxyValue.value[index].level === level
}

function handleClick(lang: string, level: string) {
  const index = proxyValue.value.findIndex((l: LanguageSkill) => l.language_iso_code === lang)
  if (index > -1) {
    proxyValue.value = proxyValue.value.map((l: LanguageSkill) => {
      if (l.language_iso_code === lang) {
        l.level = level
      }
      return l
    })
  } else {
    proxyValue.value.push({
      language_iso_code: lang,
      level: level,
    })
  }
}

const [parent] = useAutoAnimate()
</script>

<style scoped>
.btn-fluency{
  padding: 5px;
  color: #fff;
  border-radius: 0;
  border-right: 1px solid #606060;
  width: calc(100% / 4);
}
.btn-fluency:first-child {
  border-radius: 5px 0 0 5px;
}
.btn-fluency:last-child {
  border-radius: 0 5px 5px 0;
}

@media (max-width: 768px) {
  .btn-fluency {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #606060;
    border-radius: 5px !important;
    margin-bottom: 5px;
  }
}
</style>
