import "leaflet/dist/leaflet.css"
import L from "leaflet"
import "leaflet.heat"
import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  map: L.Map | null = null
  stop: Function | null = null

  connect() {
    const [_, stop] = useIntersection(this, { threshold: 0 })
    this.stop = stop
  }

  appear() {
    if (this.stop)
      this.stop()
    const domElement = this.element


    fetch("/arbeitgeber/api/kanaleo_heat_map")
      .then((r) => r.json())
      .then((json) => {
        var data = json.data
        var options = json.leafletOptions
        var baseLayer = L.tileLayer(options.tile_url, {
          attribution: options.attribution,
        })
        var heatmapOptions = options.heatmap

        var map = new L.Map(domElement as HTMLElement, {
          center: new L.LatLng(options.center[0], options.center[1]),
          zoom: options.center[2],
          layers: [baseLayer],
          minZoom: options.center[2],
          maxZoom: options.center[2],
          zoomControl: false,
          maxBounds: [
            [54.920132, 3.508927],
            [46.796604, 16.6159167],
          ],
        })
        map.boxZoom.disable()
        map.doubleClickZoom.disable()
        map.keyboard.disable()
        map.scrollWheelZoom.disable()
        map.touchZoom.disable()

        L.heatLayer(data, heatmapOptions).addTo(map)
      })
  }

  disconnect() {
    if (this.map) {
      this.map.remove()
    }
  }
}
