<template>
  <Modal v-model="openHelpModal">
    {{ $t("js.components.apply.prefilled_note.beim_absenden_einer_bewerb") }}
    <template v-slot:footer="">
      <Button @click.prevent="openHelpModal = false" variant="primary">{{ $t("js.components.apply.prefilled_note.schlie_en") }}</Button>
      <a href="https://www.empfehlungsbund.de/datenschutz#bewerbungsformular">{{ $t("js.components.apply.prefilled_note.datenschutzerklarung") }}</a>
      <button class="btn btn-link btn-sm" @click.prevent="emit('reset')">
        {{ $t("js.components.apply.prefilled_note.alle_eingaben_von_diesem_g") }}
      </button>
    </template>
  </Modal>
  <Alert variant="success" :title="$t('js.components.apply.prefilled_note.schnellbewerbung')">
    <p>
      {{ $t("js.components.apply.prefilled_note.sie_konnen_hier_mit_einem") }}
      <a href="#" @click.prevent="openHelpModal = true" role="button" aria-label="Hilfe zum Datenschutz">
        <Icon :path="mdiHelpCircleOutline" :title="$t('js.components.apply.prefilled_note.hilfe_zum_datenschutz')"></Icon>
      </a>
    </p>
    <table class="w-100 mb-5">
      <tbody>
        <tr>
          <td>{{ $t("js.components.apply.prefilled_note.name") }}</td>
          <td>
            <strong>{{ form.first_name }} {{ form.last_name }}</strong>
          </td>
        </tr>
        <tr>
          <td>{{ $t("js.components.apply.prefilled_note.e_mail") }}</td>
          <td>
            <strong>{{ form.email }}</strong>
          </td>
        </tr>
        <tr v-if="form.telephone">
          <td>{{ $t("js.components.apply.prefilled_note.telefon") }}</td>
          <td>
            <strong>{{ form.telephone }}</strong>
          </td>
        </tr>
        <tr v-if="form.city">
          <td>{{ $t("js.components.apply.prefilled_note.adresse") }}</td>
          <td>{{ form.street }}, {{ form.postcode }} {{ form.city }}</td>
        </tr>
        <tr v-if="form.comment">
          <td>{{ $t("js.components.apply.prefilled_note.anschreiben_kommentar") }}</td>
          <td>
            <strong>{{ form.comment }}</strong>
          </td>
        </tr>
        <tr>
          <td>{{ $t("js.components.apply.prefilled_note.anhange") }}</td>
          <td>{{ form.files.map((e) => e.filename).join(", ") }}</td>
        </tr>
      </tbody>
    </table>
    <Button @click.prevent="emit('close')" variant="white">{{ $t("js.components.apply.prefilled_note.angaben_anpassen") }}</Button>
  </Alert>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { Modal, Button, Alert, Icon } from "@/elements"
import { mdiHelpCircleOutline } from "@mdi/js"

const openHelpModal = ref(false)
defineProps<{
  form: ApplicationForm
}>()

const emit = defineEmits(["close", "reset"])
</script>

<style scoped></style>
