<template lang="pug">
.mt-2.d-flex.justify-content-start.gap-3.mb-4.tab-wrapper.flex-column-on-sm
  button.tab-button(
    type="button"
    v-for="(tab, idx) in tabs"
    :id="'tab-' + idx"
    :class= '{ active: proxyCurrentTab === idx }'
    @click="proxyCurrentTab = idx"
  )
    .d-flex.align-items-center.justify-content-center.gap-2
      i.fas(style="margin-right: 0.5rem" :class="tab.icon")
      | {{ $t(tab.title) }}
      span.badge.bg-accent.text-white(v-if="tab.hasNotification")
        | Neu
        i.fas.fa-bell(style="margin-left: 0.25rem")
slot(
  :name="'tab(' + proxyCurrentTab +')'"
  v-bind:tab="tabs[proxyCurrentTab]"
  :index="proxyCurrentTab"
)
</template>


<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  tabs: {
    title: string
    icon: string
    hasNotification: boolean
  }[],
  modelValue: number
}>()

const emits = defineEmits(['update:modelValue'])

const proxyCurrentTab = computed({
  get: () => props.modelValue,
  set: (val) => emits('update:modelValue', val)
})
</script>


<style lang="scss" scoped>
.tab-button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  color: #6c757d;
  font-weight: 600;
  min-width: 200px;
}
.tab-button.active {
  color: #103640;
  border-bottom: 3px solid #103640;
}
.tab-wrapper {
  overflow-x: auto;
}
</style>
