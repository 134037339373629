<script lang="ts" setup>
import { ref, computed, nextTick } from "vue"
import * as Routes from "@/routes"
import icons from "@/wizard/portalTypeIcons"

const locale = window.Locale

const props = defineProps<{
  urlParams: Record<string, string>
  portalTypes: Record<portaltype, frontend.Competence[]>
}>()

const visibleIcons = computed(() => {
  const keys: portaltype[] = Object.keys(icons) as portaltype[]
  const visiblekeys = keys.filter(key => props.portalTypes[key] && props.portalTypes[key].length > 0)
  return Object.fromEntries(visiblekeys.map(key => [key, icons[key]]))
})

const portalType = ref<portaltype | null>(null)
const competences = computed(() => {
  const v = portalType.value
  if (!v) {
    return []
  }
  return props.portalTypes[v]
})

const nextUrl = (c: frontend.Competence, pt: portaltype) => {
  return Routes.wizardCompaniesPath({
    ...props.urlParams,
    competence: c.slug,
    portal_type: pt,
  })
}
const competencesElement = ref<HTMLDivElement | null>(null)
const select = (pt: portaltype) => {
  portalType.value = pt
  nextTick( () => {
    if (competencesElement.value) {
      competencesElement.value.scrollIntoView({ behavior: "smooth" })
    }
  })
}
</script>

<template lang="pug">
div.d-flex.flex-wrap.justify-content-center.gap-4
  button.btn.btn-lg.shadow(v-for='(icon, pt) in visibleIcons' :class='{"btn-dark active": pt == portalType, "btn-light": pt != portalType}' @click='select(pt)')
    img(:src='icon.svg' v-if='icon.svg' :alt='pt' style="width: 72px; aspect-ratio: 1;")
    svg(fill='currentColor' :viewBox='icon.viewbox' v-else)
      path(:d="icon.path" fill='inherit')
    br
    | {{pt.toUpperCase()}}
    small.text-muted(v-if='locale == "de"')
      | {{icon.title_de}}
    small.text-muted(v-if='locale == "en"')
      | {{icon.title_en}}


transition(name='fade')
  div.py-5(v-if='portalType' ref='competencesElement')
    div.d-flex.flex-wrap.gap-4.justify-content-center(v-if='portalType')
      a.btn.btn-light.btn-lg.competence-btn(v-for='competence in competences' :href='nextUrl(competence, portalType)')
        span
          | {{competence.name}}
        span.badge.badge-pill.bg-medium.ms-2
          |{{ $tc("js.wizard.location.jobs", competence.job_count) }}


</template>

<style scoped>
.btn {
  text-transform: none;
  max-width: 250px;
}
.btn-lg {
  width: 250px;
}
svg {
  width: 72px;
  aspect-ratio: 1;
}
small {
  line-height: 1.2;
  display: block;
}
.competence-btn {
  white-space: normal;
  hyphens: auto;
  word-wrap: break-word;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
