export default (file: ActiveStorage.Blob) => {
  const url = `/rails/active_storage/blobs/${file.signed_id}/${encodeURIComponent(file.filename)}`
  // check if file exists
  return fetch(url, { method: 'HEAD' }).then((e) => {
    if (e.status == 404) {
      return false
    }
    return true
  }).catch(() => {
    return true
  })
}
