<template lang="pug">
.mt-5.card.bg-medium-gradient.text-white.neumorphism-on-medium.d-flex.p-m
  .row
    .col-12: .mb-3
      h4.font-light.mb-4(ref="competencesHTML")
        | {{ $t('js.components.talent_profile.competences.label') }}
      competences(
        v-model="form.competences"
        :showError="!fieldValid('competences') && v$.competences.$dirty"
        :portalType="form.portal_type"
        @update:portalType="form.portal_type = $event"
      )

    CollapseTransition
      .col-12(v-if="form.competences.length > 0"): .mb-3
        hr
        h4.font-light.mb-4(ref="competencesLevelHTML")
          | {{ $t('js.components.talent_profile.experience_levels.label') }}
        competence-level(
          v-model="form.competences"
        )
        .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="!competencesLevelValid && v$.sub_job_types_preference.$dirty")
          | {{ $t('js.components.talent_profile.errors.competence_levels') }}

    CollapseTransition
      .col-12(v-if="form.competences.length > 0"): .mb-3
        hr
        h4.font-light.mb-4(ref="importantCompetencesHTML")
          | {{ $t('js.components.talent_profile.important_competences.label') }}
        p
          | {{ $t('js.components.talent_profile.important_competences.explain') }}
        important-competences(
          v-model="form.competences"
        )
        .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="!importantCompetencesValid && v$.sub_job_types_preference.$dirty")
          | {{ $t('js.components.talent_profile.errors.important_competences') }}

    .col-12: .mb-3
      hr
      h4.font-light.mb-4
        | {{ $t('js.components.talent_profile.sub_job_types.text') }}
      SubJobTypes(
        v-model="form.sub_job_types_preference"
      )
      .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="!fieldValid('sub_job_types_preference') && v$.sub_job_types_preference.$dirty")
        | {{ $t('js.components.talent_profile.errors.sub_job_types') }}

    .col-12: .mb-3
      hr
      h4.font-light.mb-4
        | {{ $t('js.components.talent_profile.degrees.label') }}
      RadioGroup(
        name="degree"
        v-model="form.degree"
        :options="options['degrees']"
        style="font-size: 1.2rem"
        :disableError="true"
      )
      .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="!fieldValid('degree') && v$.degree.$dirty")
        | {{ $t('js.components.talent_profile.errors.degree') }}

    .col-12
      hr
      h4.font-light.mb-4
        | {{ $t('js.components.talent_profile.languages.text') }}
      LanguagePicker(
        v-model="form.language_skills"
      )
      .mt-3.alert.alert-danger.my-alert.m-0.p-2(v-if="!fieldValid('language_skills') && v$.language_skills.$dirty")
        | {{ $t('js.components.talent_profile.errors.language_skills') }}

</template>

<script lang="ts" setup>
import Competences from "../components/Competences.vue";
import { provide, computed, ref } from 'vue'
import SubJobTypes from '../components/SubJobTypes.vue'
import ImportantCompetences from '../components/ImportantCompetences.vue'
import CompetenceLevel from '../components/CompetenceLevel.vue'
import LanguagePicker from '../components/LanguagePicker.vue'
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import useTalent from "../talents_form"
import { getOptionValues, useValidations } from "../talents_form"
import { RadioGroup } from "@/elements"


const { form, v$ } = useTalent()
provide("v", v$);
const fields = ['competences', 'sub_job_types_preference', 'degree', 'language_skills', 'portal_type']

const validations = useValidations(fields, v$)
const fieldValid = validations.fieldValid
const touchFields = validations.touchFields
const primitiveValid = validations.valid

const options = {
  degrees: getOptionValues('degrees'),
}

const competencesHTML = ref<HTMLElement>()
const competencesLevelHTML = ref<HTMLElement>()
const importantCompetencesHTML = ref<HTMLElement>()

const competencesLevelValid = computed(() => {
  return form.value.competences.every((el: CompetenceInput) => el.experience_level !== null)
})

const importantCompetences = computed(() => {
  return form.value.competences.filter((el: CompetenceInput) => el.is_important).map((el: CompetenceInput) => el.competence_id)
})

const importantCompetencesValid = computed(() => {
  return importantCompetences.value.length >= 1
})

const valid = computed(() => {
  return primitiveValid.value && competencesLevelValid.value && importantCompetencesValid.value
})

defineExpose({
  valid,
  touchFields
})
</script>
