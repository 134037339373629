<template>
  <ChecklistItem :done="kanaleoDone" v-if="!kanaleoDone">
    <div v-if="application.kanaleo_url" v-html="$t('js.components.apply_card.kanaleo', { url: application.kanaleo_url })" />
    <Button :href="application.kanaleo_url" variant="dark" class="mt-2" target="_blank" @click="setKanaleoDone" @click.middle="setKanaleoDone">
      {{ $t("js.components.apply_card.go_to_kanaleo") }}
    </Button>
  </ChecklistItem>
  <ChecklistItem :done="kanaleoDone" v-if="kanaleoDone">
    <div v-html="$t('js.components.apply_card.kanaleo_success', { url: application.kanaleo_url })" />
    <Button
      :href="application.kanaleo_url"
      variant="outlineDark"
      class="mt-2"
      size="sm"
      target="_blank"
      @click="setKanaleoDone"
      @click.middle="setKanaleoDone">
      {{ $t("js.components.apply_card.go_to_kanaleo") }}
    </Button>
  </ChecklistItem>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { kanaleoAnswered } from "@/components/apply/history"
import { Button } from "@/elements"
import ChecklistItem from "@/elements/ChecklistItem.vue"

const props = defineProps<{
  application: StoredApplicationV2
}>()

const kanaleoDone = ref(props.application.kanaleo_answered ?? false)

const setKanaleoDone = () => {
  kanaleoDone.value = true
  kanaleoAnswered(props.application)
}
</script>

<style scoped></style>
