<template lang="pug">
overview-card(
  title="Empfehlungscode"
  icon="fas fa-comment-dots"
  :showProgress="false"
  :hint="$t(`js.components.progress.hint.recommendation`)"
)
  template(v-slot:toggle-actions)
    button.btn.btn-outline-primary.btn-sm.no-transform.d-flex.align-items-center(@click="proxyEditing = true" :disabled="proxyEditing" id="edit_recommendation")
      i.fas.fa-user-edit(style="margin-right: 0.5rem")
      span(v-if="!proxyEditing")
        | {{ $t("js.components.talent_profile.generic.bearbeiten") }}
      span(v-else)
        | {{ $t("js.components.talent_profile.generic.in_bearbeitung") }}
  template(v-slot:actions)
    collapse-transition
      .mt-3(v-if="proxyEditing")
        edit-recommendation(
          v-model="proxyEditing"
          @submit:makeToast="emits('submit:makeToast', $event)"
        )

</template>

<script setup lang="ts">
import { computed } from "vue"
import OverviewCard from "@/talents/profile/components/OverviewCard.vue"
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue"
import EditRecommendation from "@/talents/profile/components/edit/EditRecommendation.vue"

const props = defineProps({
  editing: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update:editing', 'submit:makeToast'])

const proxyEditing = computed({
  get: () => props.editing,
  set: (val) => emits('update:editing', val)
})
</script>

