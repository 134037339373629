<template lang="pug">
div(:style='{width: (18 * 5) + "px"}')
  .half-stars
    span(v-for='i in 5')
      svg(viewBox='0 0 24 24' fill='currentColor' style='width: 18px; height: 18px')
        path(:d='mdiStarOutline')
  .full-stars(:style='{ width: width + "px"}')
    span(v-for='i in 5')
      svg(viewBox='0 0 24 24' fill='currentColor' style='width: 18px; height: 18px')
        path(:d='mdiStar')


</template>

<script lang="ts" setup>
import { computed } from "vue"
import { mdiStarOutline, mdiStar } from "@mdi/js"

const props = defineProps<{ stars: number }>()

const width = computed(() => props.stars * 18)
</script>

<style scoped>
.half-stars {
  position: absolute;
  white-space: nowrap;
}
.full-stars {
  overflow: hidden;
  white-space: nowrap;
}
</style>
